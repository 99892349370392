import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  fontFamily: ["Roboto", "Helvetica", "Arial"].join(","),

  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#281E78",
      success: "#74ee77",
    },
    secondary: {
      main: "#FA4616",
      hover: "#768790",
    },
    success: {
      main: colors.green[500],
      secondary: colors.green[200],
    },
    error: {
      main: colors.red[500],
      secondary: colors.red[400],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
  overrides: {
    MuiInput: {
      root: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
            margin: 80,
          },
      },
    },
  },
});

export default theme;
