import { Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckBoxField({ control, name, rules, color = 'primary', onClick, onChange, disabled }) {
    return (
        <div>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field }) => (
                    <Checkbox
                        checked={field.value}
                        {...field}
                        color={color}
                        onClick={onClick}
                        onChange={(e, value) => {
                            onChange?.(value);
                            field.onChange(value);
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </div>
    );
}
