import { Chip, colors, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { EUserStatusList } from "src/store/config/constants";

const translatedColumns = (t) => {
  return [
    {
      field: "name",
      title: `${t("name")} ${t("surname")}`,
      layout: (row) => (
        <Typography>
          {row.name} {row.surName}
        </Typography>
      ),
    },
    { field: "email", title: t("email") },
    { field: "role", title: t("type") },
    { field: "organization", title: t("organization") },
    { field: "phone", title: t("phone_number") },
    {
      field: "",
      title: t("status"),
      layout: (row) => (
        <Chip
          style={{
            backgroundColor:
              row.status === EUserStatusList.ACTIVE
                ? colors.green[400]
                : row.status === EUserStatusList.DEACTIVE
                ? colors.red[400]
                : colors.blue[900],
            color: colors.common.white,
          }}
          label={
            row.status === EUserStatusList.ACTIVE
              ? t("active")
              : row.status === EUserStatusList.DEACTIVE
              ? t("inactive")
              : t("created")
          }
        />
      ),
    },
    {
      field: "createdDate",
      title: t("creation_date"),
      layout: (row) => (
        <Typography>
          {format(new Date(row.createdDate), "dd/MM/yyyy HH:mm")}
        </Typography>
      ),
    },
  ];
};

const operatorTypes = [
  {
    id: 1,
    name: "Supervisor",
  },
  { id: 2, name: "Authorized" },
  { id: 3, name: "Standard" },
];

export { translatedColumns, operatorTypes };
