import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InputField from "shared/ui/Input";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const searchStyles = makeStyles({
  input: {
    backgroundColor: "#f3f6f9",
  },
  button: {
    marginLeft: "20px",
  },
});

const SearchInput = ({ onSubmit }) => {
  const classes = searchStyles();
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <InputField
        className={classes.input}
        placeholder={`${t("search")}...`}
        size="small"
        clearable
        variant="outlined"
        onClear={onSubmit}
        name="searchText"
        control={control}
        onKeyPress={(e) => e.code === "Enter" && onSubmit()}
      />
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onSubmit}
      >
        {t("search")}
      </Button>
    </Box>
  );
};

export default SearchInput;
