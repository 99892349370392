import { useBarcode } from "next-barcode";

import { Box } from "@material-ui/core";

const BarcodeContainer = ({
  code = "",
  height = 80,
  showValue = true,
  fontSize = 20,
  width = 1.6,
}) => {
  const { inputRef } = useBarcode({
    value: code,
    options: {
      displayValue: showValue,
      height,
      width: width,
      fontSize: fontSize,
    },
  });

  return (
    <Box display="flex" justifyContent="center">
      <svg ref={inputRef} />
    </Box>
  );
};

export default BarcodeContainer;
