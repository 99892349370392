import { useState, useEffect, Fragment } from "react";
import { Grid, Box, AppBar, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import InputField from "shared/ui/Input";
import useGetStateCity from "src/helpers/useGetStateCity";
import ButtonLoader from "components/ButtonLoader";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import { useDispatch } from "react-redux";

import AutocompleteField from "shared/ui/Autocomplete";
import toast from "react-hot-toast";
import { createAddress } from "src/store/slicers/address";
import { emailRegex } from "src/helpers/validators";
import { useTranslation } from "react-i18next";

const defaultRules = (maxLength: number, t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
    maxLength: {
      value: maxLength,
      message: `${t("maximum")} ${maxLength} ${t("character")}`,
    },
  };
};

const requiredRule = (t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
  };
};

const defaultValues = {
  provinceState: null,
  cityVillage: null,
  street: "",
  building: "",
  apartment: "",
  postalCode: "",
  userEmail: "",
  email: "",
};

const useStyles = makeStyles({
  form: {},
  btn: {},
});

const CreateAddressForm = ({ updateData, onClose, fetchData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const {
    control,
    reset,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const handleResetForm = (resetableFields) => {
    if (!resetableFields?.length) {
      return;
    }

    let resetable = [];
    resetableFields.forEach((item) => {
      resetable[item] = "";
    });

    reset({ ...defaultValues, ...getValues(), ...resetable });
  };

  const classes = useStyles();
  const {
    addressState: { stateList, cityVillageList, streetList, buildingList },
  } = useGetStateCity(watch, control, handleResetForm, "");

  const [loading, setLoading] = useState(false);

  const submitCallback = async (formData) => {
    setLoading(true);
    const {
      provinceState,
      cityVillage,
      street,
      building,
      legalName,
      name,
      surname,
      ...rest
    } = formData;

    const requestData = {
      provinceStateId: formData.provinceState?.id,
      cityVillage:
        typeof formData.cityVillage === "object"
          ? formData.cityVillage?.name
          : formData.cityVillage,
      street:
        typeof formData.street === "object"
          ? formData.street?.name
          : formData.street,
      building:
        typeof formData.building === "object"
          ? formData.building?.name
          : formData.building,
      isLegal: !!tabValue,
      legalName: !!tabValue ? legalName : "",
      name: !!tabValue ? "" : name,
      surname: !!tabValue ? "" : surname,
      ...rest,
    };

    const { meta } = await dispatch(createAddress(requestData));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    toast.success(t("address_added"));

    fetchData();
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    setLoading(true);
    const editData = updateData.get;

    if (!editData || !stateList?.length) {
      setLoading(false);
      return;
    }

    const provinceState = stateList.find(
      (i) => i.id === editData.provinceState
    );

    if (!provinceState) {
      setLoading(false);
      return;
    }

    reset({
      ...editData,
    });
    setLoading(false);
  }, [reset, stateList, updateData]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <form className={classes.form}>
      <Grid container spacing={2}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label={t("physical")} value={0} />
            <Tab label={t("legal")} value={1} />
          </Tabs>
        </AppBar>
        {tabValue === 0 && (
          <Fragment>
            <Grid item xs={6}>
              <Box mt={2}>
                <InputField
                  name="name"
                  label={t("name")}
                  control={control}
                  rules={defaultRules(50, t)}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>
                <InputField
                  name="surname"
                  label={t("surname")}
                  control={control}
                  rules={defaultRules(50, t)}
                  errors={errors}
                />
              </Box>
            </Grid>
          </Fragment>
        )}

        {tabValue === 1 && (
          <Grid item xs={12}>
            <Box mt={2}>
              <InputField
                name="legalName"
                label={t("legal_name")}
                control={control}
                rules={defaultRules(200, t)}
                errors={errors}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <InputField
            name="email"
            label={t("email")}
            control={control}
            rules={{
              pattern: { value: emailRegex, message: t("wrong_email") },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            size="medium"
            name="provinceState"
            label={t("provinceState")}
            control={control}
            rules={requiredRule(t)}
            isTranslated
            options={stateList}
            onChange={() =>
              handleResetForm([
                "cityVillage",
                "street",
                "building",
                "apartment",
              ])
            }
            disabled={!stateList.length}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            size="medium"
            name="cityVillage"
            label={t("cityVillage")}
            autoSelect
            freeSolo
            control={control}
            rules={requiredRule}
            options={cityVillageList}
            onChange={() =>
              handleResetForm(["street", "building", "apartment"])
            }
            disabled={!cityVillageList.length}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            size="medium"
            name="street"
            label={t("street")}
            autoSelect
            freeSolo
            isInputChange
            onInputChange={(newValue) => setValue("street", newValue)}
            control={control}
            onChange={() => handleResetForm(["building", "apartment"])}
            rules={requiredRule}
            options={streetList || []}
            disabled={!watch("cityVillage")}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            size="medium"
            name="building"
            label={t("building")}
            autoSelect
            freeSolo
            autoSelect
            isInputChange
            onInputChange={(newValue) => setValue("building", newValue)}
            control={control}
            onChange={() => handleResetForm(["apartment"])}
            rules={requiredRule}
            options={buildingList || []}
            disabled={!buildingList?.length && !watch("street")}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="apartment"
            label={t("apartment")}
            control={control}
            autoSelect
            rules={{
              maxLength: {
                value: 50,
                message: `${t("maximum")} 50 ${t("character")}`,
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="postalCode"
            label={t("postal_code")}
            autoSelect
            control={control}
            rules={{
              maxLength: {
                value: 4,
                message: `${t("maximum")} 4 ${t("character")}`,
              },
              minLength: {
                value: 4,
                message: `${t("minimum")} 4 ${t("character")}`,
              },
            }}
            errors={errors}
            onKeyPress={onlyNumericalHandler}
            onPaste={onlyNumericalPasteHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="phone"
            label={t("phone_number")}
            control={control}
            onKeyPress={onlyNumericalHandler}
            onPaste={onlyNumericalPasteHandler}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" py={1.6}>
            <ButtonLoader
              className={classes.btn}
              onClick={handleSubmit(submitCallback)}
              isLoading={loading}
            >
              {t("save")}
            </ButtonLoader>
            <Box ml={2}>
              <ButtonLoader
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={onClose}
              >
                {t("cancel")}
              </ButtonLoader>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateAddressForm;
