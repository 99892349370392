import { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ButtonLoader from "src/components/ButtonLoader";
import { getBase64 } from "src/shared/utils/getBase64";
import PhotoIcon from "@material-ui/icons/AddAPhoto";
import avatarImg from "../../../assets/images/avatar.jpg";
import { useDispatch } from "react-redux";
import {
  fetchOrganizationLogoById,
  updateOrganizationLogo,
} from "src/store/slicers/organizations";
import { useTranslation } from "react-i18next";

const EditLogoDialog = ({ open, onClose, data, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { watch, handleSubmit, setValue } = useForm({
    defaultValues: { base64Image: "" },
  });

  const dispatch = useDispatch();

  const fileInputRef = useRef();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const fileSizeInMb = Math.round(
      Math.floor(e.target.files[0].size / 1024 / 1024)
    );
    if (fileSizeInMb >= 2) {
      toast.error(t("img_size_must_not_exceed_2_mb"));
      e.target.value = "";
      return;
    }
    const base64 = await getBase64(file);
    setValue("base64Image", base64);

    e.target.value = "";
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const { meta } = await dispatch(
      updateOrganizationLogo({ organizationId: data?.id, formData })
    );

    if (meta.requestStatus !== "fulfilled") {
      setIsLoading(false);
      return;
    }

    await fetchData();

    toast.success(t("logo_successfully_changed"));
    setIsLoading(false);

    onClose();
  };

  const triggerFileUpload = () => {
    fileInputRef?.current?.click();
  };

  const fetchLogo = async () => {
    setIsLoading(true);

    if (!data) {
      return;
    }

    const { meta, payload } = await dispatch(
      fetchOrganizationLogoById(data?.id)
    );

    if (meta.requestStatus !== "fulfilled") {
      setIsLoading(false);
      return;
    }

    setValue("base64Image", payload?.base64Image || "");
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLogo();
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth={"xs"}
    >
      <Box m={4} style={{ wordBreak: "break-word" }}>
        <Typography variant="h4">{t("change_company_logo")}</Typography>
      </Box>
      <DialogContent>
        <Box display="flex" justifyContent={"center"}>
          {!isLoading && (
            <Box
              ml={2}
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
            >
              <img
                src={watch("base64Image") || avatarImg}
                alt="logo"
                style={{
                  width: 300,
                  height: 300,
                  objectFit: "contain",
                }}
              />
              <Box display={"flex"} justifyContent="center">
                <PhotoIcon
                  style={{ cursor: "pointer" }}
                  color={"primary"}
                  onClick={triggerFileUpload}
                />
              </Box>
            </Box>
          )}
          <Box display="none">
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonLoader onClick={handleSubmit(onSubmit)}>
          {t("save")}
        </ButtonLoader>
      </DialogActions>
    </Dialog>
  );
};

export default EditLogoDialog;
