import { useState, Fragment, useRef } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import InputField from "shared/ui/Input";
import ButtonLoader from "components/ButtonLoader";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import { useDispatch } from "react-redux";
import { createOrganization } from "src/store/slicers/organizations";
import toast from "react-hot-toast";
import { emailRegex } from "src/helpers/validators";
import { getBase64 } from "src/shared/utils/getBase64";
import { useTranslation } from "react-i18next";

const defaultRules = (maxLength: number, t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
    maxLength: {
      value: maxLength,
      message: `${t("maximum")} ${maxLength} ${t("character")}`,
    },
  };
};

const defaultValues = {
  phone: "",
  userEmail: "",
  email: "",
  hvhh: "",
  name: "",
};

const CreateOrganizationForm = ({ onClose, fetchData }) => {
  const fileInputRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const submitCallback = async (formData) => {
    setLoading(true);

    const { meta } = await dispatch(createOrganization(formData));
    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    toast.success(t("organization_added"));

    fetchData();
    setLoading(false);
    onClose();
  };

  const triggerFileUpload = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const fileSizeInMb = Math.round(
      Math.floor(e.target.files[0].size / 1024 / 1024)
    );
    if (fileSizeInMb >= 2) {
      toast.error(t("img_size_must_not_exceed_2_mb"));
      e.target.value = "";
      return;
    }
    const base64 = await getBase64(file);
    setValue("base64Image", base64);

    e.target.value = "";
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{t("organization_info")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems={"center"}>
            <ButtonLoader onClick={triggerFileUpload}>
              {t("import_logo")}
            </ButtonLoader>
            {watch("base64Image") && (
              <Box ml={2}>
                <img
                  src={watch("base64Image")}
                  alt="logo"
                  style={{ width: 100, height: 100 }}
                />
              </Box>
            )}

            <Box display="none">
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="coreCustomerId"
            label={t("phoenix_customer_number")}
            control={control}
            rules={defaultRules(50, t)}
            onKeyPress={onlyNumericalHandler}
            onPaste={onlyNumericalPasteHandler}
            errors={errors}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="email"
            label={t("email")}
            control={control}
            rules={{
              maxLength: {
                value: 50,
                message: `${t("maximum")} 50 ${t("character")}`,
              },
              pattern: {
                value: emailRegex,
                message: t("wrong_email"),
              },
            }}
            errors={errors}
          />
        </Grid>

        <Fragment>
          <Grid item xs={12}>
            <Typography>{t("admin_info")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputField
              name="userName"
              label={t("name")}
              control={control}
              rules={defaultRules(50, t)}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              name="userSurname"
              label={t("surname")}
              control={control}
              rules={defaultRules(50, t)}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              name="userEmail"
              label={t("email")}
              control={control}
              rules={{
                ...defaultRules(50, t),
                pattern: {
                  value: emailRegex,
                  message: t("wrong_email"),
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Fragment>

        <Grid item xs={12}>
          <Box display="flex" py={1.6}>
            <ButtonLoader
              onClick={handleSubmit(submitCallback)}
              isLoading={loading}
            >
              {t("save")}
            </ButtonLoader>
            <Box ml={2}>
              <ButtonLoader
                variant="contained"
                color="primary"
                onClick={onClose}
              >
                {t("cancel")}
              </ButtonLoader>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateOrganizationForm;
