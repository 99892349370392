import { Fragment, memo } from "react";

import Box from "@material-ui/core/Box";
import { nanoid } from "@reduxjs/toolkit";

const LabelValueList = ({ list }) => (
  <Box>
    {list.map(({ label, value }, index) => (
      <Box mb={1} key={nanoid(3)}>
        {(value || Number.isInteger(value)) && (
          <div key={index}>
            <Box fontWeight="600" mr={1}>
              {label}
            </Box>

            {value}
          </div>
        )}
      </Box>
    ))}
  </Box>
);

export default memo(LabelValueList);
