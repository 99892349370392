import { useMemo, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import LabelValueList from "components/LabelValueList/LabelValueList";
import format from "date-fns/format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StatusList, translatedAdditionalServices } from "../data/constants";
import { ordersGridStyles } from "../styles";
import { useTranslation } from "react-i18next";

const OrderDetails = ({ data, open, onClose }) => {
  const { t } = useTranslation();
  const classes = ordersGridStyles();

  const recipientList = useMemo(() => {
    if (!data?.recipient) {
      return [];
    }
    const { provinceState, cityVillage, street, building, apartment, country } =
      data.recipient;

    const address =
      data?.country?.name +
      " " +
      provinceState +
      " " +
      cityVillage +
      " " +
      street +
      " " +
      building +
      " " +
      (apartment ? apartment : "");
    return [
      {
        label: `${t("name")} ${t("surname")}`,
        value: data.recipient.nameSurname
          ? data.recipient.nameSurname
          : data.recipient.legalName,
      },
      { label: t("address"), value: address },
      { label: t("email"), value: data.recipient.email },
      { label: t("phone_number"), value: data.recipient.phone },
      { label: t("postal_code"), value: data.recipient.postalCode },
    ];
  }, [data, t]);

  const returnSenderList = useMemo(() => {
    if (!data?.returnSender) {
      return [];
    }

    const { provinceState, cityVillage, street, building, apartment } =
      data.returnSender;

    const address =
      "Armenia" +
      " " +
      provinceState +
      " " +
      cityVillage +
      " " +
      street +
      " " +
      building +
      " " +
      (apartment ? apartment : "");
    return [
      {
        label: `${t("name")} ${t("surname")}`,
        value: data.returnSender.nameSurname
          ? data.returnSender.legalName
          : data.returnSender.legalName,
      },
      { label: t("address"), value: address },
      { label: t("email"), value: data.returnSender.email },
      { label: t("phone_number"), value: data.returnSender.phone },
      { label: t("postal_code"), value: data.returnSender.postalCode },
    ];
  }, [data, t]);

  const parcelList = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      { label: t("tracking_number"), value: data.trackingNumber },
      { label: t("service"), value: data.category?.name },
      { label: t("weight"), value: `${data.weight}${t("gram")}.` },
      ...(data.hpWeight
        ? [{ label: t("hp_weight"), value: `${data.hpWeight}գր.` }]
        : []),
      { label: t("amount"), value: data.amount },
      { label: t("hp_amount"), value: data.hpAmount },
      {
        label: t("status"),
        value: t(StatusList?.find((i) => i.id === data.status?.id)?.name),
      },
      {
        label: t("is_created"),
        value: format(new Date(data.creationDate), "dd/MM/yyyy"),
      },
      ...(data?.approvedDate
        ? [
            {
              label: t("is_approved"),
              value: format(new Date(data.approvedDate), "dd/MM/yyyy"),
            },
          ]
        : []),
      { label: t("created_operator"), value: data.createdByUser },
      {
        label: t("additional_services"),
        value: data?.additionalServiceIds?.[0]
          ? translatedAdditionalServices?.[data?.additionalServiceIds?.[0]]
          : "",
      },
      { label: t("print_label_common"), value: data.cod },
      { label: t("notes"), value: data.note },
      { label: "value", value: "" },
      { label: "value", value: "" },
      { label: "value", value: "" },
      { label: "value", value: "" },
    ];
  }, [data, t]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.h3}>
          {t("a_parcel")} {data?.trackingNumber}
        </Typography>{" "}
      </DialogTitle>
      <DialogContent>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{t("parcel_info")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LabelValueList list={parcelList} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{t("recipient_info")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LabelValueList list={recipientList} />
          </AccordionDetails>
        </Accordion>
        {data?.returnSender && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{t("return_sender_info")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LabelValueList list={returnSenderList} />
            </AccordionDetails>
          </Accordion>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetails;
