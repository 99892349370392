import { useState } from "react";
import useQueryParams from "helpers/useQueryParams";

const useTablePagination = ({ defaultQueries, fetchData }) => {
  const [getQueries, setQueries] = useQueryParams(defaultQueries);

  const paginate = (_, value) => {
    const queries = { ...defaultQueries, ...getQueries() };
    queries.pageNumber =
      value >= queries.pageNumber
        ? parseInt(queries.pageNumber) + 1
        : parseInt(queries.pageNumber) - 1;
    fetchData(queries);
    setQueries(queries);
  };
  const [pagination, setPagination] = useState({
    onChangePage: paginate,
  });
  return [pagination, setPagination];
};

export default useTablePagination;
