import { useMemo, useState } from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";
import Table from "shared/ui/Table/index";
import { translatedColumns } from "./data/constants";
import useTable from "helpers/useTable";
import { useSelector, useDispatch } from "react-redux";
import CreateUpdateDialog from "shared/ui/Table/components/createUpdateDialog";
import useTableCrud from "helpers/useTableCrud";
import CreateUserForm from "./components/CreateUserForm";
import toast from "react-hot-toast";
import {
  fetchUsers,
  selectUsers,
  fetchUserById,
  changeUserStatus,
  removeUser,
  selectUserData,
  exportUsersInfo,
} from "src/store/slicers/users";
import ConfirmDialog from "shared/ui/DialogConfirm";
import Filters from "./components/filters";
import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { Roles } from "src/router/routes";
import { EUserStatusList } from "src/store/config/constants";
import { handleDownload } from "src/helpers";
import { useTranslation } from "react-i18next";

const Users = () => {
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsers);
  const userData = useSelector(selectUserData);
  const { t } = useTranslation();
  const columns = translatedColumns(t);

  const { loading, pagination, fetchData, setLoading, updateTable } =
    useTable(fetchUsers);

  const [confirmDialogData, setConfirmDialogData] = useState({
    open: false,
    data: null,
  });

  const [activeUpdateData, setActiveUpdateData] = useState(null);

  const { title, saveAction, closeAction, dialogOpen, updateData, isUpdate } =
    useTableCrud(setLoading, fetchData, "add_user");

  const closeAddEditDialog = () => {
    setActiveUpdateData(null);
    closeAction();
  };

  const [confirmRemoveDialogData, setConfirmRemoveDialogData] = useState({
    open: false,
    data: null,
  });

  const handleConfirmAction = async () => {
    const { meta } = await dispatch(
      changeUserStatus(confirmDialogData.data.id)
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("user_state_is_changed"));
    setConfirmDialogData({
      open: false,
      data: null,
    });
    fetchData();
  };

  const handleConfirmRemoveAction = async () => {
    const { meta } = await dispatch(
      removeUser(confirmRemoveDialogData.data.id)
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("user_is_deleted"));
    setConfirmRemoveDialogData({
      open: false,
      data: null,
    });
    fetchData();
  };

  const handleExportUsers = async (formData) => {
    const { startDate, endDate, organization, name, surName, email, status } =
      formData;
    setLoading(true);

    const requestData = new URLSearchParams({
      ...(organization?.id ? { organization: organization?.id } : {}),
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(status ? { status } : {}),
      ...(surName ? { surName } : {}),
      ...(name ? { name } : {}),
    }).toString();

    const { meta, payload } = await dispatch(exportUsersInfo(requestData));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    const blob = new Blob([payload], {
      type: "application/vnd.ms-excel",
    });

    const href = URL.createObjectURL(blob);
    handleDownload(href, "", ".xlsx");
    setLoading(false);
  };

  const dropdownActions = useMemo(() => {
    return [
      {
        icon: <EditIcon color="primary" />,
        text: t("edit"),
        action: async (data) => {
          updateData.set(data);
          const { meta, payload } = await dispatch(fetchUserById(data.id));
          if (meta.requestStatus !== "fulfilled") {
            return;
          }
          setActiveUpdateData(payload);
          dialogOpen.set(true);
        },
      },
      {
        icon: (data) =>
          data.status === EUserStatusList.DEACTIVE ? (
            <CheckCircleIcon htmlColor="green" />
          ) : data.status === EUserStatusList.ACTIVE ? (
            <NotInterestedIcon color="error" />
          ) : (
            ""
          ),
        text: (data) =>
          data.status === EUserStatusList.DEACTIVE
            ? t("activate")
            : data.status === EUserStatusList.ACTIVE
            ? t("deactivate")
            : "",
        isDisabled: (data) => data?.status === EUserStatusList.CREATED,
        action: (data) => {
          setConfirmDialogData({ open: true, data });
        },
      },
      {
        isDisabled: (row) => row.active,
        icon: <DeleteIcon color="error" />,
        text: t("remove"),
        action: (data) => {
          setConfirmRemoveDialogData({ open: true, data });
        },
      },
    ];
  }, [dialogOpen, dispatch, updateData, t]);

  const activateDialogContent = useMemo(() => {
    return (
      <>
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        <Typography variant="h5">
          {t("are_you_sure")}{" "}
          {confirmDialogData?.data?.status === EUserStatusList.ACTIVE
            ? `${t("deactivate")} `
            : `${t("activate")} `}
          {t("the_user")}
        </Typography>
      </>
    );
  }, [t]);

  return (
    <Box component={Paper} p={4}>
      {userData?.role === Roles.ClAdmin && (
        <Box mb={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dialogOpen.set(true)}
          >
            {t("add_user")}
          </Button>
        </Box>
      )}
      <Table
        title="users_page_title"
        isLoading={loading}
        dropdownActions={dropdownActions}
        columns={columns}
        rows={usersList?.data || []}
        paginationConfig={pagination}
        onChangeRowsPerPage={(event: any) =>
          updateTable({ rowsPerPage: event.target.value })
        }
        filters={
          <Filters
            fetchData={fetchData}
            handleExportUsers={handleExportUsers}
          />
        }
      />
      <CreateUpdateDialog
        title={isUpdate ? t("edit_user") : title}
        open={dialogOpen.get}
        onClose={closeAddEditDialog}
        width={"md"}
      >
        <CreateUserForm
          updateData={{ id: updateData?.get?.id, ...activeUpdateData }}
          isUpdate={activeUpdateData && Object.keys(activeUpdateData)?.length}
          isLoading={loading}
          onSave={saveAction}
          fetchData={fetchData}
          onClose={closeAddEditDialog}
        />
      </CreateUpdateDialog>
      <ConfirmDialog
        isOpen={confirmDialogData.open}
        onAgree={handleConfirmAction}
        onClose={() =>
          setConfirmDialogData({
            open: false,
            data: null,
          })
        }
      >
        {activateDialogContent}
      </ConfirmDialog>
      <ConfirmDialog
        isOpen={confirmRemoveDialogData.open}
        onAgree={handleConfirmRemoveAction}
        onClose={() =>
          setConfirmRemoveDialogData({
            open: false,
            data: null,
          })
        }
      >
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        <Typography variant="h5">
          {`${t("are_you_sure")} ${t("remove")} ${t("the_user")}`}
        </Typography>
      </ConfirmDialog>
    </Box>
  );
};

export default Users;
