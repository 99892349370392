import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../helpers/axios";
import { EBaseUrl, LStorage } from "../config/constants";
import thunkOptions from "../config/thunkOptions";
import { IAuthState } from "../models/interfaces";

const name = "AUTH";

const initialState: IAuthState = {
  isAuth: !!localStorage.getItem(LStorage.accessToken),
};

export const authAction = createAsyncThunk(
  `${name}/authAction`,
  async (payload) =>
    (
      await api.post(`${EBaseUrl.Envelope}/Login`, {
        ...payload,
      })
    ).data,
  thunkOptions
);

export const checkHash = createAsyncThunk(
  `${name}/checkHash`,
  async (hash) =>
    (await api.get(`${EBaseUrl.Envelope}/User/CheckHash?hash=${hash}`)).data,
  thunkOptions
);

export const changePassword = createAsyncThunk(
  `${name}/changePassword`,
  async (formData) =>
    (await api.put(`${EBaseUrl.Envelope}/User/ChangePassword`, formData)).data,
  thunkOptions
);

export const resetPassword = createAsyncThunk(
  `${name}/resetPassword`,
  async (formData) =>
    (await api.put(`${EBaseUrl.Envelope}/User/ResetPassword`, formData)).data,
  thunkOptions
);

const AuthSlicer = createSlice({
  name,
  initialState,
  reducers: {
    signOut: (state) => {
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authAction.fulfilled, (state, { payload }) => {
      localStorage.setItem(LStorage.accessToken, payload[LStorage.accessToken]);
      localStorage.setItem(
        LStorage.refreshToken,
        payload[LStorage.refreshToken]
      );
      localStorage.setItem(LStorage.expDate, payload[LStorage.expDate]);
      state.isAuth = true;
    });
  },
});

export const { signOut } = AuthSlicer.actions;

export default AuthSlicer.reducer;
