import { forwardRef, useCallback, Ref } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFormContext,
} from "react-hook-form";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface ISelectProps {
  name: string;
  control: Control;
  rules?: any;
  size?: "medium" | "small";
  label: string;
  ellipsis?: boolean;
  options?: any[];
  valueProp?: string;
  textProp?: string;
  keyProp: string;
  className?: string;
  inputLabelClassName?: string;
  disabled?: boolean;
  fullWidth: boolean;
  defaultValue?: string;
  errors: FieldErrors;
  multiple: boolean;
  onChange?: (event: string) => void;
  isTranslated?: boolean;
}

const MUISelect = (props: ISelectProps): JSX.Element => {
  const {
    name,
    control,
    rules,
    size,
    label,
    ellipsis = false,
    options,
    valueProp = "id",
    textProp = "textProp",
    keyProp = "id",
    className,
    inputLabelClassName,
    disabled,
    fullWidth = true,
    defaultValue,
    errors,
    multiple,
    onChange,
    isTranslated = false,
    ...rest
  } = props;

  const formContext = useFormContext();
  const { t } = useTranslation();

  const errorInfo = useCallback(() => {
    const lastErrors = formContext?.formState?.errors || errors;
    if (name.includes(".")) {
      const [inputName, nestedName] = name.split(".");
      return lastErrors?.[inputName]?.[nestedName];
    }

    return lastErrors?.[name];
  }, [errors, formContext?.formState?.errors, name]);

  const getOptions = useCallback(() => {
    return (
      options &&
      options.map((item) => {
        return (
          <MenuItem
            key={item[keyProp]}
            value={item[valueProp]}
            disabled={item[valueProp] === -1 ? true : false}
          >
            {ellipsis === true ? (
              <span>{isTranslated ? t(item[textProp]) : item[textProp]}</span>
            ) : (
              <span
                style={{
                  color: item[valueProp] === -1 ? "#aaa" : "#263238",
                  fontWeight: item[valueProp] === -1 ? "350" : "400",
                }}
              >
                {isTranslated ? t(item[textProp]) : item[textProp]}
              </span>
            )}
          </MenuItem>
        );
      })
    );
  }, [ellipsis, keyProp, options, textProp, valueProp]);

  const renderValue = useCallback(
    (value) => {
      if (typeof value === "object") {
        const res = [];
        value.forEach((item) => {
          options?.forEach((option) => {
            option[keyProp] === item && res.push(option[textProp]);
          });
        });
        return res?.join(",");
      }
      const returnValue = options?.find(
        (option) => option[keyProp] === value
      )?.name;

      return isTranslated ? t(returnValue) : returnValue;
    },
    [keyProp, options, textProp]
  );

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" size={size}>
      <InputLabel htmlFor="select">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            multiple={multiple}
            label={label}
            defaultValue={defaultValue}
            className={className}
            error={!!errorInfo?.()?.message}
            autoWidth
            disabled={disabled}
            renderValue={(e) => renderValue(e)}
            name={name}
            fullWidth={fullWidth}
            onChange={(event) => {
              field.onChange(event.target.value);
              onChange?.(event.target.value);
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
            {...rest}
          >
            {getOptions()}
          </Select>
        )}
      />
      {!!errorInfo?.()?.message && (
        <FormHelperText error>{errorInfo?.()?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

MUISelect.defaultProps = {
  textProp: "name",
  keyProp: "id",
  valueProp: "id",
  fullWidth: true,
};

export default MUISelect;
