import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../helpers/axios";
import { EBaseUrl } from "../config/constants";
import thunkOptions from "../config/thunkOptions";
import { IAddressState, IState } from "../models/interfaces";
import { IProvinceState } from "../models/interfaces/address";

const name = "ADDRESS";
export const getCountries = createAsyncThunk(
  `${name}/getCountries`,
  async () => (await api.get(`${EBaseUrl}/Address/GetCountries`)).data,
  thunkOptions
);

export const rememberAddress = createAsyncThunk(
  `${name}/rememberAddress`,
  async (id: number) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/User/AddOrUpdateUserDefaultReturnSender/${id}`
      )
    ).data,
  thunkOptions
);

export const createAddress = createAsyncThunk(
  `${name}/createAddress`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Participant/SavedReturnSender`,
        formData
      )
    ).data,
  thunkOptions
);

export const fetchAddresses = createAsyncThunk(
  `${name}/fetchAddresses`,
  async () =>
    (await api.get(`${EBaseUrl.Envelope}/Participant/SavedReturnSender`)).data,
  thunkOptions
);

export const removeAddress = createAsyncThunk(
  `${name}/removeAddress`,
  async (id) =>
    (
      await api.delete(
        `${EBaseUrl.Envelope}/Participant/SavedReturnSender/${id}`
      )
    ).data,
  thunkOptions
);

export const getStates = createAsyncThunk(
  `${name}/getStates`,
  async () =>
    (await api.get(`${EBaseUrl.Envelope}/Resources/ProvincesStates`)).data,
  thunkOptions
);

export const getCityVillages = createAsyncThunk(
  `${name}/getCityVillages`,
  async (provinceStateId: number) =>
    (
      await api.get(
        `${EBaseUrl.Envelope}/Resources/CityVillage/${provinceStateId}`
      )
    ).data,
  thunkOptions
);

export const getStreets = createAsyncThunk(
  `${name}/getStreets`,
  async ({ id, name = "" }: { id: number; name: string }) =>
    (
      await api.get(`${EBaseUrl.Envelope}/Resources/Streets/${id}`, {
        params: {
          name: name,
        },
      })
    ).data,
  thunkOptions
);

export const getBuilding = createAsyncThunk(
  `${name}/getBuilding`,
  async ({ id, name }: { streetId: number; name: string }) =>
    (
      await api.get(`${EBaseUrl.Envelope}/Resources/Buildings/${id}`, {
        params: {
          name: name,
        },
      })
    ).data,
  thunkOptions
);

const initialState: IAddressState = {
  states: [],
  cityVillageList: [],
  streetList: [],
  buildingList: [],
  addressesList: [],
};

const AddressSlicer = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getStates.fulfilled, (state, { payload }) => {
      state.states = payload;
    });
    builder.addCase(getCityVillages.fulfilled, (state, { payload }) => {
      state.cityVillageList = payload;
    });
    builder.addCase(getStreets.fulfilled, (state, { payload }) => {
      state.streetList = payload;
    });
    builder.addCase(getBuilding.fulfilled, (state, { payload }) => {
      state.buildingList = payload;
    });
    builder.addCase(fetchAddresses.fulfilled, (state, { payload }) => {
      state.addressesList = payload;
    });
  },
  reducers: {},
});

export const selectStates = (state: IState): IProvinceState[] =>
  state.address.states;

export const selectCityVillages = (state: IState): IProvinceState[] =>
  state.address.cityVillageList;

export const selectStreets = (state: IState): IProvinceState[] =>
  state.address.streetList;

export const selectBuildings = (state: IState): IProvinceState[] =>
  state.address.buildingList;

export const selectAddressesList = (state: IState): IProvinceState[] =>
  state.address.addressesList;

export default AddressSlicer.reducer;
