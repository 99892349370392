import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "components/GlobalStyles";
import theme from "./theme";
import { getRoutes, Roles } from "./router/routes";
import { LStorage } from "store/config/constants";
import store from "store";
import { getStates } from "store/slicers/address";
import {
  fetchAdditionalServices,
  fetchCategories,
  fetchParcelStatuses,
  fetchServices,
} from "store/slicers/resources";

import "react-perfect-scrollbar/dist/css/styles.css";
import { getUserInfo, selectUserData } from "./store/slicers/users";
import { fetchOrganizationBalances } from "./store/slicers/organizations";

import "src/helpers/I18n";

const App = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const userInfo = useSelector(selectUserData);
  const { adminRoutes, publicRoutes, authRoutes } = getRoutes();
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem(LStorage.accessToken)
  );

  const routes = useMemo(() => {
    return isLoggedIn
      ? [...adminRoutes, ...publicRoutes]
      : [...authRoutes, ...publicRoutes];
  }, [adminRoutes, authRoutes, isLoggedIn, publicRoutes]);
  const routing = useRoutes(routes);

  const handleAuthState = useCallback(() => {
    const state = store.getState();
    if (state?.auth?.isAuth) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const initialDatafetch = async () => {
    if (isLoggedIn) {
      await Promise.all([
        dispatch(getUserInfo()),
        dispatch(getStates()),
        dispatch(fetchAdditionalServices()),
        dispatch(fetchCategories()),
        dispatch(fetchParcelStatuses()),
        dispatch(fetchServices()),
      ]);
    }
    setDataLoaded(true);
  };
  useEffect(() => {
    initialDatafetch();
  }, [dispatch, isLoggedIn]);

  store.subscribe(handleAuthState);

  useEffect(() => {
    if (!userInfo) return false;
    if (userInfo && userInfo?.role !== Roles.ClAdmin) return false;
    dispatch(fetchOrganizationBalances());
  }, [dispatch, userInfo]);

  return (
    <Fragment>
      <Toaster
        position="bottom-center"
        toastOptions={{
          success: {
            duration: 6000,
          },
          error: {
            duration: 6000,
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MuiPickersUtilsProvider moment={moment} utils={DateFnsUtils}>
          <>{dataLoaded && routing}</>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
