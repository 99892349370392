import { forwardRef } from "react";
import Barcode from "react-barcode";
import { Box } from "@material-ui/core";

const BarcodeToPrint = forwardRef(
  ({ barcode, barcodeWidth = 1, height = 50, fontSize }, ref) => {
    return (
      <Box ref={ref}>
        <Barcode
          value={barcode}
          width={barcodeWidth}
          height={height}
          fontSize={fontSize}
        />
      </Box>
    );
  }
);

export default BarcodeToPrint;
