import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles, Paper, Box, Button, Grid } from "@material-ui/core";
import RangePicker from "shared/ui/RangePicker";
import useTableFilters from "helpers/useTableFilters";
import { getUnixTime } from "date-fns";
import useQueryParams from "src/helpers/useQueryParams";
import { fetchCoreCustomerIds } from "store/slicers/organizations";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "src/store/slicers/users";
import { Roles } from "src/router/routes";
import Autocomplete from "src/shared/ui/Autocomplete";
import { AppDispatch } from "src/store";
import { debounce } from "src/helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";

const styles = makeStyles({
  autocomplete: {
    maxWidth: "235px",
    minWidth: "235px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  root: {
    width: "100%",
    marginBottom: "5px",
    padding: "15px",
  },
  filterIcon: {
    marginTop: "15px",
  },
  clearBtn: {
    marginLeft: "20px",
  },
});

const getDefaultDate = (isStart: boolean) => {
  const timeZoneHours = Math.abs(new Date().getTimezoneOffset() / 60);
  const datetime = moment(new Date()).add(timeZoneHours, "hours");
  if (isStart) {
    datetime.subtract(1, "day");
  }
  const [first, second] = datetime.toISOString().split(":");
  return `${first}:${second}`;
};

const defaultValues = {
  customerId: "",
  startDate: getDefaultDate(true),
  endDate: getDefaultDate(false),
};

const Filters = ({ fetchData }) => {
  const [organizations, setOrganizations] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(selectUserData);
  const classes = styles();
  const methods = useForm({
    defaultValues: defaultValues,
  });

  const userData = useSelector(selectUserData);
  const [getQueries, setQueries] = useQueryParams();

  const { control, handleSubmit, getValues, reset, watch } = methods;

  const { clearFilters, disableClear, areFiltersOpen, setAreFiltersOpen } =
    useTableFilters({
      reset,
      getValues,
      fetchData,
      defaultValues,
    });

  const onSubmit = (formData) => {
    const paramsData = {
      unixTimeFrom: getUnixTime(new Date(formData.startDate)),
      unixTimeTo: getUnixTime(new Date(formData.endDate)),
      ...(userData?.role === Roles.SuperAdmin
        ? { customerId: formData.customerId?.coreCustomerId }
        : {}),
    };

    setQueries(paramsData);
    fetchData(paramsData);
  };

  const handleFetchCoreOrganizations = async (value) => {
    const { meta, payload } = await dispatch(
      fetchCoreCustomerIds({ term: value })
    );

    setOrganizations(payload);
  };

  useEffect(() => {
    if (userData && userData?.role === Roles.SuperAdmin) {
      handleFetchCoreOrganizations("");
    }
  }, [userData]);

  return (
    <Paper className={classes.root}>
      <FormProvider {...methods}>
        <Box mb={2} display="flex" justifyContent="spaceBetween">
          <Grid item xs={6}>
            <Box>
              <RangePicker label="Select date range" control={control} />
            </Box>
          </Grid>
          {userData?.role === Roles.SuperAdmin && (
            <Grid item xs={3}>
              <Autocomplete
                label={t("organization")}
                name="customerId"
                options={organizations}
                control={control}
                isInputChange
                onInputChange={debounce((value) =>
                  handleFetchCoreOrganizations(value)
                )}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <Box ml={2}>
              <Button
                color="primary"
                variant="outlined"
                disabled={
                  userInfo.role === Roles.SuperAdmin
                    ? !watch("customerId") ||
                      !watch("startDate") ||
                      !watch("endDate")
                    : !watch("startDate") || !watch("endDate")
                }
                onClick={handleSubmit(onSubmit)}
              >
                {t("apply")}
              </Button>
            </Box>
          </Grid>
        </Box>
        <Box display={areFiltersOpen ? "block" : "none"}>
          <Grid container spacing={2}></Grid>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
            >
              {t("apply")}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.clearBtn}
              onClick={() => {
                clearFilters();
                handleSubmit(onSubmit)();
              }}
              disabled={disableClear()}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Paper>
  );
};

export default Filters;
