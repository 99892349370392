import { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import InputField from "shared/ui/Input";
import ButtonLoader from "components/ButtonLoader";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import { useDispatch } from "react-redux";
import { createUser, updateUser } from "src/store/slicers/users";
import toast from "react-hot-toast";
import { emailRegex } from "src/helpers/validators";
import { useTranslation } from "react-i18next";

const defaultRules = (maxLength: number, t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
    maxLength: {
      value: maxLength,
      message: `${t("maximum")} ${maxLength} ${t("character")}`,
    },
  };
};

const useStyles = makeStyles({
  form: {},
  btn: {},
});

const CreateUserForm = ({
  updateData,
  isUpdate,
  onClose,
  onSave,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const submitCallback = async (formData) => {
    setLoading(true);

    const action = isUpdate ? updateUser : createUser;
    const { meta } = await dispatch(action({ id: updateData.id, formData }));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    toast.success(isUpdate ? t("user_successfully_added") : t("user_created"));

    onClose();
    fetchData();
    setLoading(false);
  };

  useEffect(() => {
    if (!updateData) {
      return;
    }

    reset({
      name: updateData.name,
      surName: updateData.surName,
      email: updateData.email,
      phone: updateData.phone,
      limit: updateData.limit,
    });
  }, [reset, updateData]);

  return (
    <form className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputField
            name="name"
            label={t("name")}
            control={control}
            rules={defaultRules(50, t)}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="surName"
            label={t("surname")}
            control={control}
            rules={defaultRules(50, t)}
            errors={errors}
          />
        </Grid>
        {!isUpdate && (
          <Grid item xs={6}>
            <InputField
              name="email"
              label={t("email")}
              control={control}
              rules={{
                ...defaultRules(50, t),
                pattern: {
                  value: emailRegex,
                  message: t("wrong_email"),
                },
              }}
              errors={errors}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <InputField
            name="phone"
            label={t("phone_number")}
            control={control}
            onKeyPress={onlyNumericalHandler}
            rules={defaultRules(11, t)}
            onPaste={onlyNumericalPasteHandler}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" py={1.6}>
            <ButtonLoader
              className={classes.btn}
              onClick={handleSubmit(submitCallback)}
              isLoading={loading}
            >
              {t("approve")}
            </ButtonLoader>
            <Box ml={2}>
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={onClose}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateUserForm;
