import HaypostLogo from "src/assets/print-icons/haypost.svg";

import { Grid, Box } from "@material-ui/core";
import { printStyles } from "../printStyles";

const EMSInstructions = (): JSX.Element => {
  const classes = printStyles();

  return (
    <Grid className={classes.emsMainBorders}>
      <Box className={classes.emsContentArea} fontSize="9px">
        <Box paddingTop="10px" fontWeight="bold">
          Instructions
        </Box>
        <Box>
          You should attach this customs declaration and accompanying documents
          securely to the outside of the item, preferably in an adhesive
          transparent envelope. If the declaration is not clearly visible on the
          outside, or if you prefer to enclose it inside the item, you must fix
          a label to the outside indicating the presence of a customs
          declaration. To accelerate customs clearance, complete this
          declaration in English, French or in a language accepted in the
          destination country. To clear your item, the Customs in the country of
          destination need to know exactly what the contents are. You must
          therefore complete your declaration fully and legibly, otherwise,
          delay and inconvenience may result for the addressee. A false or
          misleading declaration may lead to a fine or to seizure of the item.
          Your goods may be subject to restrictions. It is your responsibility
          to enquire into import and export regulations (prohibitions,
          restrictions such as quarantine, pharmaceutical restrictions, etc.)
          and to find out what documents, if any (commercial invoice,
          certificate of origin, health certificate, licence, authorization for
          goods subject to quarantine (plant, animal, food products, etc.) are
          required in the destination country. Commercial item means any goods
          exported/imported in the course of a business transaction, whether or
          not they are sold for money or exchanged.
        </Box>
        <Box>
          (18) Give a detailed description of each article in the item, e.g.
          "men's cotton shirts". General descriptions, e.g. "spare parts",
          "samples" or "lood products are not permitted.
        </Box>
        <Box>
          (19) Give the quantity of each article and the unit of measurement
          used.
        </Box>
        <Box>
          (20) and (21) Give the net weight of each article (in kg). Give the
          total weight of the item (in kg), including packaging, which
          corresponds to the weight used to calculate the postage.{" "}
        </Box>
        <Box>
          (22) and (23) Give the value of each article and the total, indicating
          the currency used (e.g. AMD for Armenian dram, CHF for Swiss francs).
        </Box>
        <Box>
          (24) and (25) The HS tariff number (6-digit) must be based on the
          Harmonized Commodity Description and Coding System developed by the
          World Customs Organization. "Country of origin means the country where
          the goods originated, e.g. were produced/manufactured or assembled.
          Senders of commercial items are advised to supply this information as
          it will assist Customs in processing the items.{" "}
        </Box>
        <Box>(26) Tick the box or boxes specifying the category of item.</Box>
        <Box>
          (27) Provide details if the contents are subject to quarantine (plant,
          animal, food products, etc.) or other restrictions.
        </Box>
        <Box>
          (28), (29) and (30) If your item is accompanied by a licence or a
          certificate, tick the appropriate box and state the number. You should
          attach an invoice for all commercial items.
        </Box>
        <Box>
          (31) Your signature and the date confirm your liability for the item.
        </Box>
        <Box paddingTop="10px" fontWeight="bold">
          Ցուցումներ.
        </Box>
        <Box>
          Դուք պետք է այս մաքսային հայտարարագիրը ուղեկցող փաստաթղթերն ամուր
          փակցնեք առաքանու արտաքին կողմին: Նախընտրելի է, որ այն դրված լինի
          ինքնակպչուն թափանցիկ ծրարի մեջ։ Եթե այն դրսից հստակ չի երեւում, կամ
          եթե Դուք նախընտրում եք այն դնել առաքանու մեջ, Դուք պետք է դրսից
          փակցնեք պիտակ, որը կմատնանշի մաքսային հայտարարագրի առկայությունը
          Մաքսային զննումն արագացնելու նպատակով խնդրում ենք այս հայտարարագիրը
          լրացնել ֆրանսերեն, անգլերեն կան նշանակման երկրի կողմից ընդունված այլ
          լեզվով: Ահազանին մաքսազերծելու համար նշանակման երկրի մաքսատունը պետք է
          տեղեկանա ներդրվածքի հստակ նկարագրի մասին, ուստի Դուք պետք է այն
          լրացնեք ամբողջական եւ ընթեռնելի ձեւով: Հակառակ դեպքում դա կարող է
          հանգեցնել առաքանու առաքման ուշացումների եւ հասցեատիրոջ համար այլ
          անցանկալի իրավիճակների: Ցանկացած կեղծ կամ սխալ լրացված հայտարարագիրը
          կարող է հանգեցնել Լուգանքի կամ առաքանու առգրավման: Ձեր ապրանքները
          կարող են սահմանափակումների առարկա դառնալ: Ուստի Դուք պետք է նախապես
          Մեղեկանաք ներկրման եւ արտահանման հնարավորություններին (այնպիսի
          արգելքներ, սահմանափակումներ, ինչպիսիք են դեղագործական արտադրանքներին
          վերաբերող | սահմանափակումները, կարանտինը եւ այլն) եւ այն փաստաթղթերին
          (հաշիվ-ապրանքագիր, ծագման հավաստագիր, սանիտարական հավաստագիր,
          արտոնագիր, թույլտվություն կարանտինի ենթակա ապրանքների համար
          (կենդանական, բուսական, սննդային արտադրանքներ), որոնք հավանաբար
          կպահանջվեն նշանակման երկրում: «Կոմերցիոն առաքանի» նշանակում է ցանկացած
          ապրանք՝ արտահանված կամ ներկրված առեւտրային գործարքի արդյունքում, անկախ
          նրանից՝ այն վաճառված է գումարի դիմաց, թե փոխանակված:
        </Box>
        <Box>
          {" "}
          (18) Խնդրում ենք ներկայացնել առաքանու ներդրվածքի յուրաքանչյուր իրի
          մանրակրկիտ նկարագիրը (օր.՝ Տղամարդու բամբակյա վերնաշապիկ)։ Չեն
          ընդունվում ընդհանուր բնույթի այնպիսի նշումներ, ինչպիսիք են՝
          «պահեստամասեր», «նմուշներ», «սննդամթերք» եւ այլն:
        </Box>
        <Box>
          (19) Անհրաժեշտ է նշել յուրաքանչյուր իրի քանակը եւ կիրառված չափման
          միավորը:
        </Box>
        <Box>
          (20) եւ (21) Անհրաժեշտ է նշել յուրաքանչյուր իրի զուտ քաշը (կգ-ով)։
          Նշել առաքանու ընդհանուր քաշը` փաթեթավորման քաշը ներառյալ (կգ-ով), որը
          համապատասխանում է տրման ժամանակ կշռված քաշին:
        </Box>
        <Box>
          (22) եւ (23) Անհրաժեշտ է նշել յուրաքանչյուր իրի զուտ արժեքը, ինչպես
          նաեւ ընդհանուր արժեքը՝ նշելով կիրառվող դրամական միավորը (օրինակ՝ AMD`
          ՀՀ դրամի, CHF՝ շվեյցարական ֆրանկի համար):
        </Box>
        <Box>
          (24) եւ (25) Հարկավոր է նշել ԱՏԳԱԱ ծածկագիրը (6 նիշ), որը պետք է
          հիմնված լինի Համաշխարհային մաքսային կազմակերպության մշակած ապրանքների
          ներդաշնակ համակարգի նկարագրի եւ կոդավորման համակարգի վրա: «Ծագման
          երկիր» նշանակում է այն երկիրը, որտեղ ապրանքները արտադրվել կամ
          խմբավորվել են:{" "}
        </Box>
        <Box>
          (26) Անհրաժեշտ է նշում անել առաքանու տեսակը մատնանշող վանդակում կամ
          վանդակներում:
        </Box>
        <Box>
          (27) Անհրաժեշտ է հստակեցնել՝ արդյոք ներդրվածքը (կենդանական, բուսական,
          սննդային ծագմամբ արտադրանք) ենթակա է կարանտինի կամ սահմանափակումների։
        </Box>
        <Box>
          (28), (29) եւ (30) Եթե Ձեր առաքանին ուղեկցվում է հավաստագրով կամ
          լիցենզիայով, անհրաժեշտ է նշում անել համապատասխան վանդակում եւ լրացնել
          հավաստագրի կամ լիցենզիայի համարը: Ցանկացած կոմերցիոն առաքանու
          անհրաժեշտ է կցել հաշիվ-ապրանքագիր:{" "}
        </Box>
        <Box>
          (30) Ձեր ստորագրությունը եւ ամսաթիվը հաստատում են Ձեր
          պատասխանատվությունն առաքանու համար։
        </Box>
        <Box textAlign="right">
          <img src={HaypostLogo} alt="logo" />
        </Box>
      </Box>
    </Grid>
  );
};

export default EMSInstructions;
