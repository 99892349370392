import { Grid } from "@material-ui/core";
import { printStyles } from "../printStyles";

const CN23EnclosuresArea = (): JSX.Element => {
  const classes = printStyles();

  return (
    <Grid className={classes.cn23SecondSection}>
      <Grid className={classes.cn23SecondSectionLeftArea}>
        <div className={classes.cn23SecondSectionFirstColumn}>
          <div>
            <p className={classes.lettersSize7}>
              Ներդրվածքի մանրամասն նկարագրություն(1)
            </p>
            <p className={classes.lettersSize7}>
              Detailed description of contents(1)
            </p>
          </div>
        </div>

        <div className={classes.cn23SecondSectionSecondColumn}>
          <div className={classes.cp72HeaderArea}>
            <p className={classes.lettersSize7}>Քանակ(2)</p>
            <p className={classes.lettersSize7}>Quantity(2)</p>
          </div>
        </div>
        <div className={classes.cn23SecondSectionSecondColumn}>
          <div className={classes.cp72HeaderArea}>
            <p className={classes.lettersSize7}>Զտաքաշ(կգ-ով)(3)</p>
            <p className={classes.lettersSize7}>Net wight</p>
            <p className={classes.lettersSize7}>(in kg)(3)</p>
          </div>
        </div>
      </Grid>
      <Grid className={classes.cn23SecondSectionRightArea}>
        <div
          className={classes.cn23SecondSectionSecondColumn}
          style={{ width: "63.2px" }}
        >
          <div className={classes.cp72HeaderArea}>
            <p className={classes.lettersSize7}>Արժեք(5)</p>
            <p className={classes.lettersSize7}>Value(5)</p>
          </div>
        </div>
        <div className={classes.cn23SecondSectionThirdColumnArea}>
          <div className={classes.cp72SecondSectionThirdColumnAreaTitle}>
            <p className={classes.lettersSize7}>
              Միայն կոմերցիոն առաքանիների համար
            </p>
            <p className={classes.lettersSize7}>For commercial items only</p>
          </div>
          <div className={classes.cp72SecondSectionThirdColumnItems}>
            <div
              className={`${classes.cn23SecondSectionThirdColumn} ${classes.edgingRight}`}
            >
              <div className={classes.cp72HeaderArea}>
                <p className={classes.lettersSize7}>ՆՀ սակագնային թիվ(7)</p>
                <p className={classes.lettersSize7}>HS tariff number(7)</p>
              </div>
            </div>
            <div className={classes.cn23SecondSectionThirdColumn}>
              <div className={classes.cp72HeaderArea}>
                <p className={classes.lettersSize7}>
                  Ապրանքների ծագման երկիր(8)
                </p>
                <p className={classes.lettersSize7}>
                  Country of origin of goods(8)
                </p>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CN23EnclosuresArea;
