import { useState } from "react";
import useQueryParams from "helpers/useQueryParams";

const useTableSearch = (defaultQueries, fetchData) => {
  const [getQueries, setQueries] = useQueryParams();
  const [value, setValue] = useState(getQueries().searchText);
  const [prevValue, setPrevValue] = useState(value);
  const onChange = (event) => {
    if (event.code === "Enter") {
      submit();
    }
    setPrevValue(value);
    setValue(event.target.value);
  };
  const submit = () => {
    if (value !== prevValue) {
      const queries = { ...defaultQueries, ...getQueries(), searchText: value };
      !value && delete queries.searchText;
      setQueries(queries);
      fetchData(queries);
    }
  };

  return {
    value,
    onChange,
    submit,
  };
};

export default useTableSearch;
