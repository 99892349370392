import { forwardRef, Fragment } from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import BarcodeToPrint from "src/components/BarcodeToPrint";

interface ICodLandscape {
  width: number;
  activeOrder: any;
}

const CodLandscape = forwardRef((props: ICodLandscape, ref) => {
  const { activeOrder } = props;

  const { provinceState, cityVillage, street, building, apartment } =
    activeOrder?.returnSender;
  const {
    provinceState: recipientProvinceState,
    cityVillage: recipientCityVillage,
    street: recipientStreet,
    building: recipientBuilding,
    apartment: recipientApartment,
    country: recipientCountry,
  } = activeOrder?.recipient;

  return (
    <Box width="80mm" fontSize="10px" ml={1} my={1}>
      {activeOrder && (
        <Fragment>
          {activeOrder?.returnSender?.phone ? (
            <Box>Հեռ : {activeOrder.returnSender?.phone}</Box>
          ) : null}
          {activeOrder.organization ? (
            <Box>{activeOrder.organization}</Box>
          ) : null}
          {activeOrder.note1 ? <Box>{activeOrder.note1}</Box> : null}
          {activeOrder.returnSender?.provinceState ? (
            <Box>
              Հասցե :{" "}
              {"Armenia" +
                " " +
                provinceState +
                " " +
                cityVillage +
                " " +
                street +
                " " +
                building +
                " " +
                (apartment ? apartment : "")}
            </Box>
          ) : null}
          {activeOrder.returnSender?.postalCode ? (
            <Box>Դասիչ : {activeOrder.returnSender?.postalCode}</Box>
          ) : null}
          <Box>Ամսաթիվ : {moment(new Date()).format("DD.MM.YYYY")}</Box>
          {activeOrder.recipient.nameSurname ? (
            <Box>{activeOrder.recipient.nameSurname}</Box>
          ) : (
            <Box>{activeOrder.recipient.legalName}</Box>
          )}
          {activeOrder.recipient.phone ? (
            <Box>Հեռ : {activeOrder.recipient.phone}</Box>
          ) : null}
          {activeOrder.recipient.provinceState ? (
            <Box>
              Հասցե :{" "}
              {activeOrder?.country?.name +
                " " +
                recipientProvinceState +
                " " +
                recipientCityVillage +
                " " +
                recipientStreet +
                " " +
                recipientBuilding +
                " " +
                (recipientApartment ? recipientApartment : "")}
            </Box>
          ) : null}
          {activeOrder.recipient?.postalCode ? (
            <Box>Դասիչ : {activeOrder.recipient?.postalCode}</Box>
          ) : null}
          {activeOrder.note2 ? <Box>{activeOrder.note2}</Box> : null}
          {activeOrder.cod ? <Box>Վերադիր վճար : {activeOrder.cod}</Box> : null}
          {activeOrder.trackingNumber ? (
            <Box textAlign="center">
              <BarcodeToPrint barcode={activeOrder.trackingNumber} />
            </Box>
          ) : null}
        </Fragment>
      )}
    </Box>
  );
});

export default CodLandscape;
