import { Typography } from "@material-ui/core";
import format from "date-fns/format";
import { amountFormatter } from "src/helpers";

const translatedColumns = (t) => {
  return [
    {
      field: "id",
      title: t("number"),
    },
    {
      field: "amount",
      title: t("amount"),
      layout: (row) => (
        <Typography>{amountFormatter(row.amount.toString())} AMD</Typography>
      ),
    },
    {
      field: "transactionType",
      title: t("type"),
      layout: (row) => <Typography>{row.transactionType?.name}</Typography>,
    },
    {
      field: "createdDate",
      title: t("creation_date"),
      layout: (row) => (
        <Typography>
          {format(new Date(row.createDate), "dd/MM/yyyy HH:mm")}
        </Typography>
      ),
    },
  ];
};

export { translatedColumns };
