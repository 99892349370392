import { useMemo } from "react";

import { Grid, Box } from "@material-ui/core";

const useEnclosuresHook = (
  printData: any,
  type: number,
  classes: any
): JSX.Element[] | "" =>
  useMemo(() => {
    if (printData.length) {
      if (type === 1) {
        return printData.slice(0, 3).map((item, index) => (
          <Box
            key={index}
            fontSize="6px"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Grid className={`${classes.cp72ThirdSection}`}>
              <div className={classes.cp72ThirdSectionItem}>
                <Box
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {item.description}
                </Box>
              </div>
              <div
                className={classes.cp72ThirdSectionItem1}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Quantity}
              </div>
              <div
                className={classes.cp72ThirdSectionItem2}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Weight / 1000} Kg
              </div>
              <div
                className={classes.cp72ThirdSectionItem6}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Amount} AMD
              </div>
              <div
                className={classes.cp72ThirdSectionItem3}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.HsCode}
              </div>
              <div
                className={classes.cp72ThirdSectionItem4}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.CountryOfOrigin}
              </div>
            </Grid>
          </Box>
        ));
      } else if (type === 2) {
        return printData.slice(3).map((item, index) => (
          <Box
            key={index}
            fontSize="6px"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Grid className={`${classes.cp72ThirdSection}`}>
              <div className={classes.cp72ThirdSectionItem}>
                <Box
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {item.description}
                </Box>
              </div>
              <div
                className={classes.cp72ThirdSectionItem1}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Quantity}
              </div>
              <div
                className={classes.cp72ThirdSectionItem2}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Weight / 1000} Kg
              </div>
              <div
                className={classes.cp72ThirdSectionItem6}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Amount} AMD
              </div>
              <div
                className={classes.cp72ThirdSectionItem3}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.HsCode}
              </div>
              <div
                className={classes.cp72ThirdSectionItem4}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.CountryOfOrigin}
              </div>
            </Grid>
          </Box>
        ));
      } else if (type === 3) {
        return printData.slice(0, 3).map((item, index) => (
          <Box
            className={`${classes.cn23ThirdSection}`}
            display="flex"
            borderRight="1px solid"
            key={index}
            fontSize="6px"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Box width="87.1mm" display="flex" borderRight="1px solid">
              <div className={classes.cn23ThirdSectionItem}>
                <Box
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {item.description}
                </Box>
              </div>
              <div
                className={classes.cn23ThirdSectionItem1}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {" "}
                {item.Quantity}
              </div>
              <Box
                className={classes.cn23ThirdSectionItem2}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Weight / 1000} Kg
              </Box>
            </Box>
            <Box width="87mm" display="flex">
              <div
                className={classes.cn23ThirdSectionItem6}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Amount} AMD
              </div>
              <div
                className={classes.cn23ThirdSectionItem3}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.HsCode}
              </div>
              <div
                className={classes.cn23ThirdSectionItem4}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.CountryOfOrigin}
              </div>
            </Box>
          </Box>
        ));
      } else {
        return printData.slice(3).map((item, index) => (
          <Box
            className={`${classes.cn23ThirdSection} ${classes.edgingTop}`}
            display="flex"
            borderRight="1px solid"
            key={index}
            fontSize="6px"
          >
            <Box width="87.1mm" display="flex" borderRight="1px solid">
              <div
                className={classes.cn23ThirdSectionItem}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.description}{" "}
              </div>
              <div
                className={classes.cn23ThirdSectionItem1}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {" "}
                {item.Quantity}
              </div>
              <Box
                className={classes.cn23ThirdSectionItem2}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Weight / 1000} Kg
              </Box>
            </Box>
            <Box width="87mm" display="flex">
              <div
                className={classes.cn23ThirdSectionItem6}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.Amount} AMD
              </div>
              <div
                className={classes.cn23ThirdSectionItem3}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.HsCode}
              </div>
              <div
                className={classes.cn23ThirdSectionItem4}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item.CountryOfOrigin}
              </div>
            </Box>
          </Box>
        ));
      }
    }

    return "";
  }, [
    classes.cn23ThirdSection,
    classes.cn23ThirdSectionItem,
    classes.cn23ThirdSectionItem1,
    classes.cn23ThirdSectionItem2,
    classes.cn23ThirdSectionItem3,
    classes.cn23ThirdSectionItem4,
    classes.cn23ThirdSectionItem6,
    classes.cp72ThirdSection,
    classes.cp72ThirdSectionItem,
    classes.cp72ThirdSectionItem1,
    classes.cp72ThirdSectionItem2,
    classes.cp72ThirdSectionItem3,
    classes.cp72ThirdSectionItem4,
    classes.cp72ThirdSectionItem6,
    classes.edgingTop,
    printData,
    type,
  ]);

export default useEnclosuresHook;
