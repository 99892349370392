import { Box, Paper } from "@material-ui/core";
import { forwardRef } from "react";
import BarcodeContainer from "src/components/BarcodeContainer";
import { printStyles } from "./printStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ReestrPrintView = forwardRef(({ ordersList }, ref) => {
  const classes = printStyles();

  return (
    <Box
      style={{
        width: "95%",
        margin: "10px auto 5px",
      }}
      ref={ref}
      className={classes.reestrWrapper}
    >
      <Box className={classes.reestrTitleArea}>
        <Box className={classes.reestrTitle} mt={2}>
          <Box textAlign="center">
            <Box>Ռեեստր</Box>
            <Box>{ordersList?.organizationName}</Box>
          </Box>
        </Box>
        <Box className={classes.reestrTitleBarcode}>
          <BarcodeContainer
            code={`C${ordersList?.totalCount} W${ordersList?.totalWeight}`}
            height={180}
            showValue={false}
            width={5}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Համար
              </TableCell>
              <TableCell
                padding="default"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Հետևման կոդ
              </TableCell>
              <TableCell
                padding="default"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Հավելյալ նշում 1
              </TableCell>
              <TableCell
                padding="default"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Հավելյալ նշում 2
              </TableCell>
              <TableCell
                padding="default"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Ստացողի անունը
              </TableCell>
              <TableCell
                padding="default"
                align="center"
                size="medium"
                className={classes.reestrTableCellStyles}
              >
                Ստացողի հասցեն
              </TableCell>
              <TableCell
                padding="none"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Առաքանու քաշը
              </TableCell>
              <TableCell
                padding="none"
                align="center"
                size="small"
                className={classes.reestrTableCellStyles}
              >
                Առաքանու գինը
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersList?.data?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    padding="none"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    padding="default"
                    align="center"
                    size="medium"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.trackingNumber && (
                      <BarcodeContainer
                        code={item.trackingNumber}
                        height={20}
                        fontSize={15}
                        width={1}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    padding="default"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.note1}
                  </TableCell>
                  <TableCell
                    padding="default"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.note2}
                  </TableCell>
                  <TableCell
                    padding="default"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.recipient}
                  </TableCell>
                  <TableCell
                    padding="default"
                    align="center"
                    size="medium"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.recipientAddress}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.weights}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    size="small"
                    className={classes.reestrTableCellStyles}
                  >
                    {item?.amount}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={5}>
        <Box display="flex" alignItems="end" justifyContent="flex-end" mt={2}>
          <Box mr={4}>Հայփոստ `</Box>
          <Box height="1px" width={450} borderBottom="1px solid"></Box>
        </Box>
        <Box display="flex" alignItems="end" justifyContent="flex-end" mt={2}>
          <Box mr={4}>Հաճախորդ `</Box>
          <Box height="1px" width={450} borderBottom="1px solid"></Box>
        </Box>
        <Box display="flex" alignItems="end" justifyContent="flex-end" mt={2}>
          <Box mr={4}>Ամսաթիվ `</Box>
          <Box height="1px" width={450} borderBottom="1px solid"></Box>
        </Box>
      </Box>
    </Box>
  );
});

export default ReestrPrintView;
