import { makeStyles } from "@material-ui/core/styles";
import theme from "src/theme";

const tableStyles = makeStyles({
  actionItem: {
    margin: "15px 0 15px 15px",
  },
  title: {
    color: "#546e7a",
  },
  table: {
    minWidth: 650,
  },
  headCell: {
    color: "grey",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontSize: "16px",
  },
  bodyCell: {
    fontSize: "16px",
    color: "inherit",
  },
  pagination: {
    background: "white",
    marginTop: "5px",
    border: "1px solid #ddd",
    borderRadius: "2px",
  },
  container: {
    position: "relative",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    background: "white",
    marginBottom: "5px",
    border: "1px solid #ddd",
    borderRadius: "2px",
  },
  rowDefault: {
    fontSize: "12px",
    color: "#263238",
  },
  successfulItemBg: {
    background: theme.palette.success.secondary,
  },
  errorItemBg: {
    background: theme.palette.error.secondary,
  },
});

export default tableStyles;
