import { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EnclosureDialogForm, {
  enclosureDefaultData,
} from "./EnclosuresDialogForm";
import { FormProvider, useForm } from "react-hook-form";
import CircularLoader from "src/components/CircularLoader";
import { useDispatch } from "react-redux";
import {
  setEnclosuresData,
  setEnclosuresEdited,
} from "src/store/slicers/order";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const EnclosuresDialog = ({ open, dialogTitle, setOpen, mode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      enclosures: [enclosureDefaultData],
    },
    mode: "all",
  });

  const handleClose = () => {
    setTimeout(() => setOpen(false), 200);
  };

  const onSubmit = async (data) => {
    const newEnclosuresData = data.enclosures.map((item) => {
      if (!item.countryOfOrigin || !item.hsCode) {
        return;
      }
      return {
        ...item,
        countryOfOrigin: item.countryOfOrigin.name,
        hsCode: item.hsCode.code,
      };
    });
    const regex = /^\d+$/;
    const isInputField = data.enclosures.map((item) => {
      return {
        weight: item.weight,
        quantity: item.quantity,
        amount: item.amount,
      };
    });

    const isNotValidInputs = isInputField.find(
      (item) =>
        !regex.test(item.weight) ||
        !regex.test(item.quantity) ||
        !regex.test(item.amount)
    );
    const isError = newEnclosuresData.filter((item) => !item);

    if (isError?.length) {
      toast.error(t("country_and_hs_codes_are_required"));
      return;
    }
    if (isNotValidInputs && Object.values(isNotValidInputs)?.length) {
      toast.error(t("only_whole_numbers"));
      return;
    }

    setIsLoading(true);
    dispatch(setEnclosuresData(newEnclosuresData));
    dispatch(setEnclosuresEdited(true));
    setIsLoading(false);
    handleClose();
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Dialog
          transitionDuration={{ enter: 700, exit: 700 }}
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CircularLoader loading={isLoading}>
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <EnclosureDialogForm
                handleClose={handleClose}
                onSubmit={onSubmit}
                mode={mode}
                setIsLoading={setIsLoading}
              />
            </DialogContent>
          </CircularLoader>
        </Dialog>
      </FormProvider>
    </Fragment>
  );
};

export default EnclosuresDialog;
