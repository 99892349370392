import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "store/slicers/auth";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  colors,
  Typography,
  Chip,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import RefreshIcon from "@material-ui/icons/Refresh";
import Logo from "../../assets/images/logo.png";
import { LSRemoveMultiple } from "src/helpers";
import { LStorage } from "store/config/constants";
import { selectUserData } from "store/slicers/users";
import {
  selectBalances,
  fetchOrganizationBalances,
} from "store/slicers/organizations";
import { FormProvider, useForm } from "react-hook-form";
import MUISelect from "src/shared/ui/Select";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { RestrictedUsers } from "src/views/Orders/data/constants";

const useStyles = makeStyles({
  root: {
    boxShadow: "0px 0 14px 0px rgb(0 0 0 / 40%)",
  },
  clientRoleText: {
    textTransform: "capitalize",
    fontWeight: "500",
    marginRight: "30px",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: "500",
    marginRight: "5px",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  languagePicker: {
    width: "75px",
    color: "rgb(255, 255, 255)",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& svg": {
      "& path": {
        fill: "rgb(255, 255, 255)",
      },
    },
  },
});

export enum ELanguages {
  EN = "1",
  AM = "2",
  RU = "3",
}

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const userInfo = useSelector(selectUserData);
  const dispatch = useDispatch();
  const balances = useSelector(selectBalances);
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      lng: "",
    },
  });

  const { control, reset } = methods;

  const amdBalance = useMemo(
    () => balances?.find((i) => i.currencyId === 1),
    [balances]
  );

  const logOutAction = useCallback(() => {
    LSRemoveMultiple([
      LStorage.accessToken,
      LStorage.refreshToken,
      LStorage.expDate,
    ]);
    dispatch(signOut());
    navigate("/login", { replace: true });
  }, [dispatch, navigate]);

  const handleFetchData = () => {
    dispatch(fetchOrganizationBalances());
  };

  const handleLanguageChange = (value: string) => {
    const selectedLang = menuItems.find((item) => item.id == value);
    i18next.changeLanguage(selectedLang.value);
    localStorage.setItem("i18nextLng", selectedLang.value);
  };

  const menuItems = useMemo(
    () => [
      {
        id: ELanguages.EN,
        value: "en",
        name: t("languageLabels.english"),
      },
      {
        id: ELanguages.AM,
        value: "arm",
        name: t("languageLabels.armenian"),
      },
      {
        id: ELanguages.RU,
        value: "rus",
        name: t("languageLabels.russian"),
      },
    ],
    [t]
  );

  const init = () => {
    const lsLang = localStorage.getItem("i18nextLng");
    const isRestricted = RestrictedUsers.includes(userInfo?.email);
    if (!lsLang) {
      localStorage.setItem("i18nextLng", isRestricted ? "en" : "arm");
      reset({
        lng: isRestricted ? ELanguages.EN : ELanguages.AM,
      });
      return;
    }
    const selectedLang = menuItems.find((item) => item.value == lsLang);
    reset({
      lng: selectedLang?.id,
    });
  };

  useEffect(() => {
    if (userInfo) {
      init();
    }
  }, [userInfo]);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <div className={classes.logoLink}>
          <Box
            style={{ cursor: "pointer" }}
            m={1}
            onClick={() => navigate("/admin/orders")}
          >
            <img style={{ height: 40 }} src={Logo} />
          </Box>
        </div>
        <Box flexGrow={1} />
        {balances?.length ? (
          <Box mr={3} display="flex" alignItems={"center"}>
            <IconButton
              onClick={handleFetchData}
              style={{ color: colors.common.white }}
            >
              <RefreshIcon />
            </IconButton>
            <Typography
              style={{ fontWeight: "bold", fontSize: 16 }}
            >{`${amdBalance?.balance} AMD`}</Typography>
          </Box>
        ) : null}
        <Box>
          <Chip label={`${userInfo?.name} ${userInfo?.surName}`} />
        </Box>
        <FormProvider {...methods}>
          <MUISelect
            control={control}
            name="lng"
            label=""
            size="medium"
            textProp="name"
            className={classes.languagePicker}
            options={menuItems}
            valueProp="id"
            onChange={handleLanguageChange}
            fullWidth={false}
          />
        </FormProvider>
        <IconButton
          style={{ color: colors.common.white }}
          onClick={logOutAction}
        >
          <Typography className={classes.title}>{t("logout")}</Typography>
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen}>
            <MenuIcon htmlColor="white" />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
