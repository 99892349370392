import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const PasswordToggle = ({ togglePassword, isPasswordVisible }) => {
    return (
        <InputAdornment position="end">
            <IconButton onClick={togglePassword} aria-label="toggle password visibility">
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
};
