import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  wrap: {
    height: "initial !important",
    background: "white",
  },
  cn07Container: {
    height: "510px",
    width: "756px",
  },
  "*, ::after, ::before": {
    color: "#000 !important",
    textShadow: "none !important",
    background: "0 0 !important",
    boxShadow: "none !important",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  textRight: {
    textAlign: "right",
  },
  floatLeft: {
    float: "left",
    "& b": {
      fontSize: "23px",
    },
  },
  leftTextAlignment: {
    textAlign: "left",
    display: "inline-block",
  },
  contain: {
    width: "95%",
    border: "solid 1px black",
    fontSize: "9px",
    margin: "0 auto",
  },
  left: {
    width: "100%",
    border: "solid 1px black",
    marginLeft: "3px",
  },
  border: {
    border: "solid 1px black",
  },
  clear: {
    clear: "both",
  },
  label: {
    float: "right",
  },
  borderTopNon: {
    borderTop: "none",
  },
  amount: {
    borderLeft: "1px solid black",
    display: "inline-block",
    margin: "3px",
    paddingLeft: "5px",
  },
  vertical: {
    transform: "rotate(270deg)",
    position: "absolute",
    top: "320px",
    right: "205px",
  },
  infoContent: {
    display: "flex",
  },
  rightNoBorder: {
    "& div": {
      margin: "17px 0",
    },
  },
}));

export default useStyles;
