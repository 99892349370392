import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "src/router/routes";
import { AppDispatch } from "src/store";
import { getOrderReceipt } from "src/store/slicers/order";
import { fetchOrganizationLogo } from "src/store/slicers/organizations";
import { selectUserData } from "src/store/slicers/users";
import {
  EAdditionalServices,
  EPrintOptions,
  EServiceTypes,
  ETypeList,
  StatusList,
} from "../data/constants";

interface IUsePrintDialogButtonOptions {
  printOrders: any;
  open: Boolean;
}

const PAID_STATUS = 6;

const useOrderPrintDialogActions = ({
  printOrders,
  open,
}: IUsePrintDialogButtonOptions) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(selectUserData);
  const [isChequePrintButtonDisabled, setChequePrintButtonDisabled] =
    useState(false);

  const [config, setConfig] = useState({
    showReturnAddress: false,
    showSenderLogo: false,
    showAdditionalNotes: false,
    showAdditionalNotes2: false,
    showReceiverAddress: false,
  });

  const [coreReceiptData, setCoreReceiptData] = useState([]);

  const handleChangeConfig = (key) => {
    setConfig({
      ...config,
      [key]: !config[key],
    });
  };

  const fetchReceiptInfo = async () => {
    setChequePrintButtonDisabled(true);
    const parcelIds = filteredPaidOrders.map((i) => i.id);

    const { meta, payload } = await dispatch(getOrderReceipt({ parcelIds }));

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    setCoreReceiptData(payload || []);
    setChequePrintButtonDisabled(false);
  };

  const filteredCN07Orders = useMemo(() => {
    return printOrders?.filter(
      (order) =>
        [
          EAdditionalServices.ORDERED_NOTIFICATION,
          EAdditionalServices.SIMPLE_NOTIFICATION,
          EAdditionalServices.International_Notification,
        ]?.includes?.(order.additionalServiceIds?.[0]) ||
        ([
          EAdditionalServices.ORDERED_NOTIFICATION,
          EAdditionalServices.SIMPLE_NOTIFICATION,
          EAdditionalServices.International_Notification,
        ]?.includes?.(order.additionalServiceIds?.[1]) &&
          order.category?.id === ETypeList.ORDERED)
    );
  }, [printOrders]);

  const filteredPaidOrders = useMemo(() => {
    const paidStatus = StatusList.find((i) => i.id === PAID_STATUS)?.id;

    return printOrders?.filter((order) => order.status.id === paidStatus);
  }, [printOrders]);

  const filteredEnclosures = useMemo(() => {
    const filteredItems = printOrders.filter(
      (item) => JSON.parse(item.enclosures)?.length
    );
    return filteredItems;
  }, [printOrders]);

  const filteredEMSItems = useMemo(() => {
    const filteredItems = printOrders.filter(
      (item) =>
        item.service.id == EServiceTypes.EMS_Letter ||
        item.service.id == EServiceTypes.EMS_Parcel
    );
    return filteredItems;
  }, [printOrders]);

  const availableOptions = useMemo(
    () => [
      {
        id: EPrintOptions.DL,
        name: t("DL_envelope"),
        height: 110.07,
        width: 220,
      },
      {
        id: EPrintOptions.C5,
        name: t("C5_envelope"),
        height: 161.66,
        width: 226,
      },
      {
        id: EPrintOptions.C6,
        name: t("C6_envelope"),
        height: 113.24,
        width: 160,
      },
      {
        id: EPrintOptions.A4,
        name: t("A4_envelope"),
        height: 210.08,
        width: 297,
      },
      {
        id: EPrintOptions.TAX_PERCUE,
        name: t("revenue_envelope"),
        height: 30,
        width: 47,
      },
    ],

    [filteredCN07Orders, filteredEnclosures, t]
  );

  const commonOptions = useMemo(
    () => [
      ...(filteredCN07Orders?.length
        ? [
            {
              id: EPrintOptions.CN07,
              name: t("CN07"),
              height: 95,
              width: 144,
              disabled: false,
            },
          ]
        : []),
      ...(filteredPaidOrders?.length && userInfo?.role !== Roles.SuperAdmin
        ? [
            {
              id: EPrintOptions.CHEQUE,
              name: t("receipt"),
              height: 300,
              disabled: isChequePrintButtonDisabled,
            },
          ]
        : []),
      ...(!filteredEMSItems?.length && filteredEnclosures?.length
        ? [
            {
              id: EPrintOptions.CN22,
              name: "CN22",
              disabled: false,
            },
          ]
        : []),
      ...(!filteredEMSItems?.length && filteredEnclosures?.length
        ? [
            {
              id: EPrintOptions.CN23_CP72,
              name: "CN23_CP72",
              disabled: false,
            },
          ]
        : []),
      ...(filteredEMSItems?.length
        ? [
            {
              id: EPrintOptions.EMS,
              name: "EMS",
              disabled: false,
            },
          ]
        : []),
    ],

    [filteredCN07Orders, filteredEnclosures, t, isChequePrintButtonDisabled]
  );

  const commonOptionsForAll = useMemo(
    () => [
      ...(filteredCN07Orders?.length
        ? [
            {
              id: EPrintOptions.CN07,
              name: t("CN07"),
              height: 95,
              width: 144,
              disabled: false,
            },
          ]
        : []),
      ...(filteredPaidOrders?.length && userInfo?.role !== Roles.SuperAdmin
        ? [
            {
              id: EPrintOptions.CHEQUE,
              name: t("receipt"),
              height: 300,
              disabled: isChequePrintButtonDisabled,
            },
          ]
        : []),
      ...(filteredEnclosures?.length
        ? [
            {
              id: EPrintOptions.CN22,
              name: "CN22",
              disabled: false,
            },
          ]
        : []),
      ...(filteredEnclosures?.length
        ? [
            {
              id: EPrintOptions.CN23_CP72,
              name: "CN23_CP72",
              disabled: false,
            },
          ]
        : []),
      ...(filteredEMSItems?.length
        ? [
            {
              id: EPrintOptions.EMS,
              name: "EMS",
              disabled: false,
            },
          ]
        : []),
    ],

    [filteredCN07Orders, filteredEnclosures, t, isChequePrintButtonDisabled]
  );

  useEffect(() => {
    setConfig({
      showAdditionalNotes: true,
      showAdditionalNotes2: true,
      showReturnAddress: true,
      showSenderLogo: true,
      showReceiverAddress: true,
    });
  }, [open]);

  useEffect(() => {
    if (
      open &&
      filteredPaidOrders?.length &&
      userInfo?.role !== Roles.SuperAdmin
    ) {
      fetchReceiptInfo();
    }
  }, [filteredPaidOrders, open]);

  useEffect(() => {
    if (userInfo?.role === "SuperAdmin") {
      return;
    }

    if (userInfo?.role) {
      dispatch(fetchOrganizationLogo());
    }
  }, [userInfo, fetchOrganizationLogo]);

  return {
    filteredCN07Orders,
    availableOptions,
    handleChangeConfig,
    config,
    coreReceiptData,
    commonOptions,
    commonOptionsForAll,
  };
};

export default useOrderPrintDialogActions;
