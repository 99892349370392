import axios, { AxiosRequestConfig } from "axios";

import { LStorage } from "store/config/constants";

const requestHeaders = {
  Accept: "*/*",
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

const api = axios.create({
  headers: requestHeaders,
});

interface IConfig extends AxiosRequestConfig {
  hasFileUpload?: boolean;
}

api.interceptors.request.use((config: IConfig) => {
  const token = localStorage.getItem(LStorage.accessToken);

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(config.hasFileUpload && [
          { "Content-Type": "multipart/form-data" },
        ]),
        Authorization: `${token}`,
        language: "eng",
      },
    };
  }

  return config;
}, Promise.reject);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(LStorage.accessToken);
      localStorage.removeItem(LStorage.expDate);
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default api;
