import { useState, useMemo, useRef, useCallback } from "react";
import {
  Paper,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Chip,
} from "@material-ui/core";
import Table from "shared/ui/Table/index";
import { EServiceTypes, translatedColumns } from "./data/constants";

import useTable from "helpers/useTable";

import { useSelector, useDispatch } from "react-redux";
import CreateUpdateDialog from "shared/ui/Table/components/createUpdateDialog";
import useTableCrud from "helpers/useTableCrud";
import ManualOrderCreate from "./components/ManualOrderCreateEdit";
import OrderOptionsDialog from "./components/OrderOptionsDialog";
import Filters from "./components/filters";

import OrderPrintDialog from "./components/OrderPrintDialog";

import {
  fetchOrders,
  selectOrders,
  approveOrders,
  removeOrder,
  exportParcels,
  getOrderHistory,
  exportCustomerParcels,
  exportCustomerParcelsByIds,
  getParcelsPDFDataByIDs,
  getParcelsPDFDataByFilters,
  selectParcelsDataForPDF,
  getOrderDetailByIdV2,
} from "src/store/slicers/order";
import { ETypeList } from "./data/constants";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ConfirmDialog from "shared/ui/DialogConfirm";
import OrderDetails from "./components/OrderDetails";
import EyeIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "src/assets/icons/copy.svg";
import toast from "react-hot-toast";
import { selectUserData } from "src/store/slicers/users";
import { Roles } from "src/router/routes";
import { handleDownload } from "src/helpers";
import OrderLocation from "./components/OrderLocation";
import EditWeightDialog from "./components/EditWeightDialog";
import useQueryParams from "src/helpers/useQueryParams";
import { useNavigate } from "react-router";
import MultipleOrderOptionsDialog from "./components/MultipleOrderPrintDialog";
import { ordersGridStyles } from "./styles";
import ListAlt from "@material-ui/icons/ListAlt";
import { useTranslation } from "react-i18next";
import ReestrPrintView from "src/shared/prints/reestrPrintView";
import { useReactToPrint } from "react-to-print";

const pageStyle = `
  @media print {
    body {
      margin-top:10mm;
      border: 1px solid white;
      height: 99%;
      page-break-after: avoid;
      page-break-before: avoid;
    }
    html {
      border: 1px solid white;
      height: 99%;
      page-break-after: avoid;
      page-break-before: avoid;
   }
  }
  @page {
    size: landscape;
  }`;

const Orders = () => {
  const userData = useSelector(selectUserData);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const ordersList = useSelector(selectOrders);
  const [orderCreateOptionsDialogOpen, setOrderCreateOptionsDialogOpen] =
    useState(false);
  const [
    multipleOrderCreateOptionsDialogOpen,
    setMultipleOrderCreateOptionsDialogOpen,
  ] = useState(false);
  const navigate = useNavigate();
  const classes = ordersGridStyles();
  const { t } = useTranslation();
  const columns = translatedColumns(t);

  const [getQueries, setQueries] = useQueryParams();
  const [activeOrder, setActiveOrder] = useState(null);
  const [printOrders, setPrintOrders] = useState([]);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const parcelsDataForPDF = useSelector(selectParcelsDataForPDF);

  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const { loading, pagination, updateTable, fetchData, setLoading } =
    useTable(fetchOrders);
  const [confirmDialogData, setConfirmDialogData] = useState({
    open: false,
    data: null,
  });

  const orderRef = useRef({});
  const reestrPrint = useRef(null);
  const selectedOrders = orderRef.current;

  const { title, saveAction, closeAction, dialogOpen } = useTableCrud(
    setLoading,
    fetchData,
    t("add_parcel")
  );

  const setSelectedOrders = ({ id, newObj }) => {
    if (!id) {
      orderRef.current = {};
      return;
    }

    if (id === "all") {
      orderRef.current = newObj;
      setCounter(counter + 1);
      return;
    }

    orderRef.current[id] = newObj;
    setCounter(counter + 1);
  };

  const fetchFilteredData = (paramsData) => {
    setSelectedOrders({});
    fetchData(paramsData);
  };

  const canMultipleAction = useCallback(
    (action) => {
      if (!selectedOrders) {
        return;
      }

      const res = Object.values(selectedOrders)
        ?.filter((i) => i.checked)
        ?.every((i) => (action === "approve" ? i.canConfirm : i.canRemove));

      return res;
    },
    [selectedOrders]
  );

  const handleExportParcels = async (formData) => {
    const { startDate, endDate, organization, countryId, ...rest } = formData;
    if (!startDate || !endDate) {
      return;
    }
    setLoading(true);

    const { meta, payload } = await dispatch(
      exportParcels({
        startDate,
        endDate,
        organizationId: organization?.id,
        ...rest,
        countryId: countryId?.id ?? "",
      })
    );

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    const blob = new Blob([payload], {
      type: "application/vnd.ms-excel",
    });

    const href = URL.createObjectURL(blob);
    handleDownload(href, "orders");
    setLoading(false);
  };

  const handleExportCustomersParcels = async (formData) => {
    const { startDate, endDate, countryId, ...rest } = formData;
    if (!startDate || !endDate) {
      return;
    }

    setLoading(true);

    const { meta, payload } = await dispatch(
      exportCustomerParcels({
        startDate,
        endDate,
        ...rest,
        countryId: countryId?.id ?? "",
      })
    );

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    const blob = new Blob([payload], {
      type: "application/vnd.ms-excel",
    });

    const href = URL.createObjectURL(blob);
    handleDownload(href, "orders", ".xlsx");
    setLoading(false);
  };

  const handleMultiplePrint = async () => {
    const result = [];
    let requestList = [];
    const filteredOrders = Object.values(orderRef.current)
      ?.filter((i) => i.checked && i.trackingNumber)
      ?.map((i) => i.id)
      .sort((a, b) => b - a);

    filteredOrders?.forEach((element) => {
      requestList.push(dispatch(getOrderDetailByIdV2(element)));
    });
    const resultV2 = await Promise.all(requestList);

    resultV2?.forEach((item) => {
      result.push(item?.payload);
    });
    setPrintOrders(result);

    setMultipleOrderCreateOptionsDialogOpen(true);
  };

  const handleMultipleApprove = async () => {
    const filteredOrders = Object.values(selectedOrders)
      ?.filter((i) => i.checked)
      ?.map((i) => i.id);

    await handleApproveOrder(filteredOrders);
    setSelectedOrders({});
  };

  const handleMultipleRemove = async () => {
    const filteredOrders = Object.values(selectedOrders)
      ?.filter((i) => i.checked)
      ?.map((i) => i.id);

    await handleRemoveOrder(filteredOrders);
    setSelectedOrders({});
  };

  const selectedOrdersList = useMemo(() => {
    let newList = [];

    for (let key in selectedOrders) {
      if (selectedOrders[key]?.checked) {
        newList.push(Number(key));
      }
    }
    return newList;
  }, [Object.values(selectedOrders)]);

  const handleReestrPreview = useReactToPrint({
    content: () => reestrPrint.current,
    pageStyle,
  });

  const handleGetReestrPrintDataByIds = async () => {
    const formData = { parcelIDS: selectedOrdersList };
    const { meta, payload } = await dispatch(getParcelsPDFDataByIDs(formData));
    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    if (payload?.data?.length > 1) {
      setTimeout(() => {
        handleReestrPreview?.();
      }, 1000);
    } else {
      toast.error(t("no_data_to_print"));
    }
  };

  const handleGetReestrPrintData = async (formData) => {
    const { startDate, endDate, countryId, ...rest } = formData;
    if (!startDate || !endDate) {
      return;
    }
    const { meta, payload } = await dispatch(
      getParcelsPDFDataByFilters({
        startDate,
        endDate,
        ...rest,
        countryId: countryId?.id ?? "",
      })
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }
    if (payload?.data?.length > 1) {
      setTimeout(() => {
        handleReestrPreview?.();
      }, 1000);
    } else {
      toast.error(t("no_data_to_print"));
    }
  };

  const handleExportCustomerParcelsByIDs = async () => {
    setLoading(true);
    const formData = { parcelIDS: selectedOrdersList };
    const { meta, payload } = await dispatch(
      exportCustomerParcelsByIds(formData)
    );

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    const blob = new Blob([payload], {
      type: "application/vnd.ms-excel",
    });

    const href = URL.createObjectURL(blob);
    handleDownload(href, "orders", ".xlsx");
    setLoading(false);
  };

  const actions = useCallback(
    () => (
      <Box
        display="flex"
        alignItems={"center"}
        pt={2}
        pb={2}
        mb={1}
        mt={1}
        component={Paper}
      >
        <Box display="flex" ml={2}>
          <Button
            startIcon={<CheckCircleIcon htmlColor="green" />}
            variant="outlined"
            color="primary"
            onClick={handleMultipleApprove}
            disabled={!canMultipleAction("approve")}
          >
            {t("approve")}
          </Button>
        </Box>

        <Box display="flex" ml={2}>
          <Button
            startIcon={<PrintIcon color="primary" />}
            variant="outlined"
            onClick={handleMultiplePrint}
            color="primary"
          >
            {t("print")}
          </Button>
        </Box>
        <Box display="flex" ml={2}>
          <Button
            startIcon={<NotInterestedIcon htmlColor="red" />}
            variant="outlined"
            color="primary"
            disabled={!canMultipleAction("remove")}
            onClick={handleMultipleRemove}
          >
            {t("remove")}
          </Button>
        </Box>
        {userData?.role !== Roles.SuperAdmin ? (
          <Box display="flex" ml={2}>
            <Button
              startIcon={<ListAlt htmlColor="green" />}
              variant="outlined"
              color="primary"
              disabled={!selectedOrdersList?.length}
              onClick={handleExportCustomerParcelsByIDs}
            >
              {t("reestr")}
            </Button>
          </Box>
        ) : null}

        {userData?.role !== Roles.SuperAdmin ? (
          <Box display="flex" ml={2}>
            <Button
              startIcon={<PrintIcon color="primary" />}
              variant="outlined"
              onClick={handleGetReestrPrintDataByIds}
              color="primary"
            >
              {`${t("print")} ${t("reestr")}`}
            </Button>
          </Box>
        ) : null}
      </Box>
    ),
    [t, ordersList, selectedOrdersList]
  );

  const handleOpenView = async (data) => {
    const { payload } = await dispatch(getOrderDetailByIdV2(data?.id));

    setActiveOrder({ ...payload, history: [] });

    setViewDialogOpen(true);
  };

  const handleOpenEdit = async (data) => {
    navigate(`edit/${data.id}`);
  };

  const getOrderHistoryData = async (data) => {
    const isTrackable =
      data.service?.id === EServiceTypes.M_Bag ||
      data.service?.id === EServiceTypes.Parcel ||
      data.service?.id === EServiceTypes.Sprint;

    if (data.category?.id === ETypeList.SIMPLE && !isTrackable) {
      toast.error(t("simple_order_cant_be_tracked"));
      return;
    }

    if (data?.trackingNumber) {
      const { meta, payload } = await dispatch(
        getOrderHistory(data?.trackingNumber)
      );

      if (meta.requestStatus !== "fulfilled") {
        return;
      }
      setActiveOrder({ ...data, history: payload });

      setLocationModalOpen(true);
      return;
    }
  };

  const dropdownActions = useMemo(() => {
    return [
      {
        icon: <EyeIcon color="primary" />,
        text: t("view"),
        action: handleOpenView,
      },
      ...(userData?.role !== Roles.SuperAdmin
        ? [
            {
              icon: <EditIcon color="primary" />,
              text: t("change"),
              action: handleOpenEdit,
              isDisabled: (row) => row.trackingNumber,
            },
          ]
        : []),
      {
        icon: <PrintIcon color="primary" />,
        text: t("print_labels"),
        isDisabled: (row) => !row.trackingNumber,
        action: async (data) => {
          const { payload } = await dispatch(getOrderDetailByIdV2(data?.id));

          setPrintOrders([payload]);
          setPrintDialogOpen(true);
        },
      },
      ...(userData?.role !== Roles.SuperAdmin
        ? [
            {
              icon: <CheckCircleIcon htmlColor="green" />,
              text: t("approve"),
              isDisabled: (row) => !row.canConfirm,
              action: (data) => {
                setConfirmDialogData({
                  data: {
                    ...data,
                    action: "approve",
                  },
                  open: true,
                });
              },
            },
            {
              icon: <NotInterestedIcon color="error" />,
              text: t("remove"),
              isDisabled: (row) => !row.canRemove,
              action: (data) => {
                setConfirmDialogData({
                  data: {
                    ...data,
                    action: "remove",
                  },
                  open: true,
                });
              },
            },
          ]
        : []),
    ];
  }, [t, userData?.role]);

  const handleApproveOrder = async (approveData) => {
    setLoading(true);
    const { meta, payload } = await dispatch(approveOrders(approveData));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    const failedOrders = payload.data.filter(
      (item) => item.message !== "Success"
    );
    if (failedOrders?.length) {
      toast.error(t("there_are_not_approved_parcels"));
    } else {
      toast.success(t("parcel_is_approved"));
    }

    await fetchData({ ...getQueries() });
    setLoading(false);
  };

  const handleRemoveOrder = async (removeIdsList) => {
    setLoading(true);

    const { meta, payload } = await dispatch(
      removeOrder({ deleteParcelIds: removeIdsList })
    );

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    if (payload?.data?.[0]?.message) {
      toast.error(payload?.data?.[0]?.message);
      setLoading(false);
      return;
    }

    toast.success(t("parcel_is_removed"));

    await fetchData();
    setLoading(false);
  };

  const handleConfirmAction = () => {
    setConfirmDialogData({
      ...confirmDialogData,
      open: false,
    });

    if (confirmDialogData.data.action === "remove") {
      handleRemoveOrder([confirmDialogData.data.id]);
      return;
    }
    handleApproveOrder([confirmDialogData.data.id]);
  };

  const onCheckAll = (checked) => {
    const selected = {};

    ordersList?.data.forEach(
      ({ canRemove, trackingNumber, id, canConfirm }, index) => {
        selected[id] = {
          checked: checked,
          rowIndex: index,
          canRemove,
          canConfirm,
          trackingNumber,
          id,
        };
      }
    );

    setSelectedOrders({ newObj: selected, id: "all" });
  };

  const onRowCheck = (checked, row, rowIndex) => {
    const newObj = {
      checked,
      rowIndex,
      canRemove: row.canRemove,
      canConfirm: row.canConfirm,
      trackingNumber: row.trackingNumber,
      id: row.id,
    };

    setSelectedOrders({ newObj, id: row.id });
  };

  const handleCopyTrackingNumber = (trackingId) => {
    navigator.clipboard.writeText(trackingId);
    const message = t("copied_to_clipboard");
    toast.success(message);
  };

  const customColumns = [
    {
      field: "trackingNumber",
      title: t("tracking_number"),
      layout: (row) => {
        return row.trackingNumber ? (
          <Box display="flex" alignItems="center">
            <Chip
              color="primary"
              label={row.trackingNumber}
              onClick={() => getOrderHistoryData(row)}
            />
            <Box
              onClick={() => handleCopyTrackingNumber(row.trackingNumber)}
              className={classes.copyIcon}
            >
              <img src={CopyIcon} />
            </Box>
          </Box>
        ) : null;
      },
    },
    ...(userData?.role !== Roles.ClAdmin
      ? columns
      : columns.filter((i) => i.field !== "organization")),
  ];

  return (
    <Box component={Paper} p={4}>
      {userData?.role !== Roles.SuperAdmin && (
        <Box mb={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOrderCreateOptionsDialogOpen(true)}
          >
            {t("add_parcel")}
          </Button>
        </Box>
      )}
      <Table
        title="parcel"
        isLoading={loading}
        dropdownActions={dropdownActions}
        multipleActions={actions}
        selected={selectedOrders}
        columns={customColumns}
        multipleSelect
        onChangeRowsPerPage={(event: any) =>
          updateTable({ rowsPerPage: event.target.value })
        }
        onRowCheck={onRowCheck}
        onCheckAll={onCheckAll}
        rows={ordersList?.data || []}
        paginationConfig={pagination}
        filters={
          <Filters
            handleExportParcels={handleExportParcels}
            fetchData={fetchFilteredData}
            handleExportCustomersParcels={handleExportCustomersParcels}
            handleGetReestrPrintData={handleGetReestrPrintData}
          />
        }
      />
      <Box display="none">
        <Box ref={reestrPrint}>
          <ReestrPrintView ordersList={parcelsDataForPDF} />
        </Box>
      </Box>
      <OrderOptionsDialog
        open={orderCreateOptionsDialogOpen}
        title={t("creation_example")}
        onClose={() => setOrderCreateOptionsDialogOpen(false)}
      />
      <CreateUpdateDialog
        title={title}
        open={dialogOpen.get}
        onClose={closeAction}
      >
        <ManualOrderCreate
          isLoading={loading}
          onSave={saveAction}
          onClose={closeAction}
        />
      </CreateUpdateDialog>
      <></>
      <OrderDetails
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        data={activeOrder}
      />
      <EditWeightDialog
        activeOrder={activeOrder}
        open={editDialogOpen}
        onSave={saveAction}
        onClose={() => setEditDialogOpen(false)}
      />
      <ConfirmDialog
        isOpen={confirmDialogData.open}
        onAgree={handleConfirmAction}
        isLoading={isLoading}
        onClose={() =>
          setConfirmDialogData({
            open: false,
            data: null,
          })
        }
      >
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        <Typography variant="h5">
          {" "}
          {t("are_you_sure")}{" "}
          {confirmDialogData?.data?.action === "approve"
            ? t("approve")
            : t("remove")}
          {t("the_parcel")}
        </Typography>
      </ConfirmDialog>

      <OrderPrintDialog
        open={printDialogOpen}
        printOrders={printOrders}
        onClose={() => setPrintDialogOpen(false)}
      />
      <MultipleOrderOptionsDialog
        open={multipleOrderCreateOptionsDialogOpen}
        printOrders={printOrders}
        onClose={() => setMultipleOrderCreateOptionsDialogOpen(false)}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={locationModalOpen}
        onClose={() => setLocationModalOpen(false)}
      >
        <Box m={4}>
          <Typography variant="h4">
            {activeOrder?.trackingNumber} {t("parcels_history")}
          </Typography>
        </Box>
        <DialogContent>
          <OrderLocation data={activeOrder?.history || []} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Orders;
