import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "src/assets/locales/en/translation.json";
import arm from "src/assets/locales/arm/translation.json";
import rus from "src/assets/locales/rus/translation.json";

// const fallbackLng = ["arm"];
const availableLanguages = ["en", "arm", "rus"];
const fallbackLng = localStorage.getItem("i18nextLng")
  ? [localStorage.getItem("i18nextLng")]
  : ["arm"];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      en: { translation: en },
      arm: { translation: arm },
      rus: { translation: rus },
    },
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    // debug: process.env.NODE_ENV === 'production',
    debug: false,
    whitelist: availableLanguages,
    // detection: options,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
