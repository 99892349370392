import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const useQueryParams = () => {
    const setQueries = useSearchParams()[1];
    const queries = useCallback(() => {
        const queryString = decodeURIComponent(window.location.search);
        if(!queryString) {
            return {};
        }
        const queriesObject = {}

        const queriesArray = queryString.slice(1).split('&');
        queriesArray.forEach(item => {
            const [key,value] = item.split('=');
            queriesObject[key]
                ? typeof queriesObject[key] === 'string'
                    ? queriesObject[key] = [queriesObject[key], value]
                    : queriesObject[key] = [...queriesObject[key] ,value]
                : queriesObject[key] = value;
        })
        return queriesObject;
    }, []);
    return [queries, setQueries];
}

export default useQueryParams;