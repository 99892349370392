import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout/DashboardLayout";
import MainLayout from "src/layouts/MainLayout/MainLayout";
import NotFoundView from "src/views/Errors/NotFoundView";
import AuthDashboard from "src/views/Auth/AuthDashboard";
import Users from "src/views/Users";
import Orders from "src/views/Orders";
import History from "src/views/History";
import ManualOrderCreateEdit from "src/views/Orders/components/ManualOrderCreateEdit";
import ExcelImportOrders from "src/views/Orders/components/ExcelImportOrders";
import Organizations from "src/views/Organizations";
import { useSelector } from "react-redux";
import { selectUserData } from "src/store/slicers/users";
import Addresses from "src/views/Addresses";
import { LStorage } from "src/store/config/constants";
import PrintExcelData from "src/views/PrintExcelData"; 

export enum Roles {
  SuperAdmin = "SuperAdmin",
  ClAdmin = "CLAdmin",
  Operator = "Operator",
}

const authRoutes = [
  {
    path: "/login",
    element: <AuthDashboard />,
  },
  {
    path: "/admin",
    element: <Navigate to="/login" />,
  },
];

const publicRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "404", element: <NotFoundView /> },
      {
        path: "*",
        element: (
          <Navigate
            to={localStorage?.getItem(LStorage.accessToken) ? `/404` : "/login"}
          />
        ),
      },
    ],
  },
];

export const getRoutes = () => {
  const userData = useSelector(selectUserData);

  return {
    adminRoutes: [
      {
        path: "/admin",
        element: <DashboardLayout />,
        children: [
          ...(userData?.role !== Roles.Operator
            ? [
                {
                  path: "Users",
                  element: <Users />,
                },
              ]
            : []),
          ...(userData?.role === Roles.ClAdmin
            ? [
                {
                  path: "Addresses",
                  element: <Addresses />,
                },
              ]
            : []),
          {
            path: "Orders",
            children: [
              {
                path: "/",
                element: <Orders />,
              },
              ...(userData?.role !== Roles.SuperAdmin
                ? [
                    {
                      path: "manual-create",
                      element: <ManualOrderCreateEdit mode="create" />,
                    },
                    {
                      path: "/edit/:orderId",
                      element: <ManualOrderCreateEdit mode="edit" />,
                    },
                    {
                      path: "excel-import",
                      element: <ExcelImportOrders />,
                    },
                  ]
                : []),
            ],
          },
          ...(userData?.role !== Roles.Operator
            ? [
                {
                  path: "History",
                  element: <History />,
                },
              ]
            : []),
          ...(userData?.role === Roles.SuperAdmin
            ? [
                {
                  path: "Organizations",
                  element: <Organizations />,
                },
              ]
            : []),
          ...(userData?.role === Roles.SuperAdmin
            ? [
                {
                  path: "print-excel-data",
                  element: <PrintExcelData />,
                },
              ]
            : []),
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "/login",
        element: <Navigate to="/admin" />,
      },
    ],
    authRoutes,
    publicRoutes,
  };
};
