import { useRef, useState, useMemo, Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Dialog,
  Typography,
  DialogContent,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import MUISelect from "src/shared/ui/Select";
import StandartLabelPrint from "../../../shared/prints/standartLabel";
import CN07 from "../../../shared/prints/cn07";
import TaxPercue from "../../../shared/prints/taxPercue";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import CodPortrait from "src/shared/prints/codPortrait";
import CodLandscape from "src/shared/prints/codLandscape";
import Cheque from "src/shared/prints/OrderCheque";
import {
  translatedConfigOptions,
  EPrintOptions,
  translatedCODSelectOptions,
  EServiceTypes,
} from "../data/constants";
import useOrderPrintDialogActions from "../hooks/useOrderPrintDialogActions";
import Cn22 from "src/shared/prints/cn22";
import CP72AndCN23 from "src/shared/prints/cp72&cn23";
import EMS from "src/shared/prints/EMS";
import { useTranslation } from "react-i18next";
import { IActiveOrder } from "src/store/models/interfaces/order";

const OrderPrintDialog = ({ open, onClose, printOrders }): JSX.Element => {
  const standartPrintRef = useRef();
  const enclosuresRef = useRef();
  const { t } = useTranslation();
  const ConfigOptions = translatedConfigOptions(t);
  const codSelectOptions = translatedCODSelectOptions(t);

  const {
    filteredCN07Orders,
    availableOptions,
    handleChangeConfig,
    config,
    coreReceiptData,
    commonOptions,
  } = useOrderPrintDialogActions({ printOrders, open });

  const [activeOption, setActiveOption] = useState();
  const cn07Ref = useRef();

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      cod: "",
      codConfigData: null,
    },
  });

  const watchCodConfigData = watch("codConfigData");

  const handleStandartprintPreview = useReactToPrint({
    content: () => standartPrintRef.current,
  });

  const handleCN07printPreview = useReactToPrint({
    content: () => cn07Ref.current,
  });

  const handleEnclosuresPrintPreview = useReactToPrint({
    content: () => enclosuresRef.current,
  });

  const handlePrintStandart = (item) => {
    setActiveOption(item);
    setTimeout(() => {
      if (item?.id && item.id == EPrintOptions.CN07) {
        handleCN07printPreview?.();
      } else if (
        item?.id &&
        (item.id == EPrintOptions.CN22 ||
          item.id == EPrintOptions.CN23_CP72 ||
          item.id == EPrintOptions.EMS)
      ) {
        handleEnclosuresPrintPreview?.();
      } else {
        handleStandartprintPreview?.();
      }
    }, 0);
  };

  const handlePrintCod = () => {
    setActiveOption(watchCodConfigData);
    setTimeout(() => {
      handleStandartprintPreview?.();
    }, 0);
  };

  const handlePrintCODBarcodes = () => {
    setActiveOption({ id: EPrintOptions.BARCODE });
    setTimeout(() => {
      handleStandartprintPreview?.();
    }, 0);
  };

  const handleSelectCod = (value: string) => {
    const selectedValue = codSelectOptions.find((item) => item.id == value);
    setValue("codConfigData", selectedValue);
  };

  const onHandleDialogClose = () => {
    onClose();
    reset({
      cod: "",
      codConfigData: null,
    });
  };

  const filteredCODOrders = useMemo(
    () => !!printOrders.filter((order) => order.cod).length,
    [printOrders]
  );

  const filteredServiceTypes = useMemo(
    () =>
      !!printOrders.filter(
        (order) =>
          order?.service?.id >= EServiceTypes.Parcel &&
          order?.service?.id <= EServiceTypes.YES
      )?.length,
    [printOrders]
  );

  const defaultPrintDialogButtons = useMemo(() => {
    return availableOptions?.map((item, index) => {
      return (
        <Box key={index} p={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePrintStandart(item)}
          >
            {item.name}
          </Button>
        </Box>
      );
    });
  }, [availableOptions]);

  const commonPrintDialogButtons = useMemo(() => {
    return commonOptions?.map((item, index) => {
      return (
        <Box key={index} p={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePrintStandart(item)}
            disabled={item.disabled}
          >
            {item.name}
          </Button>
        </Box>
      );
    });
  }, [commonOptions]);

  const codPrintDialogButtons = useMemo(
    () => (
      <Box display="flex" alignItems="center" textAlign="center">
        <Box minWidth={200}>
          <MUISelect
            control={control}
            name="cod"
            label={t("print_label_common")}
            options={codSelectOptions}
            textProp="name"
            onChange={handleSelectCod}
          />
        </Box>
        <Box p={1} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            disabled={!watchCodConfigData}
            onClick={handlePrintCod}
          >
            {watchCodConfigData
              ? watchCodConfigData?.name
              : t("print_label_common")}
          </Button>
        </Box>
        <Box p={1} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrintCODBarcodes}
          >
            {t("print_tracking_number")}
          </Button>
        </Box>
      </Box>
    ),
    [watchCodConfigData?.id, t]
  );

  const enclosuresPrintsPreview = useMemo(
    () => printOrders?.filter((item) => JSON.parse(item.enclosures)?.length),
    [printOrders]
  );

  const chequeList = useMemo(() => {
    return printOrders.filter((item) => item.status.id === 6);
  }, [printOrders]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onHandleDialogClose}>
      <Box textAlign="center"></Box>
      <Box m={4} style={{ wordBreak: "break-word" }}>
        <Typography variant="h4">{t("print_parcel_parcels")}</Typography>
      </Box>
      <DialogContent>
        <Box p={2} display="flex" flexWrap={"wrap"}>
          <Fragment>
            {filteredCODOrders || filteredServiceTypes ? (
              <Fragment>{codPrintDialogButtons}</Fragment>
            ) : (
              <Fragment>{defaultPrintDialogButtons}</Fragment>
            )}
            <Fragment>{commonPrintDialogButtons}</Fragment>
          </Fragment>
        </Box>
        <Box ml={3}>
          {ConfigOptions.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={config[item.key]}
                    onChange={() => handleChangeConfig(item.key)}
                    color="primary"
                  />
                }
                label={item.name}
              />
            );
          })}
        </Box>

        <Box display="none">
          <Box ref={standartPrintRef}>
            {chequeList?.map((activeOrder: IActiveOrder) => {
              if (activeOption?.id === EPrintOptions.CHEQUE) {
                return (
                  <Cheque
                    key={activeOrder?.id}
                    activeOrder={activeOrder}
                    optionData={activeOption}
                    coreData={coreReceiptData?.find(
                      (i) => i.id === activeOrder.id
                    )}
                  />
                );
              }
            })}
            {printOrders?.map((activeOrder, index) => {
              if (!filteredCODOrders && !filteredServiceTypes) {
                if (
                  activeOption?.id !== EPrintOptions.TAX_PERCUE &&
                  activeOption?.id !== EPrintOptions.CHEQUE &&
                  activeOption?.id !==
                    EPrintOptions.OVERHEAD_PAYMENT_LANDSCAPE &&
                  activeOption?.id !==
                    EPrintOptions.OVERHEAD_PAYMENT_PORTRAIT &&
                  activeOption?.id !== EPrintOptions.BARCODE
                ) {
                  return (
                    <div key={index}>
                      <StandartLabelPrint
                        activeOrder={activeOrder}
                        config={config}
                        optionData={activeOption}
                      />
                    </div>
                  );
                } else if (activeOption?.id === EPrintOptions.TAX_PERCUE) {
                  return (
                    <TaxPercue
                      key={index}
                      activeOrder={activeOrder}
                      optionData={activeOption}
                    />
                  );
                }
              } else {
                if (
                  activeOption?.id == EPrintOptions.OVERHEAD_PAYMENT_LANDSCAPE
                ) {
                  return (
                    <Box key={index}>
                      <CodLandscape
                        width={watchCodConfigData?.width}
                        activeOrder={activeOrder}
                      />
                    </Box>
                  );
                } else if (
                  activeOption?.id == EPrintOptions.OVERHEAD_PAYMENT_PORTRAIT
                ) {
                  return (
                    <Box key={index}>
                      <CodPortrait
                        width={watchCodConfigData?.width}
                        activeOrder={activeOrder}
                      />
                    </Box>
                  );
                } else if (activeOption?.id === EPrintOptions.BARCODE) {
                  return (
                    <BarcodeToPrint
                      barcode={activeOrder.trackingNumber}
                      key={index}
                    />
                  );
                }
              }
            })}
          </Box>
          {filteredCN07Orders?.length ? (
            <Box ref={cn07Ref}>
              {filteredCN07Orders?.map((activeOrder, index) => {
                return (
                  <CN07
                    key={index}
                    activeOrder={activeOrder}
                    optionData={activeOption}
                    showAdditionalNotes={config.showAdditionalNotes}
                    showAdditionalNotes2={config.showAdditionalNotes2}
                  />
                );
              })}
            </Box>
          ) : null}
          {enclosuresPrintsPreview?.length ? (
            <Box ref={enclosuresRef}>
              {enclosuresPrintsPreview?.map((activeOrder, index) => {
                if (activeOption?.id === EPrintOptions.CN22) {
                  return <Cn22 key={index} printData={activeOrder} />;
                } else if (activeOption?.id === EPrintOptions.EMS) {
                  return <EMS key={index} printData={activeOrder} />;
                } else {
                  return <CP72AndCN23 key={index} printData={activeOrder} />;
                }
              })}
            </Box>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderPrintDialog;
