import { useMemo, useState } from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";
import Table from "shared/ui/Table/index";
import { translatedColumns } from "./data/constants";
import useTable from "helpers/useTable";
import { useSelector, useDispatch } from "react-redux";
import CreateUpdateDialog from "shared/ui/Table/components/createUpdateDialog";
import useTableCrud from "helpers/useTableCrud";
import CreateOrganizationForm from "./components/CreateOrganizationForm";
import {
  fetchOrganizations,
  selectOrganizations,
  changeOrganizationStatus,
} from "store/slicers/organizations";
import EditIcon from "@material-ui/icons/Edit";
import AvatarIcon from "@material-ui/icons/AccountCircle";
import Filters from "./components/filters";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ConfirmDialog from "shared/ui/DialogConfirm";
import toast from "react-hot-toast";
import EyeIcon from "@material-ui/icons/Visibility";
import OrganizationDetails from "./components/OrganizationDetails";
import EditLogoDialog from "./components/EditLogoDialog";
import EditOrganizationForm from "./components/EditOrganizationAdmin";
import { useTranslation } from "react-i18next";

const Organizations = () => {
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [editOrgAdminDialogOpen, setEditOrgAdminDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const organizationsList = useSelector(selectOrganizations);
  const [editLogoDialogOpen, setEditLogoDialogOpen] = useState(false);
  const { t } = useTranslation();
  const columns = translatedColumns(t);

  const [confirmDialogData, setConfirmDialogData] = useState({
    open: false,
    data: null,
  });

  const handleConfirmAction = async () => {
    const { meta } = await dispatch(
      changeOrganizationStatus(confirmDialogData.data.id)
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("company_status_changed"));
    setConfirmDialogData({
      open: false,
      data: null,
    });
    fetchData();
  };

  const { loading, pagination, fetchData, setLoading, updateTable } =
    useTable(fetchOrganizations);

  const { title, saveAction, closeAction, dialogOpen } = useTableCrud(
    setLoading,
    fetchData,
    "add_company"
  );

  const dropdownActions = useMemo(() => {
    return [
      {
        icon: <EyeIcon color="primary" />,
        text: t("view"),
        action: (data) => {
          setActiveOrganization(data);
          setViewDialogOpen(true);
        },
      },
      {
        icon: <EditIcon color="primary" />,
        text: t("change_admin"),
        action: (data) => {
          setActiveOrganization(data);
          setEditOrgAdminDialogOpen(true);
        },
      },
      {
        icon: <AvatarIcon color="primary" />,
        text: t("change_logo"),
        action: (data) => {
          setActiveOrganization(data);
          setEditLogoDialogOpen(true);
        },
      },
      {
        icon: (data) =>
          !data.active ? (
            <CheckCircleIcon htmlColor="green" />
          ) : (
            <NotInterestedIcon color="error" />
          ),
        text: (data) => (!data.active ? t("activate") : t("deactivate")),
        action: (data) => {
          setConfirmDialogData({ open: true, data });
        },
      },
    ];
  }, [dialogOpen, dispatch, t]);

  const activateConfirmDialogContent = useMemo(() => {
    return (
      <>
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        {confirmDialogData?.data?.active ? (
          <Typography>{t("there_are_attached_users_to_company")}:</Typography>
        ) : null}
        <Typography variant="h5">
          {`${t("are_you_sure")} ${
            confirmDialogData?.data?.active ? t("deactivate") : t("deactivate")
          } ${t("the_company")}`}
        </Typography>
      </>
    );
  }, [t, confirmDialogData?.data?.active]);

  return (
    <Box component={Paper} p={4}>
      <Box mb={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dialogOpen.set(true)}
        >
          {t("add_company")}
        </Button>
      </Box>
      <Table
        title="organizations"
        isLoading={loading}
        columns={columns}
        dropdownActions={dropdownActions}
        rows={organizationsList?.data || []}
        paginationConfig={pagination}
        onChangeRowsPerPage={(event: any) =>
          updateTable({ rowsPerPage: event.target.value })
        }
        filters={<Filters fetchData={fetchData} />}
      />
      <CreateUpdateDialog
        title={title}
        open={dialogOpen.get}
        onClose={closeAction}
        width={"sm"}
      >
        <CreateOrganizationForm
          isLoading={loading}
          onSave={saveAction}
          onClose={closeAction}
          fetchData={fetchData}
        />
      </CreateUpdateDialog>
      <OrganizationDetails
        data={activeOrganization}
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
      />
      <EditLogoDialog
        data={activeOrganization}
        open={editLogoDialogOpen}
        onClose={() => setEditLogoDialogOpen(false)}
        fetchData={fetchData}
      />
      {editOrgAdminDialogOpen && (
        <EditOrganizationForm
          data={activeOrganization}
          open={editOrgAdminDialogOpen}
          onClose={() => setEditOrgAdminDialogOpen(false)}
          fetchData={fetchData}
        />
      )}

      <ConfirmDialog
        isOpen={confirmDialogData.open}
        onAgree={handleConfirmAction}
        onClose={() =>
          setConfirmDialogData({
            open: false,
            data: null,
          })
        }
      >
        {activateConfirmDialogContent}
      </ConfirmDialog>
    </Box>
  );
};

export default Organizations;
