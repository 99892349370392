import { Control } from "react-hook-form";
import moment from "moment";
import { Grid, makeStyles } from "@material-ui/core";
import "antd/dist/antd.css";
import InputField from "../Input";
import { useTranslation } from "react-i18next";

const format = "YYYY-MM-DD";
const ranges = {
  "Last week": [moment().subtract(6, "days"), moment()],
  "Last month": [moment().subtract(30, "days"), moment()],
  "Last half-year": [moment().subtract(183, "days"), moment()],
  "Last year": [moment().subtract(365, "days"), moment()],
};
const styles = makeStyles({
  root: {
    display: "flex",
  },
});

interface IRangePickerProps {
  control: Control;
  label?: string;
}

const RangePicker = ({ control }: IRangePickerProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InputField
          size="small"
          type="datetime-local"
          label={t("date_options.start")}
          control={control}
          name="startDate"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          size="small"
          type="datetime-local"
          label={t("date_options.end")}
          control={control}
          name="endDate"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RangePicker;
