import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Roles } from "src/router/routes";
import api from "../../helpers/axios";
import { EBaseUrl, LStorage } from "../config/constants";
import thunkOptions from "../config/thunkOptions";
import { IUSerState } from "../models/interfaces";

const name = "USERS";

export const fetchUsers = createAsyncThunk(
  `${name}/fetchUsers`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/User/Grid`, formData)).data,
  thunkOptions
);

export const updateUser = createAsyncThunk(
  `${name}/updateUser`,
  async ({ formData, id }) =>
    (await api.put(`${EBaseUrl.Envelope}/User/${id}`, formData)).data,
  thunkOptions
);

export const changeUserStatus = createAsyncThunk(
  `${name}/changeUserStatus`,
  async (id) =>
    (await api.patch(`${EBaseUrl.Envelope}/User/Status/${id}`)).data,
  thunkOptions
);

export const registerOrganizationAdmin = createAsyncThunk(
  `${name}/registerOrganizationAdmin`,
  async ({ id, formData }) =>
    (await api.post(`${EBaseUrl.Envelope}/User/Register/${id}`, formData)).data,
  thunkOptions
);

export const fetchUserById = createAsyncThunk(
  `${name}/fetchUserById`,
  async (id) => (await api.get(`${EBaseUrl.Envelope}/User/${id}`)).data,
  thunkOptions
);

export const removeUser = createAsyncThunk(
  `${name}/removeUser`,
  async (id) => (await api.delete(`${EBaseUrl.Envelope}/User/${id}`)).data,
  thunkOptions
);

export const createUser = createAsyncThunk(
  `${name}/createuser`,
  async ({ id, formData }) =>
    (await api.post(`${EBaseUrl.Envelope}/User/Register`, formData)).data,
  thunkOptions
);

export const getUserInfo = createAsyncThunk(
  `${name}/getUserInfo`,
  async () => (await api.get(`${EBaseUrl.Envelope}/User/GetUserInfo`)).data,
  thunkOptions
);

export const exportUsersInfo = createAsyncThunk(
  `${name}/exportUsersInfo`,
  async (queryParams) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/User/ExcelExportUsers?${queryParams}`,
        {},
        {
          responseType: "blob",
        }
      )
    ).data,
  thunkOptions
);

const initialState: IUSerState = {
  usersList: [],
};

const usersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.usersList = payload;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
    });
  },
});

export const selectUsers = (state) => state.users.usersList;
export const selectUserData = (state) => state.users.userInfo;

export const { clearUserPermission } = usersSlice.actions;
export default usersSlice.reducer;
