import { Box, Typography } from "@material-ui/core";
import { forwardRef } from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { selectOrganizationLogo } from "src/store/slicers/organizations";
import { ETypeList } from "src/views/Orders/data/constants";
import TaxPercueImg from "../../assets/images/tax-percue.jpg";
import QRCode from "react-qr-code";
import { IActiveOrder } from "src/store/models/interfaces/order";
import format from "date-fns/format";

const labels = [
  { nameArm: "Անհայտ/", name: "Unknown", id: 1 },
  { nameArm: "Մերժված /", name: "Refused", id: 5 },
  { nameArm: "Տեղափոխված/", name: "Gone away", id: 2 },
  { nameArm: "Չի պահանջվել/", name: "Unclaimed", id: 6 },
  { nameArm: "Թերի հաասցե/", name: "Insufficient address ", id: 3 },
  { nameArm: "", name: "", id: 11 },
];

interface IStandardLabelProps {
  activeOrder: IActiveOrder;
  optionData: any;
  config: any;
}

const StandartLabel = forwardRef((props: IStandardLabelProps, ref) => {
  const {
    activeOrder,
    optionData,
    config: {
      showReturnAddress,
      showAdditionalNotes,
      showAdditionalNotes2,
      showReceiverAddress,
      showSenderLogo,
    },
  } = props;
  const { provinceState, cityVillage, street, building, apartment } =
    activeOrder?.returnSender;
  const {
    provinceState: recipientProvinceState,
    cityVillage: recipientCityVillage,
    street: recipientStreet,
    building: recipientBuilding,
    apartment: recipientApartment,
    // country: recipientCountry,
  } = activeOrder?.recipient;
  const orgLogo = useSelector(selectOrganizationLogo);

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        width: `${optionData?.width || 0}mm`,
        height: `${optionData?.height || 0}mm`,
        padding: "5px 15px 10px",
      }}
    >
      <Box display="flex" justifyContent="space-between" height="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="45%"
        >
          <Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              {showSenderLogo && orgLogo ? (
                <div
                  style={{
                    width: `50mm`,
                    height: `25mm`,
                    paddingBottom: 10,
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={orgLogo}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: `50mm`,
                    height: `25mm`,
                    paddingBottom: 10,
                  }}
                />
              )}
              <div
                style={{
                  height: "auto",
                  maxWidth: 72,
                  width: "100%",
                  marginLeft: 30,
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`${activeOrder?.trackingNumber},   ${
                    activeOrder?.weight
                  }g.,   ${format(
                    new Date(activeOrder?.creationDate),
                    "dd/MM/yyyy"
                  )},   ${activeOrder?.amount}AMD`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Box>
            <Box>
              {showReturnAddress && (
                <Box>
                  <div>
                    {activeOrder.returnSender.nameSurname
                      ? activeOrder.returnSender.nameSurname
                      : activeOrder.returnSender.legalName}
                  </div>
                  <div>
                    {"Armenia" +
                      " " +
                      provinceState +
                      " " +
                      cityVillage +
                      " " +
                      street +
                      " " +
                      building +
                      " " +
                      (apartment ? apartment : "")}
                  </div>
                  <div>
                    {activeOrder.returnSender.postalCode
                      ? activeOrder.returnSender.postalCode
                      : ""}
                  </div>
                </Box>
              )}
              <Box>
                {showAdditionalNotes && <div>{activeOrder.note1}</div>}
                {showAdditionalNotes2 && <div>{activeOrder.note2}</div>}
              </Box>
            </Box>
          </Box>
          <div
            style={{
              marginTop: "30px",
              border: "1px solid black",
              width: "80%",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid black",
                display: "flex",
                flexDirection: "row",
                textTransform: "uppercase",
                justifyContent: "space-between",
                padding: "2px",
              }}
            >
              <div style={{ textTransform: "uppercase" }}>
                <Box fontSize={10}>Հետ վերադարձ</Box>
                <Box fontSize={10}>return</Box>
              </div>
              <div> cn15</div>
            </div>
            <div
              style={{
                display: "flex",
                padding: "2px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {labels.map((i) => (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "45%",
                    fontSize: "7px",
                    alignItems: "center",
                  }}
                  key={i.id}
                >
                  <Box height={15} width={15} border="1px solid" mr={1} />
                  <div>
                    <Box>{i?.nameArm}</Box>
                    <Box>{i?.name}</Box>
                  </div>
                </Box>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid black",
                width: "100%",
                justifyContent: "center",
                padding: "2px",
              }}
            >
              <div>
                <Box fontSize={8}> Ետ վերադարձման ամսաթիվը / Return Date</Box>
              </div>
            </div>
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="55%"
        >
          <Box textAlign="right">
            <img style={{ height: "3cm" }} src={TaxPercueImg} alt={""} />{" "}
          </Box>
          <Box textAlign="right">
            <Box>
              <Barcode
                value={activeOrder?.trackingNumber}
                height={60}
                width={1}
                fontSize={18}
              />
              {activeOrder?.category?.id === ETypeList.SIMPLE && (
                <Typography style={{ fontSize: 10 }}>
                  Տվյալ առաքանին հանդիսանում է Հասարակ և հետևման ենթակա չէ
                </Typography>
              )}
            </Box>
          </Box>
          <Box textAlign="right">
            {showReceiverAddress && (
              <div>
                <div>
                  {activeOrder.recipient.nameSurname
                    ? activeOrder.recipient.nameSurname
                    : activeOrder.recipient.legalName}
                </div>
                <div>
                  {activeOrder?.country?.name +
                    " " +
                    recipientProvinceState +
                    " " +
                    recipientCityVillage +
                    " " +
                    recipientStreet +
                    " " +
                    recipientBuilding +
                    " " +
                    (recipientApartment ? recipientApartment : "")}
                </div>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      <Box textAlign="center">
        <Typography style={{ fontSize: 10 }}>
          powered by Envelope, envelope.haypost.am
        </Typography>
      </Box>
    </div>
  );
});

export default StandartLabel;
