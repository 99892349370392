import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import errorHandling from "./middlewares/errorHandling";

import addressReducer from "./slicers/address";
import authReducer from "./slicers/auth";
import orderReducer from "./slicers/order";
import transactionsReducer from "./slicers/transactions";
import usersReducer from "./slicers/users";
import organizationsReducer from "./slicers/organizations";
import resourcesReducer from "./slicers/resources";
import { IState } from "./models/interfaces";

const combinedReducers = combineReducers({
  address: addressReducer,
  auth: authReducer,
  order: orderReducer,
  transactions: transactionsReducer,
  users: usersReducer,
  organizations: organizationsReducer,
  resources: resourcesReducer,
});

const initialState = {
  address: {
    states: [],
  },
  auth: {
    isAuth: false,
  },
  order: {
    orders: [],
    excelOrdersData: [],
  },
  transactions: {
    transactions: [],
  },
  users: {
    usersList: [],
  },
  organizations: {
    organizationsList: [],
  },
  resources: {
    additionalServices: [],
    categories: [],
    parcelStatuses: [],
    services: [],
  },
};

const rootReducer = (state: IState, action: Action) => {
  if (action.type === "AUTH/signOut") {
    state = initialState;
  }
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorHandling),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
