import { useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputField from "shared/ui/Input";
import CheckBoxField from "shared/ui/CheckBox";
import { PasswordToggle } from "components/PasswordToggle";
import ButtonLoader from "components/ButtonLoader";
import useStyles from "./style";
import { authAction } from "store/slicers/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { emailRegex } from "src/helpers/validators";
import { useTranslation } from "react-i18next";

function Login({ onForgotHandler }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { email: "", password: "", isRemember: false },
  });
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const togglePassword = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  const openForgotPassModal = () => {
    onForgotHandler(true);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const { meta } = await dispatch(authAction(formData));

    if (meta.requestStatus !== "fulfilled") {
      setIsLoading(false);
      return;
    }

    navigate("/admin/orders", { replace: true });
  };

  return (
    <Box className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          control={control}
          margin="normal"
          rules={{
            required: { value: true, message: t("required_field") },
            maxLength: 255,
            pattern: {
              value: emailRegex,
              message: t("wrong_email"),
            },
          }}
          name="email"
          label={t("email")}
          errors={errors}
        />
        <InputField
          control={control}
          name="password"
          margin="normal"
          label={t("password")}
          rules={{
            required: { value: true, message: t("required_field") },
            minLength: {
              value: 8,
              message: `${t("minimum")} 8 ${t("character")}`,
            },
            maxLength: {
              value: 30,
              message: `${t("maximum")} 30 ${t("character")}`,
            },
          }}
          type={isPasswordVisible ? "text" : "password"}
          errors={errors}
          InputProps={{
            endAdornment: (
              <PasswordToggle
                isPasswordVisible={isPasswordVisible}
                togglePassword={togglePassword}
              />
            ),
          }}
        />

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            control={<CheckBoxField control={control} name="isRemember" />}
            label={t("remember")}
          />
          <Typography
            className={classes.forgotPassText}
            onClick={openForgotPassModal}
          >
            {t("password_recover")}
          </Typography>
        </Box>

        <Box my={2}>
          <ButtonLoader
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t("login")}
          </ButtonLoader>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
