import { useRef, useState, Fragment, useMemo, useEffect } from "react";
import { Paper, Box, Typography, makeStyles, AppBar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AddressStatusList, translatedExcelColumns } from "../data/constants";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Table from "shared/ui/Table/index";
import toast from "react-hot-toast";

import {
  approveOrders,
  orderExcelImport,
  removeOrder,
  selectExcelOrders,
  setExcelOrders,
} from "src/store/slicers/order";
import ButtonLoader from "components/ButtonLoader";
import ConfirmDialog from "src/shared/ui/DialogConfirm";
import { useNavigate } from "react-router";
import { red, green } from "@material-ui/core/colors";
import { handleDownload } from "src/helpers";
import ExampleExcel from "../../../../public/assets/files/excel_import.xlsx?url";
import AutocompleteField from "src/shared/ui/Autocomplete";
import { useForm } from "react-hook-form";
import {
  fetchAddresses,
  rememberAddress,
  selectAddressesList,
} from "src/store/slicers/address";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  successRow: {
    backgroundColor: green[100],
  },
  errorRow: {
    backgroundColor: red[100],
  },
});

const ExcelImportOrders = () => {
  const [excelImportError, setExcelImportError] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  const excelOrdersData = useSelector(selectExcelOrders);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const addressList = useSelector(selectAddressesList);
  const { t } = useTranslation();
  const excelColumns = translatedExcelColumns(t);

  const {
    control,
    formState: { touchedFields },
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: { returnSenderId: "" },
  });

  const dispatch = useDispatch();
  const [confirmDialogData, setConfirmDialogData] = useState({
    open: false,
    data: null,
  });

  const actualAddressId = watch("returnSenderId")?.id;

  const fileInputRef = useRef();
  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const onFileInputChange = async (e) => {
    if (!e.target.files?.length) {
      return;
    }
    setLoading(true);

    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    const { error, meta, payload } = await dispatch(
      orderExcelImport({ formData, returnSenderId: actualAddressId })
    );
    if (meta.requestStatus !== "fulfilled") {
      dispatch(setExcelOrders([]));
      if (error?.status === 404) {
        setExcelImportError(
          `${t("excel_import_error.imported_file")} ${error.data?.key} ${t(
            "excel_import_error.there_are_problems"
          )}`
        );
      }
      setLoading(false);
      fileInputRef.current.value = null;
      return;
    }

    fileInputRef.current.value = null;
    setExcelImportError("");
    dispatch(setExcelOrders(payload));
    setLoading(false);
  };

  const dropdownActions = useMemo(() => {
    return [
      {
        icon: <NotInterestedIcon color="error" />,
        text: t("remove"),
        action: (data) => {
          setConfirmDialogData({
            data,
            open: true,
          });
        },
      },
    ];
  }, []);

  const handleRemoveOrder = async () => {
    setConfirmDialogData({
      ...confirmDialogData,
      open: false,
    });

    setLoading(true);

    const filteredExcelOrders = excelOrdersData.filter(
      (i) => i.parcelId !== confirmDialogData.data.parcelId
    );

    dispatch(setExcelOrders({ parcels: filteredExcelOrders }));

    const paramsString = {
      deleteParcelIds: [confirmDialogData.data.parcelId],
    };

    const { meta, payload } = await dispatch(removeOrder(paramsString));

    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }
    toast.success(t("parcel_is_removed"));

    setLoading(false);
  };

  const handleApprove = async () => {
    setSubmitLoading(true);
    const selectedIds = excelOrdersData
      .filter((i) => i.errorMessage !== "Success")
      .map((i) => i.parcelId);

    const { meta, payload } = await dispatch(approveOrders(selectedIds));
    if (meta.requestStatus !== "fulfilled") {
      setSubmitLoading(false);
      return;
    }
    const failedOrders = payload.data.filter(
      (item) => item.message !== "Success"
    );
    if (failedOrders?.length) {
      toast.error(t("there_are_not_approved_parcels"));
    } else {
      toast.success(t("parcel_is_approved"));
    }

    navigate("/admin/orders", { replace: true });
    return;
  };

  const getRowColor = (row) =>
    row?.errorMessage
      ? typeof row?.errorMessage === "string"
        ? classes.successRow
        : classes.errorRow
      : "white";

  const handleDownloadExample = async () => {
    handleDownload(ExampleExcel, "example", ".xlsx");
  };

  useEffect(() => {
    return () => {
      dispatch(setExcelOrders([]));
    };
  }, []);

  const formattedAddressList = useMemo(() => {
    if (addressList?.length) {
      const sortedAddressesList = [...addressList];
      return sortedAddressesList
        ?.sort((a, b) => a.userDefaultReturnSender - b.userDefaultReturnSender)
        ?.map((item) => {
          return {
            groupLabel:
              AddressStatusList[item.userDefaultReturnSender - 1]?.label,
            ...item,
          };
        });
    }
    return [];
  }, [addressList]);

  const handleRememberAddress = async () => {
    if (!actualAddressId) {
      return;
    }

    const { meta } = await dispatch(rememberAddress(actualAddressId));

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("address_is_remembered"));
  };

  const fetchData = () => {
    dispatch(fetchAddresses());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!addressList?.length) {
      return;
    }

    const defaultAddress = addressList?.find(
      (i) => i.userDefaultReturnSender === 1 || i.userDefaultReturnSender === 2
    );

    if (defaultAddress) {
      reset({
        returnSenderId: defaultAddress,
      });
    }
  }, [addressList, setValue]);

  return (
    <Fragment>
      <Box p={2} component={Paper}>
        <Box mb={4}>
          <Typography variant="h3">{t("import_parcels")}</Typography>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <ButtonLoader
            isLoading={false}
            onClick={handleDownloadExample}
            variant="contained"
            color="primary"
          >
            {t("typical_example")}
          </ButtonLoader>
        </Box>
      </Box>
      <Box mt={2} mb={2} component={Paper} p={2}>
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {t("sender_return_sender_address")}
          </Typography>
        </Box>
        {formattedAddressList && (
          <AutocompleteField
            size="medium"
            groupBy={(option) => option.groupLabel}
            name="returnSenderId"
            optionLabelKey="addressString"
            label={t("address")}
            control={control}
            options={formattedAddressList}
            disabled={!formattedAddressList?.length}
          />
        )}

        {touchedFields?.returnSenderId && (
          <Box mt={1}>
            <ButtonLoader
              className={classes.btn}
              isLoading={loading}
              onClick={handleRememberAddress}
            >
              {t("remember")}
            </ButtonLoader>
          </Box>
        )}
        <Box mt={2}>
          <ButtonLoader
            isLoading={loading}
            onClick={handleFileInputClick}
            variant="contained"
            color="primary"
          >
            {t("import_from_excel")}
          </ButtonLoader>
        </Box>
      </Box>

      <Box display="none">
        <input type="file" ref={fileInputRef} onChange={onFileInputChange} />
      </Box>
      {excelOrdersData?.length ? (
        <Box mt={4} p={4} component={Paper}>
          <Table
            title={t("imported_parcels")}
            isLoading={loading}
            columns={excelColumns}
            rowClasses={getRowColor}
            dropdownActions={dropdownActions}
            rows={excelOrdersData || []}
          />
          <Box mt={4}>
            <ButtonLoader
              isLoading={submitLoading}
              onClick={handleApprove}
              variant="contained"
              color="primary"
            >
              {t("approve")}
            </ButtonLoader>
          </Box>
        </Box>
      ) : null}

      {excelImportError && (
        <Box component={Paper} mt={2} p={4}>
          <Typography variant="h5" style={{ fontWeight: "bold" }} color="error">
            {excelImportError}
          </Typography>
        </Box>
      )}

      <ConfirmDialog
        isOpen={confirmDialogData.open}
        onAgree={handleRemoveOrder}
        onClose={() =>
          setConfirmDialogData({
            open: false,
            data: null,
          })
        }
      >
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        <Typography variant="h5">
          {`${t("are_you_sure")} ${t("remove")} ${t("the_parcel")}`}
        </Typography>
      </ConfirmDialog>
    </Fragment>
  );
};

export default ExcelImportOrders;
