import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "helpers/axios";
import { EBaseUrl } from "store/config/constants";
import thunkOptions from "store/config/thunkOptions";
import { ITransactionState } from "../models/interfaces";

const name = "TRANSACTIONS";
const initialState: ITransactionState = {
  transactions: [],
};

export const fetchTransactions = createAsyncThunk(
  `${name}/fetchTransactions`,
  async (params) =>
    (await api.get(`${EBaseUrl.Envelope}/Parcel/TransactionHistory?${params}`))
      .data,
  thunkOptions
);

const TransactionsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.fulfilled, (state, { payload }) => {
      state.transactions = payload;
    });
  },
});

export const selectTransactions = (state) => state.transactions.transactions;

export default TransactionsSlice.reducer;
