import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputField from "shared/ui/Input";
import { useNavigate } from "react-router";

import Button from "@material-ui/core/Button";
import { PasswordToggle } from "components/PasswordToggle";
import ButtonLoader from "components/ButtonLoader";
import useStyles from "./style";
import { changePassword, checkHash, resetPassword } from "store/slicers/auth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import useQueryParams from "src/helpers/useQueryParams";
import { useTranslation } from "react-i18next";

const passFieldRules = (t: any) => {
  return {
    required: { value: true, message: t("required_field") },
    minLength: { value: 8, message: `${t("minimum")} 30 ${t("character")}` },
    maxLength: { value: 30, message: `${t("maximum")} 30 ${t("character")}` },
  };
};

const SetPassword = ({ hash, isReset }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { password: "", passwordConfirm: "" },
  });
  const [getQueries, setQueries] = useQueryParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const togglePassword = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  const onSubmit = async (formData) => {
    if (formData.password !== formData.passwordConfirm) {
      toast.error(t("passwords_dont_match"));
      return;
    }

    setIsLoading(true);

    const formParams = { password: formData.password, hash: hash };
    const { meta } = await dispatch(changePassword(formParams));

    if (meta.requestStatus !== "fulfilled") {
      setIsLoading(false);
      return;
    }

    navigate("/login", { replace: true });
    const successMessage = isReset
      ? t("password_is_restored_message")
      : t("password_is_created_message");
    toast.success(successMessage);
    setIsLoading(false);
  };

  const checkHashThunk = async () => {
    const { meta, payload } = await dispatch(checkHash(getQueries()?.hash));

    if (meta.requestStatus !== "fulfilled") {
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    checkHashThunk();
  }, []);

  return (
    <Box className={classes.form}>
      <Box mb={3} mt={3}>
        <Typography className={classes.formTitle}>
          {isReset ? t("password_restoring") : t("password_creating")}
        </Typography>
      </Box>
      <InputField
        control={control}
        name="password"
        margin="normal"
        label={t("new_password")}
        rules={passFieldRules(t)}
        type={isPasswordVisible ? "text" : "password"}
        errors={errors}
        InputProps={{
          endAdornment: (
            <PasswordToggle
              isPasswordVisible={isPasswordVisible}
              togglePassword={togglePassword}
            />
          ),
        }}
      />
      <InputField
        control={control}
        name="passwordConfirm"
        margin="normal"
        label={t("password_confirmation")}
        rules={passFieldRules(t)}
        type={isPasswordVisible ? "text" : "password"}
        errors={errors}
        InputProps={{
          endAdornment: (
            <PasswordToggle
              isPasswordVisible={isPasswordVisible}
              togglePassword={togglePassword}
            />
          ),
        }}
      />

      <Box my={2}>
        <ButtonLoader
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          size="large"
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t("approve")}
        </ButtonLoader>
        <Box display="flex" mt={2} justifyContent="center">
          <Button
            className={classes.forgotPassText}
            onClick={() => navigate("/login", { replace: true })}
          >
            {t("login")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SetPassword;
