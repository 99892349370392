import { addHours, format } from "date-fns";
import moment from "moment";

export const getIdsFromOptions = (value) => {
  return Array.isArray(value) ? value.map((item) => item.id) : [value.id];
};

export const getOptionsByIds = (ids, options, isSingle) => {
  return Array.isArray(ids)
    ? options.length &&
        ids.map((id) => options.find((option) => option.id === parseInt(id)))
    : options.length && isSingle
    ? options.find((option) => option.id === parseInt(ids))
    : [options.find((option) => option.id === parseInt(ids))];
};

export function handleKeyPress(e) {
  const characterCode = e.key;
  if (characterCode === "Backspace") {
    return;
  }

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.target.value && e.target.value.length) {
      return "";
    } else if (characterNumber === 0) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
}

export const debounce = (func, timeout = 600) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const handleDownload = (url, name = "", extension = ".zip") => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Envelope_${name}_${moment().format("DD-MM-Y-h:mm:ss")}${extension}`
  );
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link?.click();
  link?.parentNode?.removeChild(link);
};

export const latinAlphabeticalHandler = (event) => {
  const regex = /[A-Za-z!@#$.%^&*()_|}{~">:<?/\][\\=\- ]/g;
  let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

export const internationalAlphabeticalsAndNumbersHandler = (event) => {
  const regex = /[A-Za-zА-Яа-я0-9!@#$.%^&*()_|}{~">:<?/\][\\=\- ]/g;
  let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

export const amountFormatter = (value: string): string =>
  value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const LSSetMultiple = (data) => {
  data.forEach((item) => {
    localStorage.setItem(item[0], item[1]);
  });
};

export const LSRemoveMultiple = (data) => {
  data.forEach((item) => {
    localStorage.removeItem(item);
  });
};

export const latinAlphabeticalAndNumericalHandler = (event) => {
  const regex = /[A-Za-z0-9!@#$.%^&*()_|}{~">:<?/\][\\=\- ]/g;
  let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

export const lowerCaseLatinAlphabeticalHandler = (event) => {
  const regex = /[a-z0-9!@#$.%^&*()_|}{~">:<?/\][\\=\- ]/g;
  let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

export const onlyAlphabeticalHandler = (event) => {
  const regex = /[A-Za-zԱ-Ֆա-ֆА-Яа-я}{~">:<?/\][\\=\- ]/g;
  let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

export const onlyNumericalPasteHandler = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!Number.isInteger(parseInt(value))) {
    event.preventDefault();
    return false;
  }
};

export const onlyNumericalHandler = (event, allowFloat) => {
  const regex = /^[0-9]*$/g;
  const key = String.fromCharCode(
    !event.charCode ? event.which : event.charCode
  );
  if (
    allowFloat &&
    event.target.value &&
    !event.target.value.includes(".") &&
    key === "."
  ) {
    return false;
  }
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
};

//'42019703' is a Amazon prefix,which should be deleted at orders "check-in"/"registration" process
export const removeAmazonPrefix = (value) => {
  const amazonPrefix = "42019703";
  if (value.trim() === amazonPrefix) return amazonPrefix;
  const regexp = new RegExp("^42019703");
  const res = regexp.test(value);
  return res ? value.replace(amazonPrefix, "") : value;
};
// URL search params string to object
const search = location.search.substring(1);
const urlParams = new URLSearchParams(search);
const entries = urlParams.entries();

export const URLParamsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const params = URLParamsToObject(entries);

export const getDefaultDate = (isStart: boolean) => {
  const timeZoneHours = Math.abs(new Date().getTimezoneOffset() / 60);
  const datetime = moment(new Date()).add(timeZoneHours, "hours");
  if (isStart) {
    datetime.subtract(1, "month");
  }
  datetime.add(1, "day");
  const [first, second] = datetime.toISOString().split(":");
  return `${first}:${second}`;
};

export const translateSelectOptions = (
  list: any,
  translationOptionObject: any,
  translatedList: any
) => {
  list.forEach((item: any) => {
    if (translationOptionObject?.[item.id]) {
      translatedList.push({
        ...item,
        name: translationOptionObject?.[item.id],
      });
    }
  });
};

export const extractFromXlSX = async (file, skipFirstRowEnabled = true) => {
  if (!file) {
    return console.error("something wrong with file");
  }

  const { readFile, utils } = await import("xlsx");
  const workBook = readFile(await file.arrayBuffer(), { sheetStubs: true });
  let sheet = workBook.Sheets[workBook.SheetNames[0]];
  if (skipFirstRowEnabled) {
    let range = utils.decode_range(sheet["!ref"]);
    range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
    sheet["!ref"] = utils.encode_range(range);
  }

  return utils.sheet_to_json(sheet, { defval: "" });
};

export const formatDateWithTimezone = (date: string) => {
  const dateTime = format(
    addHours(new Date(date), Math.abs(new Date().getTimezoneOffset() / 60)),
    "dd/MM/yyyy HH:mm"
  );
  return dateTime;
};
