import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  Grid,
  Chip,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import InputField from "src/shared/ui/Input";
import { EPackageType, PackageType } from "../data/constants";
import ButtonLoader from "src/components/ButtonLoader";
import { updateOrder } from "store/slicers/order";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const weightRules = (maxWeight: number, t: any) => {
  return {
    max: {
      value: maxWeight,
      message: `${t("maximum")} ${maxWeight} ${t("weight")}`,
    },
  };
};

const EditWeightDialog = ({ open, onClose, activeOrder, onSave }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      weight: "",
      note: "",
    },
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const { meta } = await dispatch(
      updateOrder({ orderId: activeOrder?.id, formData })
    );

    if (meta.requestStatus !== "fulfilled") {
      setIsLoading(false);
      return;
    }

    onClose();
    onSave();
    toast.success(t("parcel_is_changed"));
    setIsLoading(false);
  };

  const activePackageType = useMemo(
    () => PackageType.find((i) => i.id === activeOrder?.service?.id),
    [activeOrder]
  );

  useEffect(() => {
    setValue("weight", activeOrder?.weight);
    setValue("note", activeOrder?.note);
  }, [activeOrder]);

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      {" "}
      <Box m={4}>
        <Typography variant="h4">
          {`${t("change")} ${t("the_parcel")}`}
        </Typography>
      </Box>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              name="weight"
              label={`${t("weight")} (${t("gram")})`}
              control={control}
              rules={weightRules(activePackageType?.maximalWeight, t)}
              disabled={activePackageType?.id === EPackageType.POSTCARD}
              onKeyPress={onlyNumericalHandler}
              onPaste={onlyNumericalPasteHandler}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="note"
              label={`${t("additional")} ${t("note")}`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("required_field"),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonLoader
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("save")}
            </ButtonLoader>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditWeightDialog;
