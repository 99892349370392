import { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import CircularLoader from "components/CircularLoader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onAgree: () => void;
  children: JSX.Element;
  maxWidth?: string;
}

const ConfirmDialog = ({
  isOpen,
  onClose,
  isLoading = false,
  onAgree,
  children,
  maxWidth = "sm",
}: IConfirmDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [hasPressed, setHasPressed] = useState(false);

  const handleAgree = () => {
    setHasPressed(true);
  };

  useEffect(() => {
    if (isOpen) {
      setHasPressed(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (hasPressed) {
      onAgree();
    }
  }, [hasPressed]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth}>
      <CircularLoader loading={isLoading} maxContent={false}>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {onAgree && (
            <Button color="primary" onClick={handleAgree} disabled={hasPressed}>
              {t("approve")}
            </Button>
          )}
          {onClose && (
            <Button color="primary" onClick={onClose}>
              {t("cancel")}
            </Button>
          )}
        </DialogActions>
      </CircularLoader>
    </Dialog>
  );
};

export default ConfirmDialog;
