import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useTableCrud = (setLoading, fetchData, title) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const { t } = useTranslation();

  const isUpdate = useMemo(() => {
    return !!Object.keys(updateData).length;
  }, [updateData]);
  const currentTitle = useMemo(() => {
    return isUpdate
      ? title.replace(t("add"), t("edit"))
      : title.replace(t("edit"), t("add"));
  }, [title, isUpdate, t]);

  const saveAction = async () => {
    setLoading(true);
    await fetchData();
    setIsDialogOpen(false);
    setLoading(false);
    setUpdateData({});
  };

  const closeAction = () => {
    setIsDialogOpen(false);
    setTimeout(() => {
      setUpdateData({});
    }, 200);
  };

  return {
    saveAction,
    closeAction,
    updateData: {
      get: updateData,
      set: setUpdateData,
    },
    dialogOpen: {
      get: isDialogOpen,
      set: setIsDialogOpen,
    },
    isUpdate,
    title: t(currentTitle),
  };
};

export default useTableCrud;
