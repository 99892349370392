import { forwardRef, RefObject, useMemo } from "react";

import HaypostLogo from "src/assets/print-icons/haypost.svg";

import { printStyles } from "./printStyles";
import CP72EnclosuresArea from "./components/CP72EnclosuresArea";
import useEnclosuresHook from "./hooks/useEnclosuresHook";
import { Box, Grid } from "@material-ui/core";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import hsCodeList from "src/assets/constants/hsCodes.json";

const Cp72 = forwardRef(
  (props: { rightText?: string; printData?: any }, ref) => {
    const { printData } = props;
    const classes = printStyles();

    const enclosuresUpdatedData = useMemo(() => {
      const updatedData = JSON.parse(
        printData?.enclosures
      )?.[0]?.Enclosures.map((item) => {
        return {
          ...item,
          description: hsCodeList.find((hsCode) => hsCode.code === item.HsCode)
            ?.description_en,
        };
      });
      return updatedData;
    }, [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]);

    const backendDataForFirstPage = useEnclosuresHook(
      enclosuresUpdatedData,
      1,
      classes
    );
    const backendDataForSecondPage = useEnclosuresHook(
      enclosuresUpdatedData,
      2,
      classes
    );

    const {
      apartment,
      street,
      building,
      provinceState,
      cityVillage,
      postalCode,
      country,
      nameSurname,
      phone,
      isLegal,
    } = printData.recipient;

    const {
      street: senderStreet,
      postalCode: senderPostalCode,
      building: senderBuilding,
      apartment: senderApartment,
      cityVillage: senderCityVillage,
      provinceState: senderProvinceState,
      nameSurname: senderNameSurname,
      isLegal: senderIsLegal,
      phone: senderPhone,
    } = printData?.returnSender;

    const totalAmount = useMemo(() => printData?.amount, [printData?.amount]);

    const enclosuresAmount = useMemo(
      () =>
        JSON.parse(printData?.enclosures)?.[0]?.Enclosures
          ? JSON.parse(printData?.enclosures)?.[0]?.Enclosures.reduce(
              (prev, current) => {
                return prev + current.Amount;
              },
              0
            )
          : 0,
      [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]
    );

    return (
      <Grid ref={ref as RefObject<HTMLDivElement>}>
        <Grid className={classes.cp72mainBorders}>
          <Grid container className={classes.cp72contentArea} spacing={0}>
            <div className={classes.leftTitle}>
              <p style={{ fontSize: "11px", textTransform: "uppercase" }}>
                Նախքան այս ձևաթուղթը լրացնելը ուշադիր կարդացեք դարձերեսի
                ցուցումները
              </p>
              <p style={{ fontSize: "11px", textTransform: "uppercase" }}>
                before compliting this form,read instructions on back carefully!
              </p>
              <p className={classes.lettersSize13}>
                Ձեր ապրանքները կարող են սահմանապակման ենթարկվել
              </p>
              <p className={classes.lettersSize10}>
                Your goods may be subject restrictions
              </p>
            </div>
            <div className={classes.cp72RightTitle}>
              <p>{props.rightText}</p>
            </div>
            <Grid className={classes.cp72content}>
              <Grid className={classes.leftAndRightContent} item xs={12}>
                <Grid item xs={6}>
                  <Grid
                    className={classes.titleArea}
                    style={{ paddingTop: "2px" }}
                    item
                    xs={12}
                  >
                    <div className={classes.textAreaLeft}>
                      <p className={classes.lettersSize7}>
                        (Նշանակված օպերատոր)
                      </p>
                      <p className={classes.lettersSize7}>
                        (Designated operator)
                      </p>
                    </div>
                    <div className={classes.textAreaRight}>
                      <p className={classes.lettersSize7}>
                        Առաքանին/ծանրոցը կառող է բացվել ծառայական կարգով
                      </p>
                      <p className={classes.lettersSize7}>
                        The item/parcel may be opened officially
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.formToAreaParent}
                    style={{ borderLeft: "1px solid" }}
                    item
                    xs={12}
                  >
                    <Grid
                      className={classes.leftFormToArea}
                      style={{
                        position: "absolute",
                        marginLeft: "0",
                        left: "-30px",
                        borderRight: "none",
                      }}
                    >
                      <div
                        className={`${classes.textAreaRight} ${classes.edgingTop}`}
                      >
                        <p className={classes.lettersSize7}>Որտեղից</p>
                        <p className={classes.lettersSize7}>From</p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid className={classes.nameBusinessArea} item xs={12}>
                        <Grid item xs={9}>
                          <Grid className={classes.cp72GridItem} item xs={12}>
                            <div
                              className={classes.textAreaRight}
                              style={{ width: "61.13px" }}
                            >
                              <p className={classes.lettersSize7}>
                                Անուն, Ազգանուն
                              </p>
                              <p className={classes.lettersSize7}>Name</p>
                            </div>
                            <div className={classes.cp72DataField}>
                              <p
                                className={classes.lettersSize6}
                                style={{ fontWeight: "bold" }}
                              >
                                {printData?.returnSender?.nameSurname
                                  ? printData?.returnSender?.nameSurname
                                  : ""}
                              </p>
                            </div>
                          </Grid>
                          <Grid className={classes.cp72GridItem} item xs={12}>
                            <div className={classes.textAreaRight}>
                              <p className={classes.lettersSize7}>
                                Կազակերպություն
                              </p>
                              <p className={classes.lettersSize7}>Business</p>
                            </div>
                            <div className={classes.cp72DataField}>
                              <p
                                className={classes.lettersSize6}
                                style={{ fontWeight: "bold" }}
                              >
                                {printData?.returnSender?.legalName
                                  ? printData?.returnSender?.legalName
                                  : ""}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className={classes.customRef} item xs={3}>
                          <div className={classes.textAreaLeft}>
                            <p className={classes.lettersSize6}>
                              Ուղղարկողի մաքսային թիվ(եթե այն առկա է)
                            </p>
                            <p className={classes.lettersSize6}>
                              Sender’s customs reference (if any)
                            </p>
                            <p className={classes.lettersSize6} />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <Box width="60%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>Փողոց</p>
                            <p className={classes.lettersSize7}>Street</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderStreet}
                              {senderBuilding}
                              {senderApartment}
                            </p>
                          </div>
                        </Box>
                        <Box width="40%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Հեռ.</p>
                            <p className={classes.lettersSize7}>Tel. No.</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderPhone}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <Box width="50%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>
                              Փոստային դասիչ
                            </p>
                            <p className={classes.lettersSize7}>Postcode</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderPostalCode}
                            </p>
                          </div>
                        </Box>
                        <Box width="50%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Քաղաք</p>
                            <p style={{ fontSize: "7px", paddingLeft: "5px" }}>
                              City
                            </p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderProvinceState} {senderCityVillage}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <div
                          className={classes.textAreaRight}
                          style={{ width: "61.13px" }}
                        >
                          <p className={classes.lettersSize7}>Երկիր</p>
                          <p className={classes.lettersSize7}>Country</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            Armenia
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.formToAreaParent}
                    style={{ borderLeft: "1px solid" }}
                    item
                    xs={12}
                  >
                    <Grid
                      className={classes.leftFormToArea}
                      style={{
                        position: "absolute",
                        marginLeft: "0",
                        left: "-12px",
                      }}
                    >
                      <div
                        className={classes.textAreaRight}
                        style={{ borderTop: "1px solid" }}
                      >
                        <p className={classes.lettersSize7}>Ուր</p>
                        <p className={classes.lettersSize7}>To</p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <div className={classes.textAreaRight}>
                          <p
                            className={classes.lettersSize7}
                            style={{ width: "61.13px" }}
                          >
                            Անուն, Ազգանուն
                          </p>
                          <p className={classes.lettersSize7}>Name</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            {printData.recipient?.nameSurname
                              ? printData.recipient?.nameSurname
                              : ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <div className={classes.textAreaRight}>
                          <p className={classes.lettersSize7}>
                            Կազակերպություն
                          </p>
                          <p className={classes.lettersSize7}>Business</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            {printData.recipient?.legalName
                              ? printData.recipient?.legalName
                              : ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <Box width="60%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>Փողոց</p>
                            <p className={classes.lettersSize7}>Street</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {street} {building} {apartment}
                            </p>
                          </div>
                        </Box>
                        <Box width="40%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Հեռ.</p>
                            <p className={classes.lettersSize7}>Tel. No.</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {phone}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cp72GridItem} item xs={12}>
                        <Box width="50%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>
                              Փոստային դասիչ
                            </p>
                            <p className={classes.lettersSize7}>Postcode</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {postalCode}
                            </p>
                          </div>
                        </Box>
                        <Box width="50%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Քաղաք</p>
                            <p style={{ fontSize: "7px", paddingLeft: "5px" }}>
                              City
                            </p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p className={classes.lettersSize6}>
                              {provinceState} {cityVillage}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid
                        className={classes.cp72GridItem}
                        style={{ borderBottom: "1px solid " }}
                        item
                        xs={12}
                      >
                        <div
                          className={classes.textAreaRight}
                          style={{ width: "61.13px" }}
                        >
                          <p className={classes.lettersSize7}>Երկիր</p>
                          <p className={classes.lettersSize7}>Country</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p className={classes.lettersSize6}>
                            {printData?.country?.name}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.rightArea} item xs={6}>
                  <Grid item xs={12}>
                    <Grid className={classes.ReceiptText} item xs={9}>
                      <Box display="flex" position="relative">
                        <div
                          className={classes.textAreaLeft}
                          style={{ paddingTop: "5px" }}
                        >
                          <p className={classes.lettersSize13}>Ստացական</p>
                          <p className={classes.lettersSize10}>Receipt</p>
                          <p className={classes.lettersSize6}>
                            Ծանրոցի(ների). թիվը(երը)
                          </p>
                          <p className={classes.lettersSize7}>
                            (գծանիշ կոդ, եթե կա)
                          </p>
                          <p className={classes.lettersSize7}>
                            No(s). of parcel(s)
                          </p>
                          <p className={classes.lettersSize7}>
                            (barcode, if any)
                          </p>
                        </div>
                        <Box>
                          <div
                            style={{ textAlign: "center", paddingTop: "9px" }}
                          >
                            {/* <p>{printData.barcode}</p> */}
                            <Box height="18.52mm" width="90%">
                              <BarcodeToPrint
                                barcode={printData.trackingNumber}
                                height={25}
                                fontSize={12}
                              />
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src={HaypostLogo}
                        alt="logo"
                        className={classes.cp72HpLogo}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.cp72RightAreaGridItem} item xs={12}>
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Հայտարարագրված արժեք-բառերով
                      </p>
                      <p className={classes.lettersSize8}>
                        Insured value – Words
                      </p>
                    </div>
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingLeft: "29%" }}
                    >
                      <p className={classes.lettersSize8}>
                        <span className={classes.rowStraight3} />
                        թվերով&nbsp;<span></span>
                      </p>
                      <p className={classes.lettersSize8}>figures</p>
                    </div>
                  </Grid>
                  <Grid className={classes.cp72RightAreaGridItem} item xs={12}>
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Վերադիր վճարի գումար-բառերով
                      </p>
                      <p className={classes.lettersSize8}>
                        Cash-on-delivery amount – Words
                      </p>
                    </div>
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingLeft: "32%" }}
                    >
                      <p className={classes.lettersSize8}>
                        <span className={classes.rowStraight3} />
                        թվերով&nbsp;<span></span>
                      </p>
                      <p className={classes.lettersSize8}>figures</p>
                    </div>
                  </Grid>
                  <Grid className={classes.cp72RightAreaGridItem} item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                      <div
                        className={classes.textAreaLeft}
                        style={{ paddingTop: "4px" }}
                      >
                        <p className={classes.lettersSize8}>
                          Ժիրո հաշվեհամար և Ժիրո կենտրոն
                        </p>
                        <p className={classes.lettersSize8}>
                          Giro account No. and Giro centre
                        </p>
                      </div>
                      <div className={classes.lettersSize8} />
                    </Box>
                  </Grid>
                  <Grid
                    className={classes.cp72RightAreaGridItem}
                    style={{ height: "37px", paddingBottom: "8px" }}
                    item
                    xs={12}
                  >
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Ներմուծողի/հասցեատիրոջ տվյալները(եթե առկա են)
                      </p>
                      <p className={classes.lettersSize8}>
                        (հարկային կոդ/ԱԱՀ/ներմուծողի կոդը)(այլընտրանքային)
                      </p>
                      <p className={classes.lettersSize8}>
                        Importer/addressee reference (if any) (tax code/VAT
                        No./importer code) (optional)
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cp72RightAreaGridItem}
                    style={{ height: "28px" }}
                    item
                    xs={12}
                  >
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Ներմուծողի/հասցեատիրոջ ֆաքս/էլ․ հասցե(եթե հայտնի է)
                      </p>
                      <p className={classes.lettersSize8}>
                        Importer/addressee fax/e-mail (if known)
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <CP72EnclosuresArea />
              <Box fontWeight="bold" fontSize="12px">
                {backendDataForFirstPage}
              </Box>
              <Grid className={classes.cp72FourthSection} item xs={12}>
                <div
                  className={classes.cp72FourthSectionFirstColumn}
                  style={{ paddingBottom: "3px" }}
                >
                  <div className={classes.cp72FourthSectionFirstColumnFirstRow}>
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                    >
                      <p className={classes.lettersSize7}>
                        Խնդրում ենք նշել ծառայության{" "}
                      </p>
                      <p className={classes.lettersSize7}>
                        տեսակը(նշեք նոր վանդակ)
                      </p>
                    </div>
                    <p className={classes.lettersSize7}>
                      Please indicate service required(tick one box)
                    </p>
                  </div>
                  <div
                    className={classes.cp72FourthSectionFirstColumnSecondRow}
                  >
                    <div className={classes.cp72CheckboxArea}>
                      <div
                        className={`${classes.square} ${classes.squareSizes1}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                      >
                        <p className={classes.lettersSize7}>
                          Միջազգային առաջնային{" "}
                        </p>
                        <p className={classes.lettersSize7}>
                          International Priority
                        </p>
                      </div>
                    </div>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "20px" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes2}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                      >
                        <p className={classes.lettersSize7}>
                          Միջազգային Էկոնոմ
                        </p>
                        <p className={classes.lettersSize7}>
                          International Economy
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.edgingRight}>
                  <div className={classes.cp72FourthSectionSecondColumn}>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize7}>Ընդհանուր</p>
                      <p className={classes.lettersSize7}>համաքաշ(4)</p>
                      <p className={classes.lettersSize6}>
                        Total gross weight(4)
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        {printData.weight / 1000} Kg
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.cp72FourthSectionSecondColumn} ${classes.edgingRight}`}
                  style={{ width: "66.19px" }}
                >
                  <div className={classes.cp72HeaderArea}>
                    <p className={classes.lettersSize7}>Ընդհանուր արժեք(6)</p>
                    <p className={classes.lettersSize6}>Total value(6)</p>
                    <p style={{ fontWeight: "bold" }}>{enclosuresAmount} AMD</p>
                  </div>
                </div>
                <div className={classes.cp72FourthSectionThirdColumn}>
                  <div className={classes.cp72HeaderArea}>
                    <p className={classes.lettersSize7}>
                      Փոստային վճար/գանձված սակագին(9)
                    </p>
                    <p className={classes.lettersSize6}>
                      Postal charges/Fees(9)
                    </p>
                    <p style={{ fontWeight: "bold" }}>{totalAmount} AMD</p>
                  </div>
                </div>
              </Grid>
              <Grid className={classes.cp72FifthSection} item xs={12}>
                <div className={classes.cp72FifthSectionLeftArea}>
                  <div
                    className={classes.cp72FifthSectionLeftAreaFirstRow}
                    style={{ paddingBottom: "2px" }}
                  >
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowFirstColumn
                      }
                    >
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize6}>
                          Առաքանու բնույթ(10)
                        </p>
                        <p className={classes.lettersSize6}>
                          Category of item(10)
                        </p>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes3}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>Նվեր</p>
                          <p className={classes.lettersSize6}>Gift</p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes4}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "3px" }}
                        >
                          <p className={classes.lettersSize6}>Փաստաթղթեր</p>
                          <p className={classes.lettersSize6}>Documents</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowSecondColumn
                      }
                    >
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes5}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Կոմերցիոն նմուշ
                          </p>
                          <p className={classes.lettersSize6}>
                            Comercial sample
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes6}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "2px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Ապրանքների վերադարձ
                          </p>
                          <p className={classes.lettersSize6}>Returned goods</p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes7}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "2px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Ապրանքների վաճառք
                          </p>
                          <p className={classes.lettersSize6}>Sale of goods</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowThirdColumn
                      }
                    >
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes8}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>Այլ(նշել)</p>
                          <p className={classes.lettersSize6}>
                            Other(please specify)
                          </p>
                        </div>
                      </div>
                      <div style={{ paddingTop: "5px" }}>
                        <p className={classes.lettersSize6}>Բացատրություն</p>
                        <p className={classes.lettersSize6}>Explanation</p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowFourthColumn
                      }
                    >
                      <p className={classes.lettersSize6} />
                      <div className={classes.cp72RowLeft} />
                    </div>
                  </div>
                  <div className={classes.cp72FifthSectionLeftAreaSecondRow}>
                    <p className={classes.lettersSize7}>
                      Նշումներ(11)(օր․ կարանտինի տակ գտնվող
                      սանիտարական/ֆիտոսանիտարական
                    </p>
                    <p className={classes.lettersSize7}>
                      ստուգման և այլ սահմանափակման ենթակա ապրանքներ)
                    </p>
                    <p className={classes.lettersSize7}>
                      Comments(11)(e.g.: goods subject to quarantine,
                      sanitary/phytosanytary inspection or other restrictions
                    </p>
                    <p className={classes.lettersSize7} />
                  </div>
                </div>
                <div className={classes.cp72FifthSectionRightArea}>
                  <div className={classes.cp72FifthSectionRightAreaFirstColumn}>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>Տրման գրասենյակ/</p>
                      <p className={classes.lettersSize6}>ընդունման ամսաթիվ</p>
                      <p className={classes.lettersSize6}>
                        Office of origin/Date of posting
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72FifthSectionRightAreaSecondColumn}
                  >
                    <div
                      className={`${classes.cp72FifthSectionRightAreaSecondColumnFirstRow} ${classes.edgingBottom}`}
                    >
                      <div
                        className={classes.cp72HeaderArea}
                        style={{ paddingRight: "25px" }}
                      >
                        <p className={classes.lettersSize6}>Ծանրոցների քանակ</p>
                        <p className={classes.lettersSize6}>
                          Number of parcels
                        </p>
                      </div>
                      <div>
                        <p className={classes.lettersSize6}>
                          <span className={classes.rowStraight3} />
                          Հավաստագրեր և
                        </p>
                        <p className={classes.lettersSize6}>ապրանքանգրեր</p>
                        <p className={classes.lettersSize6}>certificates</p>
                        <p className={classes.lettersSize6}>and invoices</p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionRightAreaSecondColumnSecondRow
                      }
                    >
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize6}>
                          Հայտարարագրված արժեք ՀՓԻ-ով
                        </p>
                        <p className={classes.lettersSize6}>
                          Insured value SDR
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionRightAreaSecondColumnThirdRow
                      }
                    >
                      <div
                        className={classes.cp72HeaderArea}
                        style={{ width: "22mm" }}
                      >
                        <p className={classes.lettersSize6}>Ծանրոց(ների)</p>
                        <p className={classes.lettersSize6}>
                          ընդհանուր համաքաշ
                        </p>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <p className={classes.lettersSize5}>
                              Total gross weight
                            </p>
                            <p className={classes.lettersSize5}>
                              of the parcel(s)
                            </p>
                          </Box>
                          <Box fontWeight="bold">
                            {printData.weight / 1000} Kg
                          </Box>
                        </Box>
                      </div>
                      <div className={classes.cp72Charges}>
                        <p className={classes.lettersSize6}>Վճարներ</p>
                        <p className={classes.lettersSize6}>Charges</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className={classes.cp72SixthSection}>
                <div className={classes.cp72SixthSectionLeftArea}>
                  <Grid
                    className={classes.cp72SixthSectionLeftAreaColumns}
                    item
                    xs={4}
                  >
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>
                          Թույլտվություն(12)
                        </p>
                        <p className={classes.lettersSize6}>Licence(12)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>
                        Թույլտվության թիվ(եր)
                      </p>
                      <p className={classes.lettersSize6}>Licence number(s)</p>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cp72SixthSectionLeftAreaColumns}
                    item
                    xs={4}
                  >
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>Հավաստագիր(13)</p>
                        <p className={classes.lettersSize6}>Certificate(13)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>Հավաստագրի թիվ(եր)</p>
                      <p className={classes.lettersSize6}>
                        Certificate number(s)
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cp72SixthSectionLeftAreaColumns}
                    item
                    xs={4}
                  >
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>Ապրանքագիր(14)</p>
                        <p className={classes.lettersSize6}>Invoice(14)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>Ապրանքագրի թիվ(եր)</p>
                      <p className={classes.lettersSize6}>Invoice number(s)</p>
                    </div>
                  </Grid>
                </div>
                <div className={classes.cp72SixthSectionRightArea}>
                  <div
                    className={classes.cp72SixthSectionRightAreaFirstRow}
                    style={{
                      display: "flex",
                      width: "291.5px",
                      paddingBottom: "2px",
                    }}
                  >
                    <div
                      style={{ padding: "4px 61px 0 5px ", textAlign: "left" }}
                    >
                      <p className={classes.lettersSize6}>
                        Ուղղարկողի ցուցումները չհանձնման դեպքում
                      </p>
                      <p className={classes.lettersSize5}>
                        Sender`&apos;`s instructions in case of none delivery
                      </p>
                    </div>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes12}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ padding: "4px 0 0 20px" }}
                      >
                        <p className={classes.lettersSize5}>Առաջնային</p>
                        <p className={classes.lettersSize5}>Priority</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classes.cp72SixthSectionRightAreaSecondRow}
                    style={{ display: "flex", width: "291.5px" }}
                  >
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "10px" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes13}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize5}>Հետ չուղարկել</p>
                        <p className={classes.lettersSize5}>
                          Treat as abandoned
                        </p>
                      </div>
                    </div>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "20px" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes14}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize5}>Վերադարձնել</p>
                        <p className={classes.lettersSize5}>ուղղարկողին</p>
                        <p className={classes.lettersSize5}>Return to sender</p>
                      </div>
                    </div>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "39px" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes15}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "25px" }}
                      >
                        <p className={classes.lettersSize5}>Ոչ առաջնային</p>
                        <p className={classes.lettersSize5}>Not priority</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <div className={classes.cp72SeventhSection}>
                <div className={classes.cp72SeventhSectionLeftArea}>
                  <div
                    className={classes.cp72SeventhSectionLeftAreaFirstColumn}
                  >
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize5}>
                        Ես հավաստիացնում եմ, որ սույն հայտարարագրում նշված
                        տվյալները ճիշտ են և սույն առաքանին չի պարունակում ՀՀ ,
                        նշանակման կամ տարանցման երկրների մաքսային, հարկային,
                        փոստային կամ այլ օրենսդրությամբ սահմանված արգելված կամ
                        վտանգացվոր ներդրվածք: Ես գիտակցում եմ, որ սույն առաքանու
                        ներդրվածքի համար հանդիսանում եմ միակ պատասխանատուն ՀՀ
                        և/կամ նշանակման երկրի մաքսային և/կամ հարկային մարմինների
                        առջև՝ օրենսդրության պահանջները և սահմանված ընթացակարգերը
                        պահանելու, ինչպես նաև սահմանված հարկերը, տուրքերն ու այլ
                        վճարները պատշաճ կարգով և ժամանակին կատարելու համար:
                      </p>
                      <p className={classes.lettersSize5}>
                        I certify that the particulars given in the customs
                        declaration are correct and this item does not contain
                        any dangerous article prohibited by legislation or by
                        postal or customs regulations. I realize that I am
                        solely responsible for the contribution of this item to
                        the customs and / or tax authorities of the RA and / or
                        the country of destination for complying with the
                        requirements of the legislation and established
                        procedures, as well as for proper and timely payment of
                        taxes, duties and other charges.
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72SeventhSectionLeftAreaSecondColumn}
                  >
                    <div className={classes.edgingBottom}>
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize5}>
                          Գնահատված առաքանու կորստի կամ վնասի դեպքում
                        </p>
                        <p className={classes.lettersSize5}>
                          Փոխհատուցման առավելագույն չափը 250000 ՀՀ դրամ
                        </p>
                        <p className={classes.lettersSize5}>
                          է, որի մեջ ներառված չէ ծառայության արժեքը
                        </p>
                        <p style={{ fontSize: "4px" }}>
                          In case of loss or damage of insured item the maximum
                          conpensation
                        </p>
                        <p style={{ fontSize: "4px" }}>
                          amoun is 250000 AMD, excluding item postage
                        </p>
                      </div>
                    </div>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>
                        Ամսաթիվ և ուղղարկողի ստորագրություն(15)
                      </p>
                      <p className={classes.lettersSize6}>
                        Date and sender`&apos;`s signature(15)
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.cp72SeventhSectionRightArea}>
                  <div
                    className={classes.cp72SeventhSectionRightAreaFirstColumn}
                  >
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>Հայտարարություն</p>
                      <p style={{ fontSize: "6px", paddingBottom: "5px" }}>
                        հասցեատիրոջ կողմից
                      </p>
                      <p className={classes.lettersSize6}>Declaration</p>
                      <p className={classes.lettersSize6}>by addressee</p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72SeventhSectionRightAreaSecondColumn}
                  >
                    <p className={classes.lettersSize6}>
                      Ես ստացել եմ այս ստացականում նշված ծանրոցը
                    </p>
                    <p style={{ fontSize: "5px", paddingBottom: "5px" }}>
                      I have received the parcel described on this note
                    </p>
                    <p className={classes.lettersSize6}>
                      Ամսաթիվ և հասցեատիրոջ ստորագրություն
                    </p>
                    <p className={classes.lettersSize5}>
                      Date and addresses signature
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {enclosuresUpdatedData.length > 3 && (
          <Grid className={classes.cp72mainBorders}>
            <Grid container className={classes.cp72contentArea} spacing={0}>
              <Grid className={classes.cp72content}>
                <CP72EnclosuresArea />
                <Box fontWeight="bold" fontSize="12px">
                  {backendDataForSecondPage}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default Cp72;
