import { Grid, Box } from "@material-ui/core";
import { printStyles } from "../printStyles";

const CP72Instructions = (): JSX.Element => {
  const classes = printStyles();

  return (
    <Grid className={classes.emsMainBorders}>
      <Box className={classes.emsContentArea} fontSize="9px">
        <Box display="flex" justifyContent="space-between">
          <Box width="49%" className={classes.cpInstructionsLeftArea}>
            <Box
              textAlign="center"
              fontSize="11px"
              fontWeight="bold"
              style={{ padding: "6px 0" }}
            >
              Ցուցումներ
            </Box>
            <Box>
              Ձեր առաքանու մաքսային զննման համար նշանակման երկրի մաքսատունը պետք
              է հստակ իրազեկվի առաքանու ներդրվածքի մասին: Ուստի հայտարարագիրը
              պետք է լրացնել ամբողջությամբ եւ ընթեռնելի։ Այլապես այն կարող է
              հանգեցնել առաքանու ուշացումների եւ հասցեատիրոջ համար ստեղծել
              անհարմարություններ: Ցանկացած սխալ կամ ոչ պարզ լրացված
              հայտարարագիրը կարող է հանգեցնել տույժի կամ առաքանու առգրավման:
            </Box>
            <Box>
              Ձեր ապրանքները կարող են ենթակա լինել սահմանափակումների: Դուք
              պատասխանատու եք իրազեկված լինել ներմուծման եւ արտահանման
              կանոնակարգերին արգելումներ, սահմանափակումներ, ինչպիսիք են՝
              կարանտինը, դեղագործական արտադրանքի սահմանափակումները եւ այլն, եւ
              այն փաստաթղթերին, եթե առկա է կոմերցիոն ապրանքագիր, ծագման
              հավաստագիր, առողջության հավաստագիր, արտոնագիր, 1 կարանտինի տակ
              գտնվող ապրանքների համար թույլտվություն (կենդանական, բուսական
              սննդային՝ արտադրանքներ), որոնք կարող են պահանջվել նշանակման
              երկրում։
            </Box>
            <Box>
              Մաքսային զննումն արագացնելու համար անհրաժեշտ է լրացնել այս
              հայտարարագիրը անգլերեն, ֆրանսերեն կամ նշանակման երկրում ընդունված
              լեզվով: Հնարավորության դեպքում ավելացնել ներմուծողի հասցեատիրոջ
              հեռախոսահամարը եւ էլ. փոստի հասցեն, եւ ուղարկողի հեռախոսահամարը։
            </Box>
            <Box>
              Բիզնես գործարքի արդյունքում ցանկացած ներմուծվող կամ արտահանվող
              ապրանք համարվում է՝ կոմերցիոն, անկախ նրանիցայն վաճառվում է
              գումարով, թե փոխանակվում։
            </Box>
            <Box>
              (1) Նշել առաքանու յուրաքանչյուր ապրանքի մանրակրկիտ
              նկարագրությունը, օրինակ՝ «տղամարդու բամբակյա վերնաշապիկներ»:
              Ընդհանուր բնույթի այնպիսի նշումներ, ինչպիսիք են՝ «պահեստամասեր»,
              «նմուշներ» կամ «սննդամթերքն ընդունելի չեն:
            </Box>
            <Box>
              (2)Նշել յուրաքանչյուր ապրանքի քանակը եւ կիրառվող չափման միավորը:
            </Box>
            <Box>
              ( 3) եւ (4) Նշել յուրաքանչյուր ապրանքի զտաքաշը (կգ -Ով) Նշել
              առաքանու համաքաշը(կգ -ով, ներառյալ | փաթեթավորումը, համաձայն որի
              հաշվարկվել է առաքանու սակագինը:
            </Box>
            <Box>
              (5) եւ (6) Նշել յուրաքանչյուր ապրանքի արժեքը եւ ընդհանուր արժեքը՝
              կիրառվող արտարժույթով (օրինակ` 1 CHF շվեյցարական ֆրանկի համար):
            </Box>
            <Box>
              (7)եւ (8) ՆՀ սակագնային թիվը (6 նիշ) հիմնված է Համաշխարհային
              մաքսային կազմակերպության կողմից մշակված Ապրանքների ներդաշնակ
              նկարագրման եւ ծածկասգրման համակարգի վրա։ «Ծագման երկիր» տերմինը
              նշանակում է երկիրը, որտեղ ապրանքներն արտադրվել են, օրինակ՝
              արտադրման, վերամշակման կամ հավաքման երկիր, Կոմերցիոն առաքանի
              ուղարկողներին խորհուրդ է տրվում նշել այս տվյալները, քանի որ դա
              կօգնի առաքանու մաքսային մշակմանը:
            </Box>
            <Box>
              (9) Նշել փոստային առաքանու համար փույցում առաքանու համար վճարած
              սակագնի չափը: Առանձին նշել ցանկացած այլ վճայլ, օրինակ՝
              ապսյովագրություն:{" "}
            </Box>
            <Box>
              (10) Նշել առաքանու բնույթը մատնանշող վանդակը կա վանդակները։
            </Box>
            <Box>
              (11)Նշել մանրամասն տվյալներ, եեթե առաքանու ներդրվածքը ենթակա է
              կարանտինի(բուսական, կենդանական, սննդային արտադրանքներ) կամ այլ
              սահմանափակումներ
            </Box>
            <Box>
              (12),(13) և (14) Եթե ձեր առաքանին ուղեկցվում է հավաստագրով կամ
              արտոնագրով, նշել համապատասխան վանդակը և գրել թիվը։ Բոլոր կոմերցիոն
              առաքանիների համար պետք է կցել ապրանքագիր։
            </Box>
            <Box>
              (15)Ձեր ստորագրությունը ու ամսաթիվը հաստատում են Ձեր
              պատասխանատվությունն առաքանու հանդեպ։
            </Box>
          </Box>
          <Box width="49%" className={classes.cpInstructionsRightArea}>
            <Box
              textAlign="center"
              fontSize="11px"
              fontWeight="bold"
              style={{ padding: "6px 0" }}
            >
              Instructions
            </Box>
            <Box>
              To clear your item, the Customs in the country of destination need
              to know exactly what the contents are. You must therefore complete
              your declaration fully and legibly, otherwise, delay and
              inconvenience may result for the addressee. A false or misleading
              declaration may lead to a fine or to seizure of the item.
            </Box>
            <Box>
              Your goods may be subject to restrictions. It is your
              responsibility to enquire into import and export regulations such
              as quarantine, pharmaceutical restrictions, etc.) and to find out
              what documents if any (commercial Invoice, certificate of origin,
              health certificate, licence, authorization for goods subject to
              quarantine (plant, animal, food products, etc.) are required in
              the destination country. To accelerate customs clearance, complete
              this declaration in English, French or in a language accepted in
              the destination country. If available, add importer/addressee
              telephone number and e-mail address, and sender telephone number.
            </Box>
            <Box>
              Commercial item means any goods exported imported in the course of
              a business transaction, whether or not they are sold for money or
              exchanged.
            </Box>
            <Box>
              (1) Give a detailed description of each article in the item e.g.
              "men's cotton shirts". General descriptions, e.g. "spare parts",
              "samples" or "food products" are not permitted.
            </Box>
            <Box>
              (2) Give the quantity of each article and the unit of measurement
              used.
            </Box>
            <Box>
              (3) and (4) Give the net weight of each article (in kg). Give the
              total weight of the item (in kg)including packaging, which
              corresponds to the weight used to calculate the postage.
            </Box>
            <Box>
              (5) and (6) Give the value of each article and the total,
              including the currency used (e g. CHF Swiss francs).
            </Box>
            <Box>
              (7) and (8) The HS tariff number (6-digits) must be based on the
              Harmonized Commodity Description and coding System developed by
              the World Customs Organization. "Country of origin" means the
              country where the goods originated eq were produced
              manufacturedior assembled. Senders of commercial items are advised
              to supply this information as it will assist Customs in processing
              the items.
            </Box>
            <Box>
              (9) Give the amount of postage paid to the Post for the item
              Specify separately any other charges, eg, insurance.
            </Box>
            <Box>
              {" "}
              (10) Tick the box or boxes specifying the category of item.
            </Box>
            <Box>
              (11) Provide details if the contents are subject to quarantine
              (plant animal, food productetc) or other restrictions.
            </Box>
            <Box>
              (12),(13) and (14) If your stem is accompanied by a licence or a
              certificate, tick the appropriate box and state the number. You
              should attach an invoice for all commercial items.
            </Box>
            <Box>
              You signature and the date confirm your ability for the item
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CP72Instructions;
