import { useEffect, useMemo, useRef, useState } from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import ButtonLoader from "components/ButtonLoader";
import { handleDownload } from "src/helpers";
import ExampleExcel from "../../../public/assets/files/CN07_example.xlsx?url";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";
import { getCN07PDF } from "src/store/slicers/order";
import { format } from "date-fns";

const initialSeconds = 300;

const PrintExcelData = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [counter, setCounter] = useState<number>(initialSeconds);
  const [isClicked, setClicked] = useState(false);

  const clearTimer = (timer: NodeJS.Timeout) => {
    setCounter(initialSeconds);
    setClicked(false);
    clearInterval(timer);
  };

  const fileInputRef = useRef();

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleDownloadExample = async () => {
    handleDownload(ExampleExcel, "example", ".xlsx");
  };

  const onFileInputChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    setClicked(true);
    formData.append("file", file);
    const { payload, meta } = await dispatch(getCN07PDF(formData));

    if (meta.requestStatus !== "fulfilled") {
      setCounter(initialSeconds);
      setClicked(false);
      setLoading(false);
      return;
    }
    setCounter(initialSeconds);
    setClicked(false);
    const blob = new Blob([payload], { type: "application/pdf" });

    const href = URL.createObjectURL(blob);
    handleDownload(href, "CN07", ".pdf");

    fileInputRef.current.value = null;
    setLoading(false);
  };

  const showTimer = useMemo(() => {
    const timerInSeconds = `${format(counter * 1000, "m")}m : ${format(
      counter * 1000,
      "ss"
    )}sec`;
    if (isClicked) {
      return (
        <Box mt={2} mb={2} fontWeight="bold">
          {timerInSeconds}
        </Box>
      );
    }
    return null;
  }, [counter, isClicked]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isClicked) {
        setCounter(counter - 1);
      }
    }, 1000);
    if (!counter) {
      clearTimer(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isClicked, counter]);

  return (
    <Box p={2} component={Paper}>
      <Box mb={4}>
        <Typography variant="h3">{t("import_CN07")}</Typography>
      </Box>

      <Box mt={2} mb={2} p={2}>
        <ButtonLoader
          isLoading={false}
          onClick={handleDownloadExample}
          variant="contained"
          color="primary"
        >
          {t("typical_example")}
        </ButtonLoader>
      </Box>
      <Box mt={2} mb={2} p={2}>
        <ButtonLoader
          isLoading={loading}
          onClick={handleFileInputClick}
          variant="contained"
          color="primary"
        >
          {t("import_from_excel")}
        </ButtonLoader>
        {showTimer}
      </Box>
      <Box display="none">
        <input type="file" ref={fileInputRef} onChange={onFileInputChange} />
      </Box>
    </Box>
  );
};

export default PrintExcelData;
