import { makeStyles } from "@material-ui/core";

export const stylesObj = {
  "@global": {
    body: {
      color: "initial!important",
      backgroundColor: "white",
    },
  },
  main: {
    width: "210mm",
    margin: "0 auto",
    "& p": {
      margin: "0",
    },
  },
  type_4_1_content: {
    width: "80%",
    margin: "0 auto",
  },
  header: {
    textAlign: "right",
    marginRight: "13.75mm",
    marginTop: "14%",
    "& p": {
      fontWeight: "bold",
    },
  },

  letterNumber: {
    textAlign: "center",
    marginTop: "7.93mm",
    marginBottom: "4.76mm",
  },
  currencyNumber: {
    textAlign: "center",
    marginBottom: "3.97mm",
  },
  table: {
    borderTop: "1px solid",
    borderLeft: "1px solid",
  },
  tableTitle: {
    textAlign: "center",
    fontWeight: "bold",
    borderBottom: "1px solid",
    height: "7.93mm",
    borderRight: "1px solid",
  },
  tableRows: {
    display: "flex",
    borderBottom: "1px solid",
  },
  tie: {
    borderRight: "1px solid",
    width: "40%",
    textAlign: "center",
    height: "6.61mm",
    paddingTop: "1.32mm",
  },
  quantity: {
    borderRight: "1px solid",
    width: "30%",
    textAlign: "center",
    height: "6.61mm",
    paddingTop: "1.32mm",
  },
  total: {
    width: "30%",
    textAlign: "center",
    height: "6.61mm",
    paddingTop: "1.32mm",
  },
  totalAmount: {
    fontWeight: "bold",
    borderRight: "1px solid",
    borderBottom: "1px solid",
    paddingLeft: "25%",
    height: "7.93mm",
  },
  totalAmountFRC: {
    fontWeight: "bold",
    borderRight: "1px solid",
    borderBottom: "1px solid",
    height: "7.93mm",
    textAlign: "center",
  },
  bottomArea: {
    marginTop: "10.58mm",
    paddingLeft: "2.65mm",
    "& div": {
      marginBottom: "7.93mm",
    },
  },
  postNumber: {
    paddingLeft: "2.65mm",
    paddingBottom: "2.65mm",
  },
  backendData: {
    borderBottom: "1px solid",
  },
  moneyTypeDivider: {
    borderBottom: "1px solid",
    display: "flex",
    "& div": {
      borderRight: "1px solid",
      width: "50%",
      textAlign: "center",
      height: "7.93mm",
    },
  },
  totalMoneyTypeDivider: {
    borderBottom: "1px solid",
    display: "flex",
    "& div": {
      borderRight: "1px solid",
      width: "50%",
      height: "7.93mm",
      paddingLeft: "2.65mm",
      fontSize: "13px",
    },
  },

  amdTableContent: {
    display: "flex",
    "& div": {
      fontSize: "11px",
    },
  },
  amdTableRightArea: {
    width: "50%",
    borderRight: "1px solid",
  },
  amdTableLeftArea: {
    width: "50%",
    borderRight: "1px solid",
    borderBottom: "1px solid",
  },
  otherCurrencyTable: {
    "& div": {
      fontSize: "14px",
    },
  },
  mainBorders: {
    borderBottom: "1px dashed black",
    borderTop: "1px dashed black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    width: "210mm",
    height: "148mm",
    margin: "2.65mm auto 0",
    lineHeight: "normal",
    fontSize: "11px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    "& * p": {
      margin: "0",
    },
  },
  cp72mainBorders: {
    border: "1px solid transparent",
    width: "210mm",
    height: "148mm",
    // margin: '2.65mm auto 0',
    margin: "0 auto",
    lineHeight: "normal",
    fontSize: "11px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    "& * p": {
      margin: "0",
    },
  },

  contentArea: {
    borderBottom: "1px solid black",
    paddingBottom: "0.53mm",
    position: "relative",
  },

  cp72contentArea: {
    position: "relative",
    marginTop: "2mm",
  },

  rightTitle: {
    position: "absolute",
    right: "-18.52mm",
    bottom: "50%",
    transform: "rotate(-90deg)",
  },

  leftTitle: {
    position: "absolute",
    left: "-27%",
    bottom: "40%",
    transform: "rotate(-90deg)",
    textAlign: "center",
  },

  content: {
    display: "block",
    borderBottom: "1px solid black",
    borderLeft: "1px dotted black",
    width: "93%",
    margin: "0 auto",
  },

  leftAndRightContent: {
    display: "flex",
    marginLeft: "8.5mm!important",
    position: "relative",
  },

  fillAreaFlex: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.53mm",
    width: "100%",
  },

  fillAreaPostcode: {
    width: "70%",
    textAlign: "left",
  },

  fillAreaCity: {
    width: "30%",
    textAlign: "left",
  },

  titleArea: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "10px",
    paddingTop: "3.97mm",
    paddingLeft: "1.05mm",
  },

  cp72RightTitle: {
    position: "absolute",
    right: "-18%",
    top: "50%",
    fontSize: "16px",
    fontWeight: "bold",
    transform: "rotate(-90deg)",
  },

  nameBusinessArea: {
    display: "flex",
    textAlign: "left",
    borderRight: "1px solid",
  },

  imgArea: {
    width: "90%",
    height: "18.52mm",
  },
  barcode: {
    maxWidth: "100%",
    height: "14mm",
  },

  gridItem: {
    borderTop: "1px solid black",
    display: "flex",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    alignItems: "center",
    paddingTop: "0.79mm",
  },

  cp72GridItem: {
    borderTop: "1px solid black",
    display: "flex",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    alignItems: "center",
    paddingTop: "0.8mm",
    paddingBottom: "0.8mm",
  },

  customRef: {
    borderTop: "1px solid black",
    height: "9.26mm",
  },

  rightArea: {
    borderBottom: "1px solid black",
  },

  formToAreaParent: {
    display: "flex",
  },

  leftFormToArea: {
    borderRight: "1px solid black",
    marginLeft: "1.32mm",
  },

  barcodeArea: {
    display: "flex",
  },

  barcodeText: {
    paddingTop: "8.20mm",
    paddingBottom: "7.88mm",
  },

  rightAreaGridItem: {
    textAlign: "left",
    display: "flex",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    height: "6.40mm",
  },

  cp72RightAreaGridItem: {
    textAlign: "left",
    display: "flex",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    height: "7.20mm",
  },

  bottomEdge: {
    marginBottom: "0.93mm",
  },

  dataField: {
    paddingLeft: "2.65mm",
    fontSize: "8px",
  },

  leftAreaSecondSection: {
    textAlign: "left",
    borderTop: "1px solid black",
    paddingLeft: "8.75mm",
  },

  firstLign: {
    display: "flex",
    borderLeft: "1px solid black",
  },

  secondLign: {
    display: "flex",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },

  inputsArea: {
    display: "flex",
    paddingTop: "1.85mm",
  },

  inputsAreaSecond: {
    textAlign: "left",
  },

  inputsTextArea: {
    padding: "3.175mm 5.29mm 0 1.59mm",
  },

  checkboxArea: {
    display: "flex",
    alignItems: "center",
  },

  firstLignContent: {
    borderRight: "1px solid black",
    height: "23.81mm",
    marginLeft: "1.32mm",
  },

  secondLignContent: {
    borderRight: "1px solid black",
    marginLeft: "1.32mm",
    height: "11.11mm",
  },

  rigthAreaSecondSection: {
    textAlign: "left",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    paddingBottom: "33%",
    paddingLeft: "1.32mm",
  },

  thirdSectionLeftArea: {
    borderLeft: "1px solid black",
    paddingBottom: "14%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },

  contentSecondSection: {
    marginLeft: "7.8mm!important",
    display: "block",
    paddingLeft: "8.75mm",
  },

  thirdSectionLeftAndRightArea: {
    display: "flex",
  },

  numberOfParcels: {
    textAlign: "left",
  },

  rowStraight: {
    borderRight: "1px solid black",
    height: "2.65mm",
    background: "black",
    display: "inline-block",
    position: "absolute",
    marginLeft: "0.79mm",
  },

  rowStraight2: {
    borderRight: "1px solid black",
    height: "2.65mm",
    background: "black",
    display: "inline-block",
    position: "absolute",
    marginLeft: "3.44mm",
  },

  rowStraight3: {
    borderRight: "1px solid black",
    height: "2.65mm",
    background: "black",
    display: "inline-block",
    position: "absolute",
    marginLeft: "-1.32mm",
  },

  rowStraight4: {
    borderTop: "1px solid black",
    width: "2.65mm",
    background: "black",
    display: "inline-block",
    position: "absolute",
    left: "3.97mm",
  },
  rowStraight5: {
    borderRight: "1px solid black",
    height: "2.65mm",
    background: "black",
    display: "inline-block",
    position: "absolute",
    left: "3.97mm",
  },

  officeAndDateOfPosting: {
    borderRight: "1px solid black",
    height: "27.89mm",
    paddingLeft: "1.32mm",
  },

  thirdSectionRightArea: {
    display: "flex",
    borderRight: "1px solid black",
    textAlign: "left",
    borderBottom: "1px solid black",
  },

  numberOfParcelsFirstLign: {
    borderBottom: "1px solid black",
    paddingBottom: "3.97mm",
    display: "flex",
    paddingLeft: "1.32mm",
  },

  numberOfParcelsSecondLign: {
    borderBottom: "1px solid black",
    paddingBottom: "4.76mm",
    paddingLeft: "1.32mm",
  },

  numberOfParcelsThirdLign: {
    display: "flex",
    paddingLeft: "1.32mm",
  },

  grossWeight: {
    borderRight: "1px solid black",
    paddingBottom: "5.55mm",
  },
  fourthSectionLeftAndRightArea: {
    display: "flex",
    borderRight: "1px solid black",
  },

  fourthSectionLeftAreaFirstLign: {
    display: "flex",
    paddingLeft: "1.32mm",
    borderBottom: "1px solid black",
  },

  declarationAdressee: {
    borderRight: "1px solid black",
    padding: "3.44mm 0",
  },

  rowRight: {
    width: "3.97mm",
    height: "1px",
    background: "black",
    marginTop: "0.79mm",
    marginBottom: "0.53mm",
    borderBottom: "1px solid",
    marginLeft: "-1.32mm",
  },

  fourthSectionLeftArea: {
    textAlign: "left",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },

  certification: {
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
  },

  signatureAdressee: {
    paddingLeft: "1.32mm",
  },

  fourthSectionLeftAreaSecondLign: {
    display: "flex",
  },

  sendersSignature: {
    paddingLeft: "1.32mm",
  },

  sendersDays: {
    position: "relative",
    // paddingTop:'3.44mm',
    marginRight: "1.59mm",
    display: "flex",
    paddingTop: "5.29mm",
  },

  sendersTitle: {
    textAlign: "left",
  },

  sendersLeftArea: {
    paddingLeft: "1.32mm",
    paddingTop: "2.91mm",
    textAlign: "left",
  },

  sendersLeftRightArea: {
    display: "flex",
  },

  // sendersRightArea:{
  //     display:'flex'
  // },

  sendersRightBelowArea: {
    display: "flex",
    paddingLeft: "6.08mm",
    alignItems: "center",
  },

  sendersRightAboveArea: {
    display: "flex",
    paddingLeft: "2.65mm",
    marginTop: "-1.32mm",
  },

  bottomLign: {
    marginTop: "3.02mm",
    display: "block",
    height: "0.26mm",
    background: "transparent",
    width: "100%",
    border: "none",
    borderTop: "1px solid black",
    marginBottom: "0",
  },

  textAreaLeft: {
    textAlign: "left",
  },

  textAreaRight: {
    textAlign: "right",
  },

  cp72content: {
    width: "90%",
    margin: "0 auto",
  },

  ReceiptText: {
    paddingLeft: "1.32mm",
  },

  cp72SecondSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    borderBottom: "1px solid",
  },

  cp72HeaderArea: {
    textAlign: "left",
    paddingLeft: "1.32mm",
  },

  cp72SecondSectionFirstColumn: {
    // width: '58.75mm',
    width: "53.75mm",
    // marginLeft: '7.5mm!important',
    borderLeft: "1px solid black",
    paddingLeft: "1.32mm",
    textAlign: "left",
  },

  cp72HpLogo: {
    position: "absolute",
    right: "-9mm",
    top: "4px",
    width: "18%",
  },

  cp72SecondSectionSecondColumn: {
    width: "17.52mm",
    borderLeft: "1px solid black",
  },

  cp72SecondSectionThirdColumn: {
    width: "49.3%",
  },

  cp72SecondSectionThirdColumnItems: {
    display: "flex",
  },

  cp72SecondSectionRightArea: {
    display: "flex",
    width: "90.2mm",
  },

  cp72SecondSectionLeftArea: {
    display: "flex",
    width: "93.93mm",
  },

  cp72SecondSectionThirdColumnArea: {
    borderRight: "2px solid black",
    borderLeft: "2px solid black",
    width: "78.08mm",
    borderTop: "1px solid",
  },

  cp72SecondSectionThirdColumnAreaTitle: {
    borderBottom: "1px solid black",
    textAlign: "left",
    paddingLeft: "1.32mm",
  },

  cp72ThirdSection: {
    display: "flex",
    borderBottom: "1px solid black",
    marginLeft: "8.5mm!important",
    borderLeft: "1px solid black",
    height: "5.61mm",
  },

  cp72ThirdSectionItem: {
    height: "5.61mm",
    width: "53.8mm",
    borderRight: "1px solid black",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.32mm",
  },

  cp72ThirdSectionItem1: {
    height: "5.61mm",
    borderRight: "1px solid black",
    width: "18.4mm",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },

  cp72ThirdSectionItem2: {
    height: "5.61mm",
    borderRight: "1px solid black",
    width: "19.5mm",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },

  cp72ThirdSectionItem6: {
    height: "5.61mm",
    borderRight: "1px solid black",
    width: "17.5mm",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },

  cp72ThirdSectionItem3: {
    height: "5.61mm",
    width: "37.86mm",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },

  cp72ThirdSectionItem4: {
    height: "5.61mm",
    width: "39.751mm",
    borderRight: "2px solid black",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },

  checkboxItem: {
    borderRadius: "unset",
    height: "3.70mm",
    width: "3.70mm",
    WebkitAppearance: "none",
    WebkitBoxShadow: "0px 0px 0px 1px rgba(0,0,0,1)",
    MozBoxShadow: "0px 0px 0px 1px rgba(0,0,0,1)",
    boxShadow: "0px 0px 0px 1px rgba(0,0,0,1)",
    margin: "0",
    marginTop: "3.70mm",
    marginLeft: "-1.32mm",
  },

  cp72DataField: {
    paddingLeft: "1.32mm",
    fontSize: "8px",
  },

  cp72GridItemRightTextArea: {
    display: "flex",
  },

  cp72FourthSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
  },

  cp72FourthSectionFirstColumn: {
    width: "72mm",
    borderRight: "1px solid",
  },

  cp72FourthSectionFirstColumnFirstRow: {
    display: "flex",
    justifyContent: "space-between",
  },

  cp72FourthSectionFirstColumnSecondRow: {
    display: "flex",
  },

  cp72CheckboxArea: {
    display: "flex",
    position: "relative",
    paddingLeft: "3.97mm",
  },

  cp72FourthSectionFirstColumnServiceType: {
    textAlign: "left",
    paddingLeft: "1.32mm",
  },

  cp72FourthSectionSecondColumn: {
    width: "18.2mm",
    textAlign: "left",
  },

  cp72FourthSectionThirdColumn: {
    width: "73.53mm",
    borderRight: "1px solid",
    textAlign: "left",
  },

  square: {
    border: "1px solid",
    width: "3.70mm",
    height: "3.70mm",
    position: "absolute",
  },

  cp72FifthSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
  },

  cp72FifthSectionLeftArea: {
    width: "103.3mm",
    borderRight: "1px solid",
  },

  cp72FifthSectionRightArea: {
    width: "76.60mm",
    borderRight: "1px solid",
    display: "flex",
    borderBottom: "1px solid",
    height: "22mm",
  },

  cp72FifthSectionLeftAreaFirstRowFirstColumn: {
    textAlign: "left",
    width: "25.4mm",
  },

  cp72FifthSectionLeftAreaFirstRow: {
    display: "flex",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
  },

  cp72FifthSectionLeftAreaFirstRowSecondColumn: {
    paddingLeft: "2.65mm",
    textAlign: "left",
    width: "40.22mm",
  },

  cp72FifthSectionLeftAreaFirstRowThirdColumn: {
    paddingLeft: "2.65mm",
    textAlign: "left",
    width: "15.875mm",
  },

  cp72RowLeft: {
    borderBottom: "1px solid",
    width: "23.81mm",
  },

  cp72FifthSectionLeftAreaFirstRowFourthColumn: {
    paddingLeft: "6.61mm",
    paddingTop: "1.59mm",
  },

  cp72FifthSectionLeftAreaSecondRow: {
    textAlign: "left",
    paddingLeft: "1.32mm",
    borderLeft: "1px solid",
    borderBottom: "1px solid",
    height: "8.79mm",
  },

  cp72FifthSectionRightAreaFirstColumn: {
    width: "38.1mm",
    textAlign: "left",
  },

  cp72FifthSectionRightAreaSecondColumn: {
    width: "38.63mm",
    borderLeft: "1px solid",
    textAlign: "left",
  },

  cp72FifthSectionRightAreaSecondColumnFirstRow: {
    display: "flex",
  },

  cp72FifthSectionRightAreaSecondColumnSecondRow: {
    borderBottom: "1px solid",
    paddingBottom: "2.12mm",
  },

  cp72FifthSectionRightAreaSecondColumnThirdRow: {
    display: "flex",
  },

  cp72SixthSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    width: "179.65mm",
  },

  cp72SixthSectionLeftArea: {
    display: "flex",
    // width: '386.5px',
    borderLeft: "1px solid",
    borderBottom: "1px solid",
  },

  cp72SixthSectionLeftAreaColumns: {
    textAlign: "left",
    borderRight: "1px solid",
    width: "34.3mm",
  },

  cp72SixthSectionRightAreaFirstRow: {
    display: "flex",
    width: "77.13mm",
  },

  cp72SixthSectionRightAreaSecondRow: {
    display: "flex",
  },

  cp72SixthSectionRightArea: {
    borderBottom: "1px solid",
    borderRight: "1px solid",
    width: "76.80mm",
  },

  cp72SeventhSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    borderLeft: "1px solid",
    width: "179.9mm",
  },

  cp72SeventhSectionLeftArea: {
    display: "flex",
  },

  cp72SeventhSectionLeftAreaFirstColumn: {
    textAlign: "left",
    width: "60.325mm",
    borderRight: "1px solid",
  },

  cp72SeventhSectionLeftAreaSecondColumn: {
    textAlign: "left",
    width: "42.6mm",
    borderRight: "1px solid",
  },

  cp72SeventhSectionRightArea: {
    display: "flex",
  },

  cp72SeventhSectionRightAreaFirstColumn: {
    width: "19.84mm",
    textAlign: "left",
  },

  cp72SeventhSectionRightAreaSecondColumn: {
    width: "55.83mm",
    textAlign: "left",
    borderLeft: "1px solid",
    paddingLeft: "1.32mm",
  },

  cp72Charges: {
    borderLeft: "1px solid",
    width: "11.11mm",
    textAlign: "center",
    height: "30.5px",
  },

  cp72Logo: {
    position: "relative",
    "& > img ": {
      position: "absolute",
      width: "40%",
      top: "-13.23mm",
      right: "-46%",
    },
  },

  chequeMainBorders: {
    width: "70mm",
    margin: "0 auto",
    lineHeight: "normal",
    fontSize: "10px",
    paddingLeft: "10px",
    paddingRight: "20px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    "& * p": {
      marginBottom: "0.79mm",
      margin: "0",
      marginTop: "0.53mm",
    },
    fontWeight: "bold",
  },
  chequeTitle: {
    textAlign: "center",
    fontSize: "14px",
    borderTop: "1px dashed",
    borderBottom: "1px dashed",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  chequeFirstSection: {
    textAlign: "left",
    borderBottom: "1px dashed",
  },
  chequeSecondSection: {
    textAlign: "left",
    borderBottom: "1px dashed",
  },

  chequeThirdSection: {
    textAlign: "left",
    borderBottom: "1px dashed",
  },

  chequeWeight: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "7.93mm",
  },

  chequeEstimatedAmount: {
    display: "flex",
    justifyContent: "space-between",
  },

  chequeTextArea: {
    display: "flex",
  },

  chequeFourthSection: {
    textAlign: "left",
    paddingLeft: "2.65mm",
    borderBottom: "1px dashed",
  },

  chequeAmounts: {
    display: "flex",
    justifyContent: "space-between",
  },

  chequeDateArea: {
    display: "flex",
    borderBottom: "1px dashed",
    paddingBottom: "1.32mm",
  },

  companyNameArea: {
    display: "flex",
  },

  containerPageCN: {
    margin: "auto",
    height: "148mm",
    width: "210mm",
    display: "flex",
    justifyContent: "space-around",
    border: "solid 2px #000",
    lineHeight: "normal",
    fontSize: "11px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    "& * p": {
      margin: 0,
    },
  },
  containerHeadertLeft: {
    paddingBottom: "3.70mm",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
  },
  contentHeaderLeft: {
    display: "flex",
    justifyContent: "space-between",
  },
  HeaderTextLeft: {
    margin: "0",
    fontWeight: "bold",
    fontFamily: "Sans-serif",
    paddingTop: "3.97mm",
    paddingLeft: "3.97mm",
    fontSize: 20,
  },
  LogoContainer: {
    width: "50%",
    height: "30%",
  },
  HeaderLogoLeft: {
    width: "100%",
    height: "auto",
    paddingLeft: "3.97mm",
    paddingTop: "4.23mm",
  },
  haypostLogoSvgContainer: {
    width: "8mm",
    marginLeft: 14,
    marginTop: 12,
    display: "flex",
    alignItems: "center",
  },
  HeaderTextsLeft: {
    display: "flex",
    flexDirection: "column",
    marginRight: "2.65mm",
  },
  HeaderTitleft: {
    margin: "0",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    fontSize: "34px",
    paddingLeft: "3.44mm",
    paddingTop: "2.65mm",
  },
  line: {
    borderLeft: "solid 2px",
    height: "7.93mm",
  },
  HeaderSubtitleLeft: {
    fontSize: "15px",
    paddingLeft: "15.61mm",
    fontFamily: "Sans-serif",
  },
  HeaderDescLeft: {
    fontSize: "15px",
    fontFamily: "Sans-serif",
    paddingTop: "1.32mm",
  },
  containerSectionLeft: {
    display: "flex",
    padding: "0 3.44mm",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    justifyContent: "space-between",
  },
  containerSectionLeftGrid: {
    paddingTop: "2.12mm",
    paddingBottom: "2.12mm",
  },
  itemLeftGrid: {
    display: "flex",
    fontFamily: "Sans-serif",
    fontSize: "11px",
    alignItems: "center",
  },
  itemLeft: {
    width: "6.29mm",
    height: "6.29mm",
    border: "solid 3px gray",
  },
  titleLeft: {
    paddingLeft: "1.32mm",
  },
  itemText: {
    paddingLeft: "1.32mm",
  },
  text: {
    fontFamily: "Sans-serif",
    marginTop: "5.29mm",
    paddingLeft: "6.35mm",
    fontSize: "13px",
  },
  textBelow: {
    fontFamily: "Sans-serif",
    paddingLeft: "21.70mm",
    fontSize: "12px",
  },
  constinerAsideLeft: {
    display: "flex",
  },
  constinerAsideLeftTexts: {
    padding: "1.79mm 0",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    "& > p": {
      margin: "0",
      padding: "0 0 0 1.32mm",
      fontSize: "12px",
      fontFamily: "Sans-serif",
    },
  },
  constinerContentLeft: {
    display: "flex",
    wordBreak: "break-all",
  },
  lineLeft: {
    borderBottom: "none",
  },
  constinerContentLeftTexts: {
    display: "flex",
    alignItems: "center",
    height: "7.25mm",
    paddingLeft: "2px",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    "& > p": {
      fontSize: "10px",
      fontFamily: "Sans-serif",
      borderTop: "solid 1px",
    },
  },
  cn22EnclosureItemDetailsArea: {
    flexBasis: "16.666667%",
    flexGrow: "0",
    maxWidth: "16.666667%",
    "-webkit-box-flex": "0",
  },
  cn22EnclosureItemDescriptionArea: {
    flexBasis: "33.333333%",
    flexGrow: "0",
    maxWidth: "33.333333%",
    "-webkit-box-flex": "0",
  },
  barcodeContainer: {
    height: "148mm",
    width: "210mm",
  },
  cn22Barcode: {
    width: "52.92mm",
    height: "26.46mm",
  },
  cn22BarcodePage: {
    width: "52.92mm",
    height: "26.46mm",
    marginLeft: "13.23mm",
    marginTop: "13.23mm",
  },
  cn22Asset: {
    width: "75%",
    paddingLeft: "10.58mm",
    paddingTop: "1.06mm",
  },
  containerFooterLeft: {
    display: "flex",
    borderTop: "1px solid",
  },
  containerFooterLeftItems: {
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    textAlign: "left",
  },
  title: {
    margin: "0",
    padding: "1.32mm 0 1.32mm 1.32mm ",
    fontSize: "12px",
    fontFamily: "Sans-serif",
  },

  description: {
    margin: "0 1.32mm 1.32mm",
    paddingLeft: "1.32mm",
    fontSize: "15px",
    fontFamily: "Sans-serif",
    height: "7.14mm",
  },
  containerFooterLeftText: {
    paddingBottom: "1.32mm",
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    paddingLeft: "1.85mm",
    "& > p": {
      margin: "0",
      fontSize: "14px",
      paddingLeft: "1.32mm",
      paddingTop: "1.32mm",
      paddingBottom: "2.12mm",
      fontFamily: "Sans-serif",
    },
  },
  footerLeft: {
    display: "flex",
    borderRight: "solid 2px",
    paddingBottom: "2.12mm",
  },
  footerLeftItem: {
    "& > p": {
      fontFamily: "Sans-serif",
      fontWeight: "bold",
      fontSize: "12px",
      margin: "0",
      paddingTop: "2.65mm",
      paddingLeft: "1.32mm",
    },
  },
  footerRightItem: {
    "& > p": {
      fontSize: "12px",
      margin: "0",
      paddingTop: "2.65mm",
      paddingLeft: "5.29mm",
      paddingBottom: "2.38mm",
    },
  },
  containerHeaderRight: {
    display: "flex",
    justifyContent: "center",
    height: "31.64mm",
  },
  containerLogoRight: {
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    "& > img": {
      width: "100%",
      padding: "3.97mm 1.32mm",
    },
  },
  containerPostExpress: {
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    textAlign: "center",
    "& > img": {
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "2mm",
    },
  },
  containerTextRight: {
    borderRight: "solid 2px",
    borderBottom: "solid 2px",
    padding: "4mm 0",
    textAlign: "center",
    "& > p": {
      fontSize: "56px",
      fontWeight: "bold",
      fontFamily: "Sans-serif",
    },
  },
  containerTextsRight: {
    borderBottom: "solid 2px",
    "& > p": {
      textAlign: "center",
      fontFamily: "Sans-serif",
      fontWeight: "bold",
      fontSize: "15px",
      margin: "0",
    },
  },
  phoneNumber: {
    padding: "2.65mm 2.12mm",
    textAlign: "center",
  },
  containerRightForm: {
    display: "flex",
    height: "34.66mm",
    borderBottom: "solid 2px",
    justifyContent: "center",
  },
  containerRightTextFrom: {
    borderRight: "solid 2px",
  },
  textRightFrom: {
    margin: "19.84mm 0!important",
    fontWeight: "bold",
    fontSize: "24px",
    fontFamily: "Sans-serif",
    transform: "rotate(-90deg)",
  },
  containerRightTitles: {
    textAlign: "left",
    "& > p": {
      marginTop: "3.70mm",
      marginBottom: "0",
      marginLeft: "2.65mm",
      fontSize: "14px",
      fontFamily: "Sans-serif",
    },
  },
  itemUser: {
    margin: "0 1.32mm",
    display: "flex",
    "& > p": {
      margin: "1.32mm",
      fontSize: "11px",
      height: "2.65mm",
      padding: "2.12mm 0",
      fontFamily: "Sans-serif",
    },
  },
  textRightTo: {
    margin: "10.58mm 0!important",
    fontWeight: "bold",
    fontSize: "24px",
    fontFamily: "Sans-serif",
    transform: "rotate(-90deg)",
  },
  containerRightIcons: {
    display: "flex",
    borderBottom: "solid 2px",
    paddingBottom: "0.53mm",
  },
  containerIcon: {
    "& > svg": {
      width: "60%",
      padding: "3.97mm 0 0 21.17mm",
    },
  },
  footerRightKg: {
    width: "32.78mm",
    height: "4.76mm",
    border: "solid 1px",
    marginTop: "6.35mm",
    marginLeft: "5.29mm",
    marginBottom: "1.85mm",
    "& > p": {
      margin: "0",
      paddingLeft: "10mm",
      paddingBottom: "0.79mm",
    },
  },
  actSection: {
    width: "297mm",
    height: "210mm",
    margin: "0 auto",
  },
  actHeaderFirstRow: {
    paddingLeft: "26%",
  },
  actHeaderTitle: {
    textAlign: "center",
  },
  actHeaderSecondRow: {
    paddingLeft: "10%",
  },

  actHeaderThirdRow: {
    paddingLeft: "10%",
    "& > p": {
      margin: "0",
    },
  },

  actTableTitle: {
    display: "flex",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    "& p": {
      fontSize: "13px",
    },
  },
  actTableData: {
    height: "12.17mm",
    display: "flex",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    "& p": {
      fontSize: "13px",
    },
  },
  actTableFirstColumn: {
    borderRight: "1px solid",
    width: "9%",
    paddingLeft: "1.32mm",
  },
  actTableSecondColumn: {
    borderRight: "1px solid",
    width: "10%",
    paddingLeft: "1.32mm",
  },

  actTableThirdColumn: {
    borderRight: "1px solid",
    width: "15%",
    paddingLeft: "1.32mm",
  },

  actTableFourthColumn: {
    borderRight: "1px solid",
    width: "7%",
    paddingLeft: "1.32mm",
  },
  toCapitalize: {
    textTransform: "capitalize",
  },
  actTableFifthColumn: {
    borderRight: "1px solid",
    width: "8%",
    paddingLeft: "1.32mm",
  },
  actTableSixthColumn: {
    borderRight: "1px solid",
    width: "16%",
    paddingLeft: "1.32mm",
  },
  actTableSeventhColumn: {
    borderRight: "1px solid",
    width: "13%",
    paddingLeft: "1.32mm",
  },
  actBottomArea: {
    paddingLeft: "10%",
    paddingTop: "5%",
  },
  lettersSize16: {
    fontSize: "16px",
  },
  lettersSize14: {
    fontSize: "14px",
  },
  lettersSize13: {
    fontSize: "13px",
  },
  lettersSize12: {
    fontSize: "12px",
  },
  lettersSize11: {
    fontSize: "11px",
  },
  lettersSize10: {
    fontSize: "10px",
  },
  lettersSize9: {
    fontSize: "9px",
  },
  lettersSize8: {
    fontSize: "8px",
  },
  lettersSize7: {
    fontSize: "7px",
  },

  lettersSize6: {
    fontSize: "6px",
  },

  lettersSize5: {
    fontSize: "1.32mm",
  },

  squareSizes1: {
    top: "1.06mm",
    left: "-0.26mm",
  },
  squareSizes2: {
    top: "1.06mm",
    left: "1.06mm",
  },
  squareSizes3: {
    top: "0px",
    left: "-0.26mm",
    height: "16px",
  },
  squareSizes4: {
    height: "3.97mm",
    left: "-0.26mm",
    top: "1.06mm",
  },
  squareSizes5: {
    top: "-0.26mm",
    height: "3.97mm",
  },
  squareSizes6: {
    top: "0.53mm",
    height: "3.97mm",
  },
  squareSizes7: {
    top: "0.79mm",
  },
  squareSizes8: {
    top: "-0.26mm",
  },
  squareSizes9: {
    top: "-0.26mm",
    left: "-0.26mm",
  },
  squareSizes12: {
    top: "0.79mm",
    left: "-0.53mm",
  },
  squareSizes13: {
    top: "0",
    left: "2.65mm",
  },
  squareSizes14: {
    top: "0",
    left: "5.55mm",
  },
  squareSizes15: {
    top: "0.53mm",
    left: "11.38mm",
  },

  edgingRight: {
    borderRight: "1px solid",
  },
  edgingLeft: {
    borderLeft: "1px solid",
  },
  edgingTop: {
    borderTop: "1px solid",
  },
  edgingBottom: {
    borderBottom: "1px solid",
  },
  cargoContentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  cargoContent: {
    borderTop: "1px solid",
    width: "95%",
    marginTop: 100,
    margin: "0 auto",
  },
  cargoContent2: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 100,
    width: "100%",
  },
  marginRightParagraph: {
    paddingRight: 10,
  },
  cargoFirstSection: {
    display: "flex",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
  },
  cargoFirstRowFirstColumn: {
    width: "31%",
    borderRight: "1px solid",
    fontSize: "13px",
    paddingLeft: "2.65mm",
  },
  cargoFirstRowSecondColumn: {
    width: "38%",
    borderRight: "1px solid",
    fontSize: "13px",
    lineHeight: "6.08mm",
    paddingLeft: "2.65mm",
  },
  cargoSecondSection: {
    lineHeight: "7.14mm",
    padding: "2.65mm 6.61mm 2.65mm",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    borderLeft: "1px solid",
  },
  cargoNumber: {
    display: "flex",
    justifyContent: "space-between",
  },
  cargoThirdSection: {
    padding: "1.32mm 6.61mm 7.93mm",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    borderLeft: "1px solid",
  },
  cargoThirdSectionFirstRow: {
    display: "flex",
    paddingBottom: "2.12mm",
  },
  cargoBackendData: {
    paddingLeft: "2.65mm",
  },
  cargoThirdSectionsenderReceiverArea: {
    display: "flex",
    paddingTop: "3.97mm",
  },
  cargoTable: {
    display: "flex",
    borderBottom: "1px solid",
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      // background: 'blue',
    },
  },
  cargoOrderNumber: {
    width: "10%",
    textAlign: "center",
    borderRight: "1px solid",
  },
  cargoProductCode: {
    width: "25%",
    textAlign: "center",
    borderRight: "1px solid",
  },
  cargoProductName: {
    width: "50%",
    textAlign: "center",
    borderRight: "1px solid",
  },
  cargoProductCount: {
    width: "15%",
    textAlign: "center",
    borderRight: "1px solid",
  },
  cargoButtonArea: {
    textAlign: "center",
    paddingTop: "3.97mm",
    "& button": {
      background: "transparent",
      border: "1px solid",
      padding: "3.97mm 5.29mm",
      cursor: "pointer",
    },
  },

  //////////////////Cn07///////////////////

  cn07Wrapper: {
    width: "210mm",
    height: "107mm",
    margin: "0 auto",
  },
  cn07ContentWrapper: {
    width: "90%",
    margin: "0 auto",
    wordBreak: "break-all",
  },

  //////////////// Form 22 //////////////////
  form22Wrapper: {
    width: "200mm",
    height: "100mm",
    margin: "0 auto",
    fontSize: 12,
  },
  form22Data: {
    marginLeft: "5px",
    borderBottom: "1px solid",
  },

  /////////////// EMS /////////////////
  emsMainBorders: {
    border: "1px solid transparent",
    width: "210mm",
    height: "148mm",
    margin: "0 auto 0",
    lineHeight: "normal",
    fontSize: "11px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    "& * p": {
      margin: "0",
    },
  },
  emsContentArea: {
    width: "90%",
    margin: "0 auto",
    position: "relative",
  },
  logoArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  emsEnclosuresArea: {
    "& div": {
      paddingLeft: "5px",
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
    },
  },
  cpInstructionsLeftArea: {
    paddingBottom: "8px",
    "& div": {
      paddingBottom: "3px",
    },
  },
  cpInstructionsRightArea: {
    paddingBottom: "8px",
    "& div": {
      paddingBottom: "13px",
    },
  },

  ///////////////////////// CN23 /////////////////////////

  cn23MainBorders: {
    borderBottom: "1px dashed transparent",
    borderTop: "1px dashed transparent",
    borderRight: "1px solid transparent",
    borderLeft: "1px solid transparent",
    width: "204mm",
    height: "148mm",
    // margin: '2.65mm auto 0',
    margin: "0 auto",
    lineHeight: "normal",
    fontSize: "11px",
    fontFamily: "sans-serif",
    letterSpacing: "normal",
    maxWidth: "100%",
    "& * p": {
      margin: "0",
    },
  },
  cn23RightTitle: {
    position: "absolute",
    right: "-25%",
    top: "50%",
    fontSize: "16px",
    fontWeight: "bold",
    transform: "rotate(-90deg)",
    width: "424px",
  },
  cn23GridItem: {
    borderTop: "1px solid black",
    display: "flex",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    alignItems: "center",
    paddingTop: "1mm",
  },
  cn23SecondSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  cn23SecondSectionSecondColumn: {
    width: "20%",
    borderLeft: "1px solid black",
  },
  cn23SecondSectionLeftArea: {
    display: "flex",
    width: "87.1mm",
  },
  cn23SecondSectionRightArea: {
    display: "flex",
    width: "87mm",
  },
  cn23ThirdSectionItem6: {
    width: "19%",
    borderRight: "1px solid",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.32mm",
  },
  cn23ThirdSectionItem3: {
    width: "38.8%",
    borderRight: "1px solid",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.32mm",
  },
  cn23ThirdSectionItem4: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.32mm",
  },
  cn23SecondSectionThirdColumn: {
    width: "49%",
  },
  cn23SecondSectionFirstColumn: {
    width: "60%",
    borderLeft: "1px solid black",
    paddingLeft: "1.32mm",
    textAlign: "left",
  },
  cn23SecondSectionThirdColumnArea: {
    borderLeft: "1px solid black",
    width: "70.17mm",
    marginRight: "1.65mm",
  },
  cn23ThirdSection: {
    display: "flex",
    borderBottom: "1px solid black",
    marginLeft: "8.5mm!important",
    borderLeft: "1px solid black",
    height: "5.61mm",
  },
  cn23ThirdSectionItem: {
    height: "5.61mm",
    width: "52.2mm",
    borderRight: "1px solid black",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },
  cn23ThirdSectionItem1: {
    height: "5.61mm",
    borderRight: "1px solid black",
    width: "17.48mm",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },
  cn23ThirdSectionItem2: {
    height: "5.61mm",
    width: "17.1mm",
    paddingLeft: "1.32mm",
    display: "flex",
    alignItems: "center",
  },
  cn23FourthSectionFirstColumn: {
    width: "70.21mm",
    borderRight: "1px solid",
  },
  cn23FourthSectionSecondColumn: {
    width: "17.19mm",
    textAlign: "left",
  },

  cn23FourthSectionThirdColumn: {
    width: "77.26mm",
    textAlign: "left",
  },
  cn23FifthSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
  },
  cn23FifthSectionLeftArea: {
    width: "103.8mm",
    borderRight: "1px solid",
    height: "21.17mm",
  },
  cn23FifthSectionLeftAreaFirstRowFourthColumn: {
    paddingLeft: "6.08mm",
    paddingTop: "1.59mm",
  },
  cn23FifthSectionRightAreaFirstColumn: {
    width: "31.98mm",
    textAlign: "left",
  },
  cn23SixthSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    width: "174.7mm",
    borderRight: "1px solid",
  },
  cn23SixthSectionLeftArea: {
    display: "flex",
    borderLeft: "1px solid",
    borderBottom: "1px solid",
  },
  cn23SixthSectionLeftAreaColumns: {
    textAlign: "left",
    borderRight: "1px solid",
    width: "34.5mm",
  },
  cn23SixthSectionRightArea: {
    borderBottom: "1px solid",
    paddingBottom: "1.32mm",
    width: "70.7mm",
  },
  cn23SeventhSection: {
    display: "flex",
    marginLeft: "8.5mm!important",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    borderLeft: "1px solid",
    width: "174.7mm",
  },
  cn23SeventhSectionLeftAreaFirstColumn: {
    textAlign: "left",
    width: "60.88mm",
    borderRight: "1px solid",
  },
  cn23SeventhSectionLeftAreaSecondColumn: {
    textAlign: "left",
    width: "42.6mm",
    borderRight: "1px solid",
  },
  cn23Charges: {
    borderLeft: "1px solid",
    width: "11.11mm",
    textAlign: "center",
    height: "7.28mm",
  },

  ////////////Act///////////////
  actMainBorders: {
    width: "210mm",
    height: "296.5mm",
  },
  porcnakan: {
    "&:after": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "50%",
      background: "black",
      display: "block",
      width: "1px",
      height: "100%",
    },
  },
  listItemParent: {
    borderRight: "1px solid",
    position: "relative",
  },
  actTableContainer: {
    border: "1px solid",
    "& .MuiTableCell-root": {
      padding: "0",
    },
    "& .MuiTableCell-head": {
      lineHeight: "20px",
    },
  },

  /////////////////// Reports ///////////////////
  headFirstCell: {
    width: "8%",
    borderRight: "1px solid",
    fontSize: "10.5px",
    paddingLeft: "2px!important",
  },
  headSecondCell: {
    width: "20%",
    borderRight: "1px solid",
    fontSize: "10.5px",
    paddingLeft: "2px!important",
  },
  headCell: {
    borderRight: "1px solid",
    fontSize: "10.5px",
    paddingLeft: "2px!important",
  },
  headSeventhCell: {
    width: "30%",
    borderRight: "1px solid",
    fontSize: "10.5px",
    paddingLeft: "2px!important",
  },
  bodyCell: {
    textAlign: "right",
    paddingRight: "5px!important",
  },
  secondTableCellBordered: {
    borderRight: "1px solid",
  },
  secondTableCellPosition: {
    paddingLeft: "2px!important",
  },
  reportSecondTable: {
    "& th": {
      fontSize: "11px",
    },
    "& td": {
      fontSize: "11px",
    },
  },
  reportBottomArea: {
    "& span": {
      display: "inline-block",
      borderBottom: "1px solid",
    },
  },
  dividerColor: {
    background: "black",
  },

  /////////////// Reestr //////////////
  reestrTitleArea: {
    display: "flex",
    alignItems: "center",
  },
  reestrTitle: {
    width: "55%",
    display: "flex",
    justifyContent: "end",
  },

  reestrTitleBarcode: {
    width: "200px",
    height: "70px",
    marginLeft: "60px",
    marginTop: "-120px",
  },
  reestrTableCellStyles: {
    borderRight: "1px solid",
    borderBottom: "1px solid",
  },
  reestrWrapper: {
    "& .MuiTableCell-root": {
      fontSize: "12px!important",
    },
  },
};

const printStyles = makeStyles(stylesObj);
export { printStyles };
