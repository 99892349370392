import { Grid } from "@material-ui/core";
import { RefObject, forwardRef } from "react";
import Cn23 from "./cn23";
import CP72Instructions from "./components/CP72Instructions";
import Cp72 from "./cp72";

const CP72AndCN23 = forwardRef((props, ref) => (
  <Grid ref={ref as RefObject<HTMLDivElement>}>
    <Cp72 printData={props.printData} />
    <Cp72
      rightText="ՀԱՅՓՈՍՏԻ ՕՐԻՆԱԿ/COPY OF HAYPOST"
      printData={props.printData}
    />
    <Cn23
      rightText="ՄԱՔՍԱՅԻՆ ՀԱՅՏԱՐԱՐԱԳԻՐ CN23 CUSTOMS DECLERATION"
      printData={props.printData}
    />
    <Cn23
      rightText="ՄԱՔՍԱՅԻՆ ՀԱՅՏԱՐԱՐԱԳԻՐ CN23 CUSTOMS DECLERATION"
      printData={props.printData}
    />
    <Cn23
      rightText="ՈւՂՂԱՐԿՈՂԻ ՕՐԻՆԱԿ/SENDER'S COPY"
      printData={props.printData}
    />
    <CP72Instructions />
  </Grid>
));

export default CP72AndCN23;
