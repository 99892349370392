import { Box, makeStyles } from "@material-ui/core";
import moment from "moment";
import { forwardRef, Fragment } from "react";
import BarcodeToPrint from "src/components/BarcodeToPrint";

const useStyles = makeStyles((theme) => ({
  barcodeNumbers: {
    "& text": {
      font: "18px monospace!important",
    },
  },
}));

interface ICodPortrait {
  width: number;
  activeOrder: any;
}

const CodPortrait = forwardRef((props: ICodPortrait, ref) => {
  const { activeOrder } = props;
  const classes = useStyles();

  const { provinceState, cityVillage, street, building, apartment } =
    activeOrder.returnSender;
  const {
    provinceState: recipientProvinceState,
    cityVillage: recipientCityVillage,
    street: recipientStreet,
    building: recipientBuilding,
    apartment: recipientApartment,
    country: recipientCountry,
  } = activeOrder.recipient;

  return (
    <Box width="48mm" ml={2} fontSize="10px" my={1}>
      {activeOrder && (
        <Fragment>
          {activeOrder.returnSender?.phone ? (
            <Box>Հեռ : {activeOrder.returnSender?.phone}</Box>
          ) : null}
          {activeOrder.organization ? (
            <Box>{activeOrder.organization}</Box>
          ) : null}
          {activeOrder.note1 ? <Box>{activeOrder.note1}</Box> : null}
          {activeOrder.returnSender?.provinceState ? (
            <Box>
              Հասցե :{" "}
              {"Armenia" +
                " " +
                provinceState +
                " " +
                cityVillage +
                " " +
                street +
                " " +
                building +
                " " +
                (apartment ? apartment : "")}
            </Box>
          ) : null}
          {activeOrder.returnSender?.postalCode ? (
            <Box>Դասիչ : {activeOrder.returnSender?.postalCode}</Box>
          ) : null}
          <Box>Ամսաթիվ : {moment(new Date()).format("DD.MM.YYYY")}</Box>
          {activeOrder.recipient.nameSurname ? (
            <Box>{activeOrder.recipient.nameSurname}</Box>
          ) : (
            <Box>{activeOrder.recipient.legalName}</Box>
          )}
          {activeOrder.recipient.phone ? (
            <Box>Հեռ : {activeOrder.recipient.phone}</Box>
          ) : null}
          {activeOrder.recipient.provinceState ? (
            <Box>
              Հասցե :{" "}
              {activeOrder?.country?.name +
                " " +
                recipientProvinceState +
                " " +
                recipientCityVillage +
                " " +
                recipientStreet +
                " " +
                recipientBuilding +
                " " +
                (recipientApartment ? recipientApartment : "")}
            </Box>
          ) : null}
          {activeOrder.recipient?.postalCode ? (
            <Box>Դասիչ : {activeOrder.recipient?.postalCode}</Box>
          ) : null}
          {activeOrder.note2 ? <Box>{activeOrder.note2}</Box> : null}
          {activeOrder.cod ? <Box>Վերադիր վճար : {activeOrder.cod}</Box> : null}
          {activeOrder.trackingNumber ? (
            <Box
              marginLeft="-20px"
              fontSize="10px"
              textAlign="center"
              className={classes.barcodeNumbers}
            >
              <BarcodeToPrint
                barcode={activeOrder.trackingNumber}
                barcodeWidth={0.9}
              />
            </Box>
          ) : null}
        </Fragment>
      )}
    </Box>
  );
});

export default CodPortrait;
