import { Fragment } from "react";
import { Box } from "@material-ui/core";

const EmsEnclosuresArea = (): JSX.Element => (
  <Fragment>
    <Box
      display="flex"
      fontSize="9px"
      borderLeft="1px solid"
      borderBottom="1px solid"
    >
      <Box
        position="relative"
        padding="5px 5px"
        borderRight="1px solid"
        width="28.1%"
      >
        <Box>
          <p>Detailed description of contents</p>
          <p>Ներդրվածքի մանրակրկիտ նկարագրություն</p>
        </Box>
        <Box position="absolute" top="0" right="0">
          18
        </Box>
      </Box>
      <Box
        position="relative"
        padding="5px 5px"
        borderRight="1px solid"
        width="11%"
      >
        <Box>
          <p>Quantity</p>
          <p>Քանակ</p>
        </Box>
        <Box position="absolute" top="0" right="0">
          19
        </Box>
      </Box>
      <Box
        position="relative"
        padding="5px 5px"
        borderRight="1px solid"
        width="11%"
      >
        <Box>
          <p>Net wight(in kg)</p>
          <p>Զտաքաշ(կգ-ով)</p>
        </Box>
        <Box position="absolute" top="0" right="0">
          20
        </Box>
      </Box>
      <Box
        position="relative"
        padding="5px 5px"
        borderRight="1px solid"
        width="15.5%"
      >
        <Box>
          <p>Value</p>
          <p>Արժեք</p>
        </Box>
        <Box position="absolute" top="0" right="0">
          22
        </Box>
      </Box>
      <Box width="34.6%" fontSize="8px" borderRight="1px solid">
        <Box borderBottom="1px solid">
          For comercial items only Միայն կոմերցիոն առաքանիների համար
        </Box>
        <Box display="flex">
          <Box
            position="relative"
            borderRight="1px solid"
            width="49.5%"
            height="7.6mm"
          >
            <Box paddingLeft="5px">
              <p>HS tariff number</p>
              <p>ՆՀ սակագնային թիվ</p>
            </Box>
            <Box position="absolute" top="0" right="0">
              24
            </Box>
          </Box>
          <Box position="relative" width="50%">
            <Box paddingLeft="5px">
              <p>Country of origin of goods</p>
              <p>Ապրանքների ծագման երկիր</p>
            </Box>
            <Box position="absolute" top="0" right="0">
              25
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Fragment>
);

export default EmsEnclosuresArea;
