import { useState, Fragment } from "react";
import {
  Grid,
  Box,
  Typography,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import InputField from "shared/ui/Input";
import ButtonLoader from "components/ButtonLoader";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { emailRegex } from "src/helpers/validators";
import { registerOrganizationAdmin } from "src/store/slicers/users";
import { useTranslation } from "react-i18next";

const defaultRules = (maxLength: number, t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
    maxLength: {
      value: maxLength,
      message: `${t("maximum")} ${maxLength} ${t("character")}`,
    },
  };
};

const defaultValues = {
  phone: "",
  userEmail: "",
  email: "",
  hvhh: "",
  name: "",
};

const EditOrganizationForm = ({ open, onClose, fetchData, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const submitCallback = async (formData) => {
    setLoading(true);

    const { meta } = await dispatch(
      registerOrganizationAdmin({ id: data?.id, formData })
    );
    if (meta.requestStatus !== "fulfilled") {
      setLoading(false);
      return;
    }

    toast.success(t("company_admin_is_changed"));

    fetchData();
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h4">{t("change_admin_info")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name="name"
                  label={t("name")}
                  control={control}
                  rules={defaultRules(50, t)}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name="surname"
                  label={t("surname")}
                  control={control}
                  rules={defaultRules(50, t)}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name="email"
                  label={t("email")}
                  control={control}
                  rules={{
                    ...defaultRules(50, t),
                    pattern: {
                      value: emailRegex,
                      message: t("wrong_email"),
                    },
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name="phone"
                  label={t("phone_number")}
                  control={control}
                  rules={defaultRules(50, t)}
                  errors={errors}
                  onKeyPress={onlyNumericalHandler}
                  onPaste={onlyNumericalPasteHandler}
                />
              </Grid>
              <Grid item xs={6}></Grid>
            </Fragment>

            <Grid item xs={12}>
              <Box display="flex" py={1.6}>
                <ButtonLoader
                  onClick={handleSubmit(submitCallback)}
                  isLoading={loading}
                >
                  {t("save")}
                </ButtonLoader>
                <Box ml={2}>
                  <ButtonLoader
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                  >
                    {t("cancel")}
                  </ButtonLoader>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditOrganizationForm;
