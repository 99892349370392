import { useCallback, useState } from "react";
import {
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { nanoid } from "@reduxjs/toolkit";

const styles = makeStyles({
  dropdownActionLoader: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    display: "flex",
    justifyContent: "center",
    background: "#fff",
    pointerEvents: "none",
  },
});

const ActionsDropdown = ({ list, row, rowIndex }) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {list.map(
          (item) =>
            item && (
              <MenuItem
                key={nanoid(10)}
                onClick={() => {
                  handleClose();
                  item.action(row);
                }}
                disabled={!!item.isDisabled?.(row)}
              >
                <ListItemIcon>
                  {typeof item.icon === "function" ? item.icon(row) : item.icon}
                </ListItemIcon>
                <ListItemText>
                  {typeof item.text === "function" ? item.text(row) : item.text}
                </ListItemText>
                {item.isLoading && (
                  <div className={classes.dropdownActionLoader}>
                    <CircularProgress />
                  </div>
                )}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};

export default ActionsDropdown;
