import { Action, isRejected } from "@reduxjs/toolkit";
import i18next from "i18next";
import { toast } from "react-hot-toast";

interface IActionErrors extends Action {
  error: {
    data: {
      key: string;
    };
    status: number;
  };
}

const errorHandling =
  () =>
  (next: (arg: IActionErrors) => void) =>
  (action: IActionErrors): void => {
    const t = i18next.t.bind(i18next);
    if (isRejected(action)) {
      toast.error(t(action?.error?.data?.key));
    }

    return next(action);
  };

export default errorHandling;
