import {
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  Timeline,
} from "@material-ui/lab";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatDateWithTimezone } from "src/helpers";

const useStyles = makeStyles({
  activeItem: {
    border: "2px solid green",
    backgroundColor: "#b4d7b4",
  },
  activeItemBg: {
    backgroundColor: "green",
  },
  timeLine: {
    "&::before": {
      flex: "unset",
    },
  },
});

const postmanLocationTypeId = 3;

const OrderLocation = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Timeline>
      {data?.locations?.map((item, index) => {
        const isActive = index === 0;
        return (
          <TimelineItem key={index} className={classes.timeLine}>
            <TimelineSeparator>
              <TimelineConnector
                classes={{ root: isActive ? classes.activeItemBg : "" }}
              />
              <TimelineDot
                classes={{ root: isActive ? classes.activeItemBg : "" }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box
                component={Paper}
                elevation={3}
                p={2}
                classes={{ root: isActive ? classes.activeItem : "" }}
              >
                <Typography>
                  {t("location")}՝{" "}
                  {item.locationTypeId !== postmanLocationTypeId
                    ? item?.locationName
                    : t("postman")}
                </Typography>
                <Typography>
                  {t("is_created")}՝ {formatDateWithTimezone(item.createDate)}
                </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default OrderLocation;
