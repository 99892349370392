import { forwardRef, Fragment, RefObject, useMemo } from "react";

import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@material-ui/core";
import { selectUserData } from "store/slicers/users";
import { amountFormatter } from "src/helpers";
import { ETypeList } from "src/views/Orders/data/constants";
import { addHours, format } from "date-fns";

const boxFlex = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const dividerAdditional = {
  borderBottom: "1px dashed",
  borderTop: "1px dashed",
  marginTop: "6px",
  marginBottom: "6px",
};

const Cheque = forwardRef(({ activeOrder, coreData, optionData }, ref) => {
  return (
    <Grid
      ref={ref as RefObject<HTMLDivElement>}
      style={{
        height: `${optionData?.height || 0}mm`,
        flexDirection: "column",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <Box
        style={{
          marginLeft: 40,
          marginRight: 40,
          fontFamily: "Arial AMU,Sylfaen",
        }}
      >
        <Box>
          <Box style={dividerAdditional} />
          <Typography
            style={{ fontSize: 16, textAlign: "center", marginBottom: 0 }}
          >
            Ստացական
          </Typography>
          <Box style={dividerAdditional} />
        </Box>

        <Box style={boxFlex}>
          <Typography
            style={{
              fontFamily: "Arial AMU,Sylfaen",
              fontSize: 12,
              marginBottom: 0,
            }}
          >
            Հայփոստ ՓԲԸ
          </Typography>
          <Typography style={{ fontSize: 12, marginBottom: 0 }}>
            00011937
          </Typography>
        </Box>

        <Box style={boxFlex}>
          <Typography
            style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}
          ></Typography>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            Ձև 3
          </Typography>
        </Box>

        {![ETypeList.SIMPLE].includes(activeOrder?.category?.id) && (
          <Box style={boxFlex}>
            <Typography style={{ fontSize: 12, marginBottom: 0 }}>
              Կոդը:
            </Typography>
            <Typography style={{ fontSize: 12, marginBottom: 0 }}>
              {activeOrder.trackingNumber}
            </Typography>
          </Box>
        )}

        <Box style={boxFlex}>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            Առաքանի N:
          </Typography>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            {activeOrder.id}
          </Typography>
        </Box>
        <Box style={dividerAdditional} />
        <Box>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            {activeOrder?.category?.name} / Անկանխիկ
          </Typography>
        </Box>

        <Box style={dividerAdditional} />

        <Box>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            Ուղարկող
          </Typography>
          <Box ml={1}>
            <Typography
              style={{
                fontFamily: "Arial AMU,Sylfaen",
                fontSize: 12,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {activeOrder?.returnSender?.nameSurname
                ? activeOrder?.returnSender?.nameSurname
                : activeOrder?.returnSender?.legalName}
            </Typography>
            <Typography
              style={{
                fontFamily: "Arial AMU,Sylfaen",
                fontSize: 12,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {activeOrder?.returnSender?.address}
            </Typography>
          </Box>
        </Box>

        <Box style={dividerAdditional} />

        <Box>
          <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
            Ստացող
          </Typography>

          <Box ml={1}>
            <Typography
              style={{
                fontFamily: "Arial AMU,Sylfaen",
                fontSize: 12,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {activeOrder?.recipient?.nameSurname}
            </Typography>

            <Typography
              style={{
                fontFamily: "Arial AMU,Sylfaen",
                fontSize: 12,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {`${activeOrder?.country?.name}, Մարզ ${
                activeOrder?.recipient?.provinceState
              } Ք. / Գ. 
              ${activeOrder?.recipient?.cityVillage} Փ. ${
                activeOrder?.recipient?.street
              },
              Շ. ${activeOrder?.recipient?.building},  ${
                activeOrder?.recipient?.postalCode
                  ? `ՓԴ. ${activeOrder?.recipient?.postalCode}`
                  : ""
              },
               ${
                 activeOrder?.recipient?.apartment
                   ? `բն. ${activeOrder?.recipient?.apartment}`
                   : ""
               }`}
            </Typography>
          </Box>
        </Box>

        <Box style={dividerAdditional} />

        <Box>
          <Box style={boxFlex}>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              Քաշը
            </Typography>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              {activeOrder.weight} գր
            </Typography>
          </Box>

          {/* {printData.priceDetails.valuedAmount !== 0 ? (
            <Box style={boxFlex}>
              <Typography
                style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}
              >
                Գնահատ․ Գումարը
              </Typography>
              <Typography
                style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}
              >
                {printData.priceDetails.valuedAmount}
              </Typography>
            </Box>
          ) : null} */}
        </Box>

        <Box>
          <Box style={boxFlex}>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              Սակագինը
            </Typography>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              {coreData?.hpAmount} AMD
            </Typography>
          </Box>

          <Box style={boxFlex}>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              Լրացուցիչ
            </Typography>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              {coreData?.additionalServiceAmount} AMD
            </Typography>
          </Box>
        </Box>

        <Box style={dividerAdditional}>
          <Box style={boxFlex}>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              Վճարվել է
            </Typography>
            <Typography style={{ fontSize: 12, marginBottom: 0, marginTop: 0 }}>
              {amountFormatter(activeOrder?.hpAmount?.toString())} AMD
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box>
            <Typography style={{ fontSize: 12, marginBottom: 0 }}>
              Օպերատոր
            </Typography>
            <Typography
              style={{
                fontFamily: "Arial AMU,Sylfaen",
                fontSize: 12,
                marginBottom: 0,
              }}
            >
              {coreData?.createdByUser}
            </Typography>
          </Box>

          <Box style={dividerAdditional} />

          <Box mb={10}>
            {coreData?.approvedDate && (
              <Typography
                style={{
                  fontSize: 12,
                  marginBottom: 0,
                  fontFamily: "Arial AMU,Sylfaen",
                }}
              >
                Ամսաթիվը{" "}
                {format(
                  addHours(
                    new Date(coreData?.approvedDate),
                    Math.abs(new Date().getTimezoneOffset() / 60)
                  ),
                  "dd/MM/yyyy HH:mm"
                )}
              </Typography>
            )}

            <Box style={dividerAdditional} />
            {[ETypeList.SIMPLE].includes(activeOrder?.category?.id) ? (
              <Fragment>
                <Typography
                  style={{ fontSize: 12, textAlign: "center", marginBottom: 0 }}
                >
                  Հայփոստ ՓԲԸ-ն տեղեկություն չի կարող տրամադրել հասարակ
                  առաքանիների համար
                </Typography>
                <Typography
                  style={{ fontSize: 12, textAlign: "center", marginBottom: 0 }}
                >
                  Արտահանված է "Էնվելոփ" համակարգով
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography
                  style={{ fontSize: 12, textAlign: "center", marginBottom: 0 }}
                >
                  Տվյալ կտրոնի վավերականությունը հնարավոր է ստուգել փնտրելով
                  "Կոդը" Հայփոստի կայքում
                </Typography>
                <Typography
                  style={{ fontSize: 12, textAlign: "center", marginBottom: 0 }}
                >
                  Արտահանված է "Էնվելոփ" համակարգով
                </Typography>
              </Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
});

export default Cheque;
