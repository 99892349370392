import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useQueryParams from "helpers/useQueryParams";
import useTablePagination from "helpers/useTablePagination";
import useTableSearch from "helpers/useTableSearch";
import { useLocation } from "react-router";

export const defaultQueries = {
  pageNumber: 1,
  pageCount: 10,
};

const defaultForCore = {
  pn: 1,
  ps: 10,
};

interface IUpdateTableData {
  rowsPerPage: number;
}

const useTable = (dispatchAction, config) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [getQueries, setQueries] = useQueryParams(defaultQueries);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (queries) => {
      setLoading(true);
      const urlParams = {
        ...(config?.withQueryParams ? defaultForCore : defaultQueries),
      };

      queries = {
        ...urlParams,
        ...queries,
      };

      const requestQueries = config?.withQueryParams
        ? new URLSearchParams(queries)
        : queries;

      if (requestQueries?.user && requestQueries?.user?.includes("+")) {
        requestQueries.user = requestQueries.user.replace("+", " ");
      }

      const { meta, payload } = await dispatch(dispatchAction(requestQueries));

      if (meta.requestStatus !== "fulfilled") {
        setLoading(false);
        return;
      }

      setLoading(false);
      setPagination({
        ...pagination,
        page: queries.pageNumber || queries.pn,
        rowsPerPage: parseInt(queries.pageCount) || queries.ps,
        totalCount: payload.totalCount,
      });
    },
    [dispatch, dispatchAction]
  );

  const [pagination, setPagination] = useTablePagination({
    defaultQueries,
    fetchData,
  });
  const search = useTableSearch(defaultQueries, fetchData);

  const initialFetch = useCallback(() => {
    const queries = { ...defaultQueries, ...getQueries() };
    if (config?.pathToCheck && config?.pathToCheck !== location.pathname) {
      delete queries.pageNumber;
      delete queries.pageCount;
    }
    fetchData(queries);
  }, [fetchData, getQueries, location.pathname, config?.pathToCheck]);

  useEffect(() => {
    if (!config?.noInitialFetch) {
      initialFetch();
    }
  }, []);

  const updateTable = ({ rowsPerPage }: IUpdateTableData) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(rowsPerPage),
    });
    setQueries({
      ...getQueries(),
      pageNumber: getQueries()?.pageNumber || 1,
      pageCount: rowsPerPage,
      ...(config?.withQueryParams ? { ps: rowsPerPage } : {}),
      ...(config?.withQueryParams ? { pn: getQueries()?.pageNumber || 1 } : {}),
    });
    fetchData(getQueries());
  };

  return {
    search,
    pagination,
    loading,
    setLoading,
    fetchData,
    updateTable,
  };
};

export default useTable;
