import { memo } from 'react';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((Theme) => ({
    buttonWrapper: {
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
        boxShadow: 'none',
    },
    loader: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 0,
    },
    colorPrimary: {
        color: 'red',
    },
    barColorPrimary: {
        backgroundColor: '#72a250',
    },
    barColorSecondary: {
        backgroundColor: '#72a250',
    },
}));

function ButtonLoader({
    color,
    disabled,
    fullWidth,
    isLoading,
    children,
    type,
    className,
    clickHandler,
    form,
    ...other
}) {
    const classes = useStyles({ fullWidth });

    return (
        <Button
            color={color}
            form={form}
            className={`${classes.buttonWrapper} ${className}`}
            disabled={isLoading || disabled}
            fullWidth={fullWidth}
            type={type}
            onClick={clickHandler}
            {...other}
        >
            {children}

            {isLoading && <LinearProgress className={classes.loader} color={color} />}
        </Button>
    );
}

ButtonLoader.defaultProps = {
    color: 'primary',
    variant: 'contained',
};
export default memo(ButtonLoader);
