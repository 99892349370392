import { useCallback } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { t } from "i18next";

const styles = makeStyles({
  error: {
    "& .MuiInputLabel-outlined ": {
      color: "#f44336",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336 !important",
    },
  },
});

const AutocompleteField = (props) => {
  const getDefaultOptionLabel = useCallback((option) => {
    if (!option) {
      return "";
    }
    if (typeof option === "object") {
      if (isTranslated) {
        return t(option?.[optionLabelKey]);
      }
      return option?.[optionLabelKey];
    }
    return option;
  }, []);

  const {
    size = "small",
    name,
    freeSolo = false,
    control,
    rules,
    multiple,
    isInputChange,
    onInputChange,
    autoSelect = false,
    disabled,
    className,
    options,
    label,
    optionLabelKey = "name",
    disableClearable,
    onChange,
    errors,
    groupBy,
    filterOptions,
    getOptionsLabel = getDefaultOptionLabel,
    customErrorMessage,
    renderOption = getDefaultOptionLabel,
    isTranslated = false,
  } = props;

  const formContext = useFormContext();

  const errorInfo = useCallback(() => {
    const lastErrors = formContext?.formState?.errors || errors;
    if (name.includes(".")) {
      const [inputName, nestedName] = name.split(".");
      return lastErrors?.[inputName]?.[nestedName];
    }

    return lastErrors?.[name];
  }, [errors, formContext?.formState?.errors, name]);

  const classes = styles();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            filterOptions={filterOptions}
            value={field?.value ?? ""}
            groupBy={groupBy}
            size={size}
            freeSolo={freeSolo}
            multiple={multiple}
            className={`${className} ${
              errorInfo?.() && !disabled && classes.error
            }`}
            disabled={disabled}
            options={options}
            disableClearable={disableClearable}
            autoSelect={autoSelect}
            getOptionLabel={getOptionsLabel}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={
                  !customErrorMessage ? !!errorInfo?.() : !!customErrorMessage
                }
                color="primary"
                helperText={
                  !customErrorMessage
                    ? errorInfo?.()?.message
                    : customErrorMessage
                }
                variant="outlined"
                disabled={disabled}
              />
            )}
            {...(isInputChange && {
              onInputChange: (event, newInputValue) => {
                onInputChange?.(newInputValue);
              },
            })}
            onChange={(e, value) => {
              field.onChange(value);
              onChange?.(value);
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteField;
