import { forwardRef, RefObject, useMemo } from "react";

import { Grid, Box } from "@material-ui/core";
import HaypostLogo from "src/assets/print-icons/haypost.svg";

import { printStyles } from "./printStyles";
import useEnclosuresHook from "./hooks/useEnclosuresHook";
import CN23EnclosuresArea from "./components/CN23EnclosuresArea";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import hsCodeList from "src/assets/constants/hsCodes.json";

const Cn23 = forwardRef(
  (props: { rightText?: string; printData?: any }, ref) => {
    const classes = printStyles();
    const { printData, rightText } = props;

    const enclosuresUpdatedData = useMemo(() => {
      const updatedData = JSON.parse(
        printData?.enclosures
      )?.[0]?.Enclosures.map((item) => {
        return {
          ...item,
          description: hsCodeList.find((hsCode) => hsCode.code === item.HsCode)
            ?.description_en,
        };
      });
      return updatedData;
    }, [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]);

    const backendDataForFirstPage = useEnclosuresHook(
      enclosuresUpdatedData,
      3,
      classes
    );
    const backendDataForSecondPage = useEnclosuresHook(
      enclosuresUpdatedData,
      4,
      classes
    );

    const {
      street: senderStreet,
      provinceState: senderProvinceState,
      cityVillage: senderCityVillage,
      building: senderBuilding,
      postalCode: senderPostalCode,
      apartment: senderApartment,
      nameSurname: senderNameSurname,
      phone: senderPhone,
      isLegal: senderIsLegal,
    } = printData.returnSender;

    const {
      country,
      provinceState,
      cityVillage,
      street,
      building,
      postalCode,
      apartment,
      nameSurname,
      phone,
      isLegal,
    } = printData.recipient;

    const totalAmount = useMemo(() => printData?.amount, [printData?.amount]);

    const enclosuresAmount = useMemo(
      () =>
        JSON.parse(printData?.enclosures)?.[0]?.Enclosures
          ? JSON.parse(printData?.enclosures)?.[0]?.Enclosures.reduce(
              (prev, current) => {
                return prev + current.Amount;
              },
              0
            )
          : 0,
      [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]
    );

    return (
      <Grid ref={ref as RefObject<HTMLDivElement>}>
        <Grid className={classes.cn23MainBorders}>
          <Grid container className={classes.cp72contentArea} spacing={0}>
            <div className={classes.cn23RightTitle}>
              <div className={classes.lettersSize14}>{rightText}</div>
            </div>
            <Grid className={classes.cp72content}>
              <Grid className={classes.leftAndRightContent} item xs={12}>
                <Grid item xs={6}>
                  <Grid
                    className={classes.titleArea}
                    style={{ paddingTop: "2px" }}
                    item
                    xs={12}
                  >
                    <div className={classes.textAreaRight}>
                      <p className={classes.lettersSize7}>
                        (Նշանակված օպերատոր)
                      </p>
                      <p className={classes.lettersSize7}>
                        (Designated operator)
                      </p>
                    </div>
                  </Grid>
                  <Grid className={classes.formToAreaParent} item xs={12}>
                    <Grid
                      className={classes.leftFormToArea}
                      style={{
                        position: "absolute",
                        marginLeft: "0",
                        left: "-30px",
                        borderRight: "none",
                      }}
                    >
                      <div
                        className={`${classes.textAreaRight} ${classes.edgingTop}`}
                      >
                        <p className={classes.lettersSize7}>Որտեղից</p>
                        <p className={classes.lettersSize7}>From</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ borderLeft: "1px solid" }}>
                      <Grid className={classes.nameBusinessArea} item xs={12}>
                        <Grid item xs={9}>
                          <Grid className={classes.cn23GridItem} item xs={12}>
                            <div
                              className={classes.textAreaRight}
                              style={{ width: "61.13px" }}
                            >
                              <p className={classes.lettersSize7}>
                                Անուն, Ազգանուն
                              </p>
                              <p className={classes.lettersSize7}>Name</p>
                            </div>
                            <div className={classes.cp72DataField}>
                              <p
                                className={classes.lettersSize6}
                                style={{ fontWeight: "bold" }}
                              >
                                {printData?.returnSender?.nameSurname
                                  ? printData?.returnSender?.nameSurname
                                  : ""}
                              </p>
                            </div>
                          </Grid>
                          <Grid className={classes.cn23GridItem} item xs={12}>
                            <div className={classes.textAreaRight}>
                              <p className={classes.lettersSize7}>
                                Կազակերպություն
                              </p>
                              <p className={classes.lettersSize7}>Business</p>
                            </div>
                            <div className={classes.cp72DataField}>
                              <p
                                className={classes.lettersSize6}
                                style={{ fontWeight: "bold" }}
                              >
                                {printData?.returnSender?.legalName
                                  ? printData?.returnSender?.legalName
                                  : ""}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className={classes.customRef} item xs={3}>
                          <div className={classes.textAreaLeft}>
                            <p className={classes.lettersSize6}>
                              Ուղղարկողի մաքսային թիվ(եթե այն առկա է)
                            </p>
                            <p className={classes.lettersSize6}>
                              Sender’s customs reference (if any)
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <Box width="60%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>Փողոց</p>
                            <p className={classes.lettersSize7}>Street</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderStreet} {senderBuilding} {senderApartment}
                            </p>
                          </div>
                        </Box>
                        <Box width="40%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Հեռ.</p>
                            <p className={classes.lettersSize7}>Tel. No.</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderPhone}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <Box width="50%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>
                              Փոստային դասիչ
                            </p>
                            <p className={classes.lettersSize7}>Postcode</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderPostalCode}
                            </p>
                          </div>
                        </Box>
                        <Box width="50%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Քաղաք</p>
                            <p style={{ fontSize: "7px", paddingLeft: "5px" }}>
                              City
                            </p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {senderProvinceState}
                              {senderCityVillage}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <div className={classes.textAreaRight}>
                          <p
                            className={classes.lettersSize7}
                            style={{ width: "61.13px" }}
                          >
                            Երկիր
                          </p>
                          <p className={classes.lettersSize7}>Country</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            Armenia
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.formToAreaParent} item xs={12}>
                    <Grid
                      className={classes.leftFormToArea}
                      style={{
                        position: "absolute",
                        marginLeft: "0",
                        left: "-12px",
                      }}
                    >
                      <div
                        className={classes.textAreaRight}
                        style={{ borderTop: "1px solid" }}
                      >
                        <p className={classes.lettersSize7}>Ուր</p>
                        <p className={classes.lettersSize7}>To</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ borderLeft: "1px solid" }}>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <div
                          className={classes.textAreaRight}
                          style={{ width: "61.13px" }}
                        >
                          <p className={classes.lettersSize7}>
                            Անուն, Ազգանուն
                          </p>
                          <p className={classes.lettersSize7}>Name</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            {printData?.recipient?.nameSurname
                              ? printData?.recipient?.nameSurname
                              : ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <div className={classes.textAreaRight}>
                          <p className={classes.lettersSize7}>
                            Կազակերպություն
                          </p>
                          <p className={classes.lettersSize7}>Business</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          <p
                            className={classes.lettersSize6}
                            style={{ fontWeight: "bold" }}
                          >
                            {printData?.recipient?.legalName
                              ? printData?.recipient?.legalName
                              : ""}
                          </p>
                        </div>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <Box width="60%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p
                              className={classes.lettersSize7}
                              style={{ width: "61.13px" }}
                            >
                              Փողոց
                            </p>
                            <p className={classes.lettersSize7}>Street</p>
                          </div>
                          <div
                            className={classes.cp72DataField}
                            style={{ fontWeight: "bold" }}
                          >
                            <p className={classes.lettersSize6}>
                              {street} {building} {apartment}
                            </p>
                          </div>
                        </Box>
                        <Box width="40%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Հեռ.</p>
                            <p className={classes.lettersSize7}>Tel. No.</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {phone}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid className={classes.cn23GridItem} item xs={12}>
                        <Box width="50%" display="flex">
                          <div
                            className={classes.textAreaRight}
                            style={{ width: "61.13px" }}
                          >
                            <p className={classes.lettersSize7}>
                              Փոստային դասիչ
                            </p>
                            <p className={classes.lettersSize7}>Postcode</p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {postalCode}
                            </p>
                          </div>
                        </Box>
                        <Box width="50%" display="flex" alignItems="center">
                          <div className={classes.cp72GridItemRightTextArea}>
                            <p className={classes.lettersSize7}>Քաղաք</p>
                            <p style={{ fontSize: "7px", paddingLeft: "5px" }}>
                              City
                            </p>
                          </div>
                          <div className={classes.cp72DataField}>
                            <p
                              className={classes.lettersSize6}
                              style={{ fontWeight: "bold" }}
                            >
                              {provinceState} {cityVillage}
                            </p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid
                        className={classes.cn23GridItem}
                        style={{ borderBottom: "1px solid " }}
                        item
                        xs={12}
                      >
                        <div className={classes.textAreaRight}>
                          <p
                            className={classes.lettersSize7}
                            style={{ width: "61.13px" }}
                          >
                            Երկիր
                          </p>
                          <p className={classes.lettersSize7}>Country</p>
                        </div>
                        <div className={classes.cp72DataField}>
                          {/* TODO  */}
                          <p className={classes.lettersSize6}>
                            {printData?.country?.name}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.rightArea} item xs={6}>
                  <Grid item xs={12} style={{ paddingBottom: "17%" }}>
                    <Grid className={classes.ReceiptText} item xs={9}>
                      <Box display="flex" position="relative">
                        <div
                          className={classes.textAreaLeft}
                          style={{ paddingTop: "5px" }}
                        >
                          <p className={classes.lettersSize13}>Ստացական</p>
                          <p className={classes.lettersSize10}>Receipt</p>
                          <p className={classes.lettersSize6}>
                            Ծանրոցի(ների). թիվը(երը)
                          </p>
                          <p className={classes.lettersSize7}>
                            (գծանիշ կոդ, եթե կա)
                          </p>
                          <p className={classes.lettersSize7}>
                            No(s). of parcel(s)
                          </p>
                          <p className={classes.lettersSize7}>
                            (barcode, if any)
                          </p>
                        </div>
                        <Box>
                          <Box
                            width="90%"
                            height="18.52mm"
                            style={{ textAlign: "center", paddingTop: "9px" }}
                          >
                            <BarcodeToPrint
                              barcode={printData.trackingNumber}
                              height={25}
                              fontSize={12}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src={HaypostLogo}
                        alt="logo"
                        className={classes.cp72HpLogo}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.cp72RightAreaGridItem}
                    style={{ height: "37px", paddingBottom: "8px" }}
                    item
                    xs={12}
                  >
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Ներմուծողի/հասցեատիրոջ տվյալները(եթե առկա են)
                      </p>
                      <p className={classes.lettersSize8}>
                        (հարկային կոդ/ԱԱՀ/ներմուծողի կոդը)(այլընտրանքային)
                      </p>
                      <p className={classes.lettersSize8}>
                        Importer/addressee reference (if any) (tax code/VAT
                        No./importer code) (optional)
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cp72RightAreaGridItem}
                    style={{ height: "28px" }}
                    item
                    xs={12}
                  >
                    <div
                      className={classes.textAreaLeft}
                      style={{ paddingTop: "4px" }}
                    >
                      <p className={classes.lettersSize8}>
                        Ներմուծողի/հասցեատիրոջ ֆաքս/էլ․ հասցե(եթե հայտնի է)
                      </p>
                      <p className={classes.lettersSize8}>
                        Importer/addressee fax/e-mail (if known)
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <CN23EnclosuresArea />
              <Box fontWeight="bold" fontSize="12px">
                {backendDataForFirstPage}
              </Box>
              <Grid className={classes.cp72FourthSection} item xs={12}>
                <Box display="flex" width="87.1mm">
                  <div
                    className={classes.cn23FourthSectionFirstColumn}
                    style={{ paddingBottom: "3px" }}
                  >
                    <div
                      className={classes.cp72FourthSectionFirstColumnFirstRow}
                    >
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                      >
                        <p className={classes.lettersSize7}>
                          Խնդրում ենք նշել ծառայության{" "}
                        </p>
                        <p className={classes.lettersSize7}>
                          տեսակը(նշեք նոր վանդակ)
                        </p>
                      </div>
                      <p className={classes.lettersSize6}>
                        Please indicate service required(tick one box)
                      </p>
                    </div>
                    <div
                      className={classes.cp72FourthSectionFirstColumnSecondRow}
                    >
                      <div className={classes.cp72CheckboxArea}>
                        <div
                          className={`${classes.square} ${classes.squareSizes1}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                        >
                          <p className={classes.lettersSize7}>
                            Միջազգային առաջնային{" "}
                          </p>
                          <p className={classes.lettersSize7}>
                            International Priority
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "20px" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes2}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                        >
                          <p className={classes.lettersSize7}>
                            Միջազգային Էկոնոմ
                          </p>
                          <p className={classes.lettersSize7}>
                            International Economy
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.edgingRight}>
                    <div className={classes.cn23FourthSectionSecondColumn}>
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize7}>Ընդհանուր</p>
                        <p className={classes.lettersSize7}>համաքաշ(4)</p>
                        <p className={classes.lettersSize6}>
                          Total gross weight(4)
                        </p>
                      </div>
                      <p
                        className={classes.cp72DataField}
                        style={{ fontWeight: "bold", fontSize: "10px" }}
                      >
                        {printData.weight / 1000} Kg
                      </p>
                    </div>
                  </div>
                </Box>
                <Box display="flex" width="87.1mm">
                  <div
                    className={`${classes.cp72FourthSectionSecondColumn} ${classes.edgingRight}`}
                    style={{ width: "20.8%" }}
                  >
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize7}>Ընդհանուր արժեք(6)</p>
                      <p className={classes.lettersSize6}>Total value(6)</p>
                    </div>
                    <p
                      className={classes.cp72DataField}
                      style={{ fontWeight: "bold", fontSize: "10px" }}
                    >
                      {enclosuresAmount} AMD
                    </p>
                  </div>
                  <div className={classes.cn23FourthSectionThirdColumn}>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize7}>
                        Փոստային վճար/գանձված սակագին(9)
                      </p>
                      <p className={classes.lettersSize6}>
                        Postal charges/Fees(9)
                      </p>
                    </div>
                    <p
                      className={classes.cp72DataField}
                      style={{ fontWeight: "bold", fontSize: "10px" }}
                    >
                      {totalAmount} AMD
                    </p>
                  </div>
                </Box>
              </Grid>
              <Grid
                className={classes.cn23FifthSection}
                item
                xs={12}
                style={{ borderRight: "1px solid" }}
              >
                <div className={classes.cn23FifthSectionLeftArea}>
                  <div
                    className={classes.cp72FifthSectionLeftAreaFirstRow}
                    style={{ paddingBottom: "2px" }}
                  >
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowFirstColumn
                      }
                    >
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize6}>
                          Առաքանու բնույթ(10)
                        </p>
                        <p className={classes.lettersSize6}>
                          Category of item(10)
                        </p>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes3}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>Նվեր</p>
                          <p className={classes.lettersSize6}>Gift</p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes4}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "3px" }}
                        >
                          <p className={classes.lettersSize6}>Փաստաթղթեր</p>
                          <p className={classes.lettersSize6}>Documents</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowSecondColumn
                      }
                    >
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes5}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Կոմերցիոն նմուշ
                          </p>
                          <p className={classes.lettersSize6}>
                            Comercial sample
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes6}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "2px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Ապրանքների վերադարձ
                          </p>
                          <p className={classes.lettersSize6}>Returned goods</p>
                        </div>
                      </div>
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes7}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px", paddingTop: "2px" }}
                        >
                          <p className={classes.lettersSize6}>
                            Ապրանքների վաճառք
                          </p>
                          <p className={classes.lettersSize6}>Sale of goods</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionLeftAreaFirstRowThirdColumn
                      }
                    >
                      <div
                        className={classes.cp72CheckboxArea}
                        style={{ paddingLeft: "0" }}
                      >
                        <div
                          className={`${classes.square} ${classes.squareSizes8}`}
                        />
                        <div
                          className={
                            classes.cp72FourthSectionFirstColumnServiceType
                          }
                          style={{ paddingLeft: "20px" }}
                        >
                          <p className={classes.lettersSize6}>Այլ(նշել)</p>
                          <p className={classes.lettersSize6}>
                            Other(please specify)
                          </p>
                        </div>
                      </div>
                      <div style={{ paddingTop: "5px" }}>
                        <p className={classes.lettersSize6}>Բացատրություն</p>
                        <p className={classes.lettersSize6}>Explanation</p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cn23FifthSectionLeftAreaFirstRowFourthColumn
                      }
                    >
                      <p className={classes.lettersSize6} />
                      <div className={classes.cp72RowLeft} />
                    </div>
                  </div>
                  <div className={classes.cp72FifthSectionLeftAreaSecondRow}>
                    <p className={classes.lettersSize7}>
                      Նշումներ(11)(օր․ կարանտինի տակ գտնվող
                      սանիտարական/ֆիտոսանիտարական
                    </p>
                    <p className={classes.lettersSize7}>
                      ստուգման և այլ սահմանափակման ենթակա ապրանքներ)
                    </p>
                    <p className={classes.lettersSize7}>
                      Comments(11)(e.g.: goods subject to quarantine,
                      sanitary/phytosanytary inspection or other restrictions
                    </p>
                    <p className={classes.lettersSize7} />
                  </div>
                </div>
                <div
                  className={classes.cp72FifthSectionRightArea}
                  style={{
                    width: "70.6mm",
                    borderRight: "none",
                    height: "22mm",
                  }}
                >
                  <div className={classes.cn23FifthSectionRightAreaFirstColumn}>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>Տրման գրասենյակ/</p>
                      <p className={classes.lettersSize6}>ընդունման ամսաթիվ</p>
                      <p className={classes.lettersSize6}>
                        Office of origin/Date of posting
                      </p>
                    </div>
                    <p className={classes.cp72DataField} />
                  </div>
                  <div
                    className={classes.cp72FifthSectionRightAreaSecondColumn}
                  >
                    <div
                      className={`${classes.cp72FifthSectionRightAreaSecondColumnFirstRow} ${classes.edgingBottom}`}
                    >
                      <div
                        className={classes.cp72HeaderArea}
                        style={{ paddingRight: "25px" }}
                      >
                        <p className={classes.lettersSize6}>Ծանրոցների քանակ</p>
                        <p className={classes.lettersSize6}>
                          Number of parcels
                        </p>
                      </div>
                      <div>
                        <p className={classes.lettersSize6}>
                          <span className={classes.rowStraight3} />
                          Հավաստագրեր և
                        </p>
                        <p className={classes.lettersSize6}>ապրանքանգրեր</p>
                        <p className={classes.lettersSize6}>certificates</p>
                        <p className={classes.lettersSize6}>and invoices</p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionRightAreaSecondColumnSecondRow
                      }
                    >
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize6}>
                          Հայտարարագրված արժեք ՀՓԻ-ով
                        </p>
                        <p className={classes.lettersSize6}>
                          Insured value SDR
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        classes.cp72FifthSectionRightAreaSecondColumnThirdRow
                      }
                    >
                      <div
                        className={classes.cp72HeaderArea}
                        style={{ paddingRight: "22px", width: "74%" }}
                      >
                        <p className={classes.lettersSize6}>Ծանրոց(ների)</p>
                        <p className={classes.lettersSize6}>
                          ընդհանուր համաքաշ
                        </p>
                        <p className={classes.lettersSize5}>
                          Total gross weight
                        </p>
                        <p className={classes.lettersSize5}>of the parcel(s)</p>
                      </div>
                      <div className={classes.cn23Charges}>
                        <p className={classes.lettersSize6}>Վճարներ</p>
                        <p className={classes.lettersSize6}>Charges</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className={classes.cn23SixthSection}>
                <div className={classes.cn23SixthSectionLeftArea}>
                  <Grid className={classes.cn23SixthSectionLeftAreaColumns}>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>
                          Թույլտվություն(12)
                        </p>
                        <p className={classes.lettersSize6}>Licence(12)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>
                        Թույլտվության թիվ(եր)
                      </p>
                      <p className={classes.lettersSize6}>Licence number(s)</p>
                    </div>
                  </Grid>
                  <Grid className={classes.cn23SixthSectionLeftAreaColumns}>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>Հավաստագիր(13)</p>
                        <p className={classes.lettersSize6}>Certificate(13)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>Հավաստագրի թիվ(եր)</p>
                      <p className={classes.lettersSize6}>
                        Certificate number(s)
                      </p>
                    </div>
                  </Grid>
                  <Grid className={classes.cn23SixthSectionLeftAreaColumns}>
                    <div
                      className={classes.cp72CheckboxArea}
                      style={{ paddingLeft: "0" }}
                    >
                      <div
                        className={`${classes.square} ${classes.squareSizes9}`}
                      />
                      <div
                        className={
                          classes.cp72FourthSectionFirstColumnServiceType
                        }
                        style={{ paddingLeft: "20px" }}
                      >
                        <p className={classes.lettersSize6}>Ապրանքագիր(14)</p>
                        <p className={classes.lettersSize6}>Invoice(14)</p>
                      </div>
                    </div>
                    <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                      <p className={classes.lettersSize6}>Ապրանքագրի թիվ(եր)</p>
                      <p className={classes.lettersSize6}>Invoice number(s)</p>
                    </div>
                  </Grid>
                </div>
                <div
                  className={classes.cn23SixthSectionRightArea}
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className={classes.cp72SixthSectionRightAreaFirstRow}
                    style={{
                      display: "flex",
                      width: "291.5px",
                      paddingBottom: "2px",
                    }}
                  >
                    <div
                      style={{ padding: "4px 61px 0 5px ", textAlign: "left" }}
                    />
                  </div>
                  <div
                    className={classes.cp72SixthSectionRightAreaSecondRow}
                    style={{ display: "flex", width: "291.5px" }}
                  />
                </div>
              </Grid>
              <div className={classes.cn23SeventhSection}>
                <div className={classes.cp72SeventhSectionLeftArea}>
                  <div
                    className={classes.cn23SeventhSectionLeftAreaFirstColumn}
                  >
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>
                        Հավաստիացնում եմ,որ սույն մաքսային հայտարարագրում
                        ներկայացված տեղեկությունները ճիշտ են, և որ այս առաքանին
                        չի պարունակում փոստային կամ մաքսային կանոնակարգով կամ
                        օրենսդրությամբ արդելված վտանգավոր իր
                      </p>
                      <p className={classes.lettersSize5}>
                        I certify that the particulars given in this customs
                        declaration are correct and that this item does not
                        contain any dangerous article prohibited by legislation
                        or by postal or customs regulations
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.cn23SeventhSectionLeftAreaSecondColumn}
                  >
                    <div className={classes.edgingBottom}>
                      <div className={classes.cp72HeaderArea}>
                        <p className={classes.lettersSize5}>
                          Գնահատված առաքանու կորստի կամ վնասի դեպքում
                        </p>
                        <p className={classes.lettersSize5}>
                          Փոխհատուցման առավելագույն չափը 250000 ՀՀ դրամ
                        </p>
                        <p className={classes.lettersSize5}>
                          է, որի մեջ ներառված չէ ծառայության արժեքը
                        </p>
                        <p style={{ fontSize: "4px" }}>
                          In case of loss or damage of insured item the maximum
                          conpensation
                        </p>
                        <p style={{ fontSize: "4px" }}>
                          amoun is 250000 AMD, excluding item postage
                        </p>
                      </div>
                    </div>
                    <div className={classes.cp72HeaderArea}>
                      <p className={classes.lettersSize6}>
                        Ամսաթիվ և ուղղարկողի ստորագրություն(15)
                      </p>
                      <p className={classes.lettersSize6}>
                        Date and sender`&apos;`s signature(15)
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.cp72SeventhSectionRightArea}>
                  <div
                    className={classes.cp72SeventhSectionRightAreaFirstColumn}
                  >
                    <div className={classes.cp72HeaderArea} />
                  </div>
                  <div
                    className={classes.cp72SeventhSectionRightAreaSecondColumn}
                    style={{ border: "none" }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {enclosuresUpdatedData.length > 3 && (
          <Grid className={classes.cn23MainBorders}>
            <Grid container className={classes.cp72contentArea} spacing={0}>
              <Grid className={classes.cp72content}>
                <CN23EnclosuresArea />
                <Box fontWeight="bold" fontSize="12px">
                  {backendDataForSecondPage}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default Cn23;
