import { Dialog, Box, DialogContent, Typography } from "@material-ui/core";

const CreateUpdateDialog = ({
  open,
  title,
  onClose,
  children,
  width = "xs",
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={width}>
      <Box m={4}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CreateUpdateDialog;
