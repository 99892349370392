import { Fragment, useCallback, useEffect } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";
import { createFilterOptions } from "@material-ui/lab";
import { Grid, Box, Button } from "@material-ui/core";
import countriesList from "src/assets/constants/countries.json";
import hsCodeList from "src/assets/constants/hsCodes.json";
import AutocompleteField from "src/shared/ui/Autocomplete";
import InputField from "src/shared/ui/Input";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  selectEnclosuresData,
  selectOrderDetailData,
} from "src/store/slicers/order";
import { t } from "i18next";

export const enclosureDefaultData = {
  countryOfOrigin: null,
  hsCode: null,
  weight: "0",
  quantity: "0",
  amount: "0",
};

const filterOptions = createFilterOptions({
  stringify: (option: IHSCodes) => option.code + option.description_am,
});

const requiredRule = {
  required: {
    value: true,
    message: "Պարտադիր դաշտ",
  },
};

interface IHSCodes {
  id: number;
  code: string;
  description_am: string;
  description_en: string;
}

const EnclosureDialogForm = ({
  onSubmit,
  handleClose,
  mode,
  setIsLoading,
}): JSX.Element => {
  const enclosuresData = useSelector(selectEnclosuresData);
  const orderDetailsData = useSelector(selectOrderDetailData);

  const { control, handleSubmit, reset } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "enclosures",
  });

  const getHSCodeOptionsLabel = useCallback((option) => {
    if (option) {
      return `${option.description_am} ${option.code}`;
    } else {
      return "";
    }
  }, []);

  const getHSCodeRenderOption = useCallback((option) => {
    if (option) {
      return `${option.code}`;
    } else {
      return "";
    }
  }, []);

  const addEnclosure = () => append(enclosureDefaultData);

  const removeEnclosure = (index: number) => remove(index);

  const initialFetch = useCallback(() => {
    setIsLoading(true);
    const newEnclosuresData = enclosuresData?.map((item) => {
      return {
        ...item,
        countryOfOrigin: countriesList.find(
          (country) => item.countryOfOrigin === country.name
        ),
        hsCode: hsCodeList.find((hsCode) => hsCode.code === item.hsCode),
      };
    });
    if (mode === "create" && enclosuresData?.length) {
      const data = newEnclosuresData?.length
        ? newEnclosuresData
        : [enclosureDefaultData];

      reset({
        enclosures: data,
      });
    }
    if (mode === "edit" && JSON.parse(orderDetailsData?.enclosures)?.length) {
      const orderDetailEnclosures = JSON.parse(
        orderDetailsData?.enclosures
      )?.[0]?.Enclosures;

      const newOrderEnclosuresData = orderDetailEnclosures?.map((item) => {
        return {
          weight: item.Weight,
          amount: item.Amount,
          quantity: item.Quantity,
          countryOfOrigin: countriesList.find(
            (country) => item.CountryOfOrigin === country.name
          ),
          hsCode: hsCodeList.find((hsCode) => hsCode.code === item.HsCode),
        };
      });

      const data = newEnclosuresData?.length
        ? newEnclosuresData
        : newOrderEnclosuresData?.length
        ? newOrderEnclosuresData
        : [];

      reset({
        enclosures: data,
      });
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    initialFetch();
  }, [enclosuresData]);

  return (
    <Fragment>
      {fields?.length
        ? fields.map((_, index) => (
            <Grid container alignItems="center" spacing={3} key={nanoid(10)}>
              <Grid item xs={3}>
                <AutocompleteField
                  size="medium"
                  name={`enclosures[${index}].countryOfOrigin`}
                  label={t("country")}
                  control={control}
                  options={countriesList || []}
                />
              </Grid>
              <Grid item xs={2}>
                <AutocompleteField
                  filterOptions={filterOptions}
                  size="medium"
                  optionLabelKey="code"
                  name={`enclosures[${index}].hsCode`}
                  label={t("hs_code")}
                  control={control}
                  getOptionsLabel={getHSCodeRenderOption}
                  options={hsCodeList || []}
                  renderOption={getHSCodeOptionsLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <InputField
                  name={`enclosures[${index}].weight`}
                  label={`${t("weight")}(${t("gram")})`}
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <InputField
                  name={`enclosures[${index}].quantity`}
                  label={t("quantity")}
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <InputField
                  name={`enclosures[${index}].amount`}
                  label={t("amount")}
                  control={control}
                />
              </Grid>
              {fields?.length !== 1 && (
                <Grid item xs={1}>
                  <Button onClick={() => removeEnclosure(index)}>
                    <RemoveCircleOutlineIcon color="error" />
                  </Button>
                </Grid>
              )}
            </Grid>
          ))
        : null}
      <Box pt={2}>
        <Button
          onClick={addEnclosure}
          disabled={fields?.length === 5}
          color="primary"
          variant="contained"
        >
          {t("add_new_enclosure")}
        </Button>
      </Box>
      <Box display="flex" justifyContent="flex-end" py={1}>
        <Button onClick={handleClose} color="primary">
          {t("cancel")}
        </Button>
        <Box ml={2}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {t("approve")}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default EnclosureDialogForm;
