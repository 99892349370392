import { forwardRef, RefObject, useMemo } from "react";

import Asset from "src/assets/print-icons/Asset.svg";
import HaypostLogo from "src/assets/print-icons/haypost.svg";
import HaypostLogoSvg from "src/assets/print-icons/CN22Icon.svg";
import PostExpressImage from "src/assets/print-icons/CN22PostExpressImage.jpg";

import { printStyles } from "./printStyles";
import { format } from "date-fns";
import { Grid, Box } from "@material-ui/core";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import hsCodeList from "src/assets/constants/hsCodes.json";

const Cn22 = forwardRef((props, ref) => {
  const classes = printStyles();
  const { printData } = props;
  const {
    apartment: senderApartment,
    building: senderBuilding,
    street: senderStreet,
    country: senderCountry,
    cityVillage: senderCityVillage,
    postalCode: senderPostalCode,
    nameSurname: senderNameSurname,
  } = printData.returnSender;
  const {
    street,
    postalCode,
    country,
    cityVillage,
    building,
    apartment,
    nameSurname,
  } = printData.recipient;
  const isUS = false;

  const enclosuresUpdatedData = useMemo(() => {
    const updatedData = JSON.parse(printData?.enclosures)?.[0]?.Enclosures.map(
      (item) => {
        return {
          ...item,
          description: hsCodeList.find((hsCode) => hsCode.code === item.HsCode)
            ?.description_en,
        };
      }
    );
    return updatedData;
  }, [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]);

  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      className={classes.containerPageCN}
    >
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div className={classes.containerHeadertLeft}>
            <div className={classes.contentHeaderLeft}>
              <div>
                <h3 className={classes.HeaderTextLeft}>CUSTOMS DECLARATION</h3>
                {isUS ? (
                  <div className={classes.haypostLogoSvgContainer}>
                    <img
                      style={{ width: "8mm" }}
                      src={HaypostLogoSvg}
                      alt="logo-icon"
                    />
                    <Box fontSize="22px" marginLeft="5px" fontWeight="bold">
                      HayPost
                    </Box>
                  </div>
                ) : (
                  <div
                    className={classes.LogoContainer}
                    style={{ textAlign: "left" }}
                  >
                    <img
                      src={HaypostLogo}
                      className={classes.HeaderLogoLeft}
                      alt="logo"
                    />
                  </div>
                )}
              </div>

              <div className={classes.line} style={{ height: "35px" }} />

              <div className={classes.HeaderTextsLeft}>
                <h5 className={classes.HeaderTitleft}>CN 22</h5>
                <span className={classes.HeaderSubtitleLeft}>May be</span>
                <span className={classes.HeaderDescLeft}>
                  opened officially
                </span>
              </div>
            </div>
          </div>

          <div className={classes.containerSectionLeft}>
            <Grid item xs={4} className={classes.containerSectionLeftGrid}>
              <div className={classes.itemLeftGrid}>
                <div className={classes.itemLeft} />
                <span
                  className={classes.titleLeft}
                  style={{ paddingTop: "6px" }}
                >
                  Gift
                </span>
              </div>

              <div className={classes.itemLeftGrid}>
                <div className={classes.itemLeft} />
                <span
                  className={classes.titleLeft}
                  style={{ paddingTop: "6px" }}
                >
                  Documents
                </span>
              </div>

              <div className={classes.itemLeftGrid}>
                <div className={classes.itemLeft} />
                <span
                  className={classes.titleLeft}
                  style={{ paddingTop: "6px" }}
                >
                  Sale of goods
                </span>
              </div>
            </Grid>

            <Grid item xs={4} className={classes.containerSectionLeftGrid}>
              <div className={classes.itemLeftGrid}>
                <div className={classes.itemLeft} />
                <div>
                  <p className={classes.titleLeft}>Commercial</p>
                  <p className={classes.titleLeft}>sample</p>
                </div>
              </div>

              <div
                className={classes.itemLeftGrid}
                style={{ marginTop: "-4px" }}
              >
                <div
                  className={classes.itemLeft}
                  style={{ width: "7mm", height: "7mm" }}
                />
                <span className={classes.titleLeft}>Returned goods other</span>
              </div>

              <div
                className={classes.itemLeftGrid}
                style={{ marginTop: "-4px" }}
              >
                <div className={classes.itemLeft} style={{ height: "7mm" }} />
                <span className={classes.titleLeft}>(please specify)</span>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className={classes.line} />
              <div className={classes.text}>
                <span>See instructions</span>
              </div>
              <span className={classes.textBelow}>below</span>
            </Grid>
          </div>

          <div className={classes.constinerAsideLeft}>
            <Grid
              item
              xs={4}
              className={classes.constinerAsideLeftTexts}
              style={{ paddingLeft: "7px" }}
            >
              <p>Quantity and</p>
              <p>detailed description</p>
              <p> of contents(1)</p>
            </Grid>

            <Grid item xs={2} className={classes.constinerAsideLeftTexts}>
              <p>Net</p>
              <p>weight(2)</p>
            </Grid>

            <Grid item xs={2} className={classes.constinerAsideLeftTexts}>
              <p>HS tariff</p>
              <p>number</p>
              <p>(3)</p>
            </Grid>

            <Grid item xs={2} className={classes.constinerAsideLeftTexts}>
              <p>Value and</p>
              <p>currency</p>
              <p>(4)</p>
            </Grid>

            <Grid item xs={2} className={classes.constinerAsideLeftTexts}>
              <p>Country of</p>
              <p>origin</p>
              <p>(5)</p>
            </Grid>
          </div>
          <Box height="29mm" fontWeight="bold">
            {enclosuresUpdatedData?.length > 0
              ? enclosuresUpdatedData.map((item, index) => {
                  return (
                    <div className={classes.constinerContentLeft} key={index}>
                      <div
                        className={`${classes.constinerContentLeftTexts} ${classes.cn22EnclosureItemDescriptionArea}`}
                      >
                        <p
                          style={{
                            border: "none",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.description} / {item.Quantity}
                        </p>
                      </div>

                      <div
                        className={`${classes.constinerContentLeftTexts} ${classes.cn22EnclosureItemDetailsArea}`}
                      >
                        <p
                          style={{
                            border: "none",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.Weight / 1000} Kg
                        </p>
                      </div>

                      <div
                        className={`${classes.constinerContentLeftTexts} ${classes.cn22EnclosureItemDetailsArea}`}
                      >
                        <p
                          style={{
                            border: "none",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.HsCode}
                        </p>
                      </div>

                      <div
                        className={`${classes.constinerContentLeftTexts} ${classes.cn22EnclosureItemDetailsArea}`}
                      >
                        <p
                          style={{
                            border: "none",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.Amount} AMD
                        </p>
                      </div>

                      <div
                        className={`${classes.constinerContentLeftTexts} ${classes.cn22EnclosureItemDetailsArea}`}
                      >
                        <p
                          style={{
                            border: "none",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.CountryOfOrigin}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ""}
          </Box>
          <Grid item xs={12} className={classes.containerFooterLeft}>
            <Grid
              item
              xs={6}
              className={classes.containerFooterLeftItems}
              style={{ paddingLeft: "6px" }}
            >
              <p className={classes.title}>Total weight(6)</p>
              <p className={classes.description} style={{ fontWeight: "bold" }}>
                {printData.weight / 1000} Kg
              </p>
            </Grid>

            <Grid item xs={6} className={classes.containerFooterLeftItems}>
              <p className={classes.title}>Total value (7)</p>
              <p className={classes.description} style={{ fontWeight: "bold" }}>
                {printData?.amount} AMD
              </p>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerFooterLeftText}>
            <p>
              I, the undersigned, whose name and address are given on the item,
              certify that the particulars given in this declaration are correct
              and that this item does not contain any dangerous article or
              articles prohibited by legislation or by postal or customs
              regulations
            </p>
          </Grid>

          <Grid item xs={12} className={classes.footerLeft}>
            <Grid item xs={6} className={classes.footerLeftItem}>
              <p>Date and sender{`'`}s signature (8)</p>
            </Grid>

            <Grid item xs={6} className={classes.footerRightItem}>
              <p>
                {senderNameSurname
                  ? senderNameSurname
                  : printData?.returnSender?.legalName}
                {format(new Date(), "dd/MM/yyyy")}
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid item xs={12} className={classes.containerHeaderRight}>
            {isUS ? (
              <Grid item xs={4} className={classes.containerPostExpress}>
                <img src={PostExpressImage} alt="logo" />
              </Grid>
            ) : (
              <Grid item xs={4} className={classes.containerLogoRight}>
                <img src={HaypostLogo} alt="logo" />
              </Grid>
            )}
            {isUS ? (
              <Grid item xs={2} className={classes.containerTextRight}>
                <p>L</p>
                <Box fontSize="14px">Tracked</Box>
              </Grid>
            ) : (
              <Grid item xs={2} className={classes.containerTextRight}>
                <p>R</p>
              </Grid>
            )}

            <Grid
              item
              style={{ paddingTop: "20px" }}
              className={classes.containerTextsRight}
              xs={6}
            >
              <p>P.P.</p>
              <div className={classes.phoneNumber}>
                <p>12345678999999</p>
              </div>
              <p>REPUBLIC OF ARMENIA</p>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerRightForm}>
            <Grid item xs={1} className={classes.containerRightTextFrom}>
              <p className={classes.textRightFrom}>FROM</p>
            </Grid>

            <Grid item xs={11} className={classes.containerRightTitles}>
              <div className={classes.itemUser}>
                <p style={{ paddingRight: "20px" }}>Full Name</p>
                <p style={{ fontWeight: "bold" }}>
                  {senderNameSurname
                    ? senderNameSurname
                    : printData?.returnSender?.legalName}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "40px" }}>Street</p>
                <p style={{ fontWeight: "bold" }}>
                  {senderStreet} , {senderBuilding} , {senderApartment}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "2px" }}>Postcode/City</p>
                <p style={{ fontWeight: "bold" }}>
                  {senderPostalCode} / {senderCityVillage}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "31px" }}>Country</p>
                <p style={{ fontWeight: "bold" }}>Armenia</p>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerRightForm}>
            <Grid item xs={1} className={classes.containerRightTextFrom}>
              <p className={classes.textRightTo}>TO</p>
            </Grid>

            <Grid item xs={11} className={classes.containerRightTitles}>
              <div className={classes.itemUser}>
                <p style={{ paddingRight: "20px" }}>Full Name</p>
                <p style={{ fontWeight: "bold" }}>
                  {nameSurname ? nameSurname : printData?.recipient?.legalName}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "40px" }}>Street</p>
                <p style={{ fontWeight: "bold" }}>
                  {street} , {building} , {apartment?.name}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "2px" }}>Postcode/City</p>
                <p style={{ fontWeight: "bold" }}>
                  {postalCode} / {cityVillage}
                </p>
              </div>

              <div className={classes.itemUser}>
                <p style={{ paddingRight: "31px" }}>Country</p>
                <p style={{ fontWeight: "bold" }}>{printData?.country?.name}</p>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.containerRightIcons}>
            <Grid item xs={6} className={classes.containerIcon}>
              <img src={Asset} alt="" className={classes.cn22Asset} />
            </Grid>

            <Grid item xs={6}>
              <div className={classes.footerRightKg}>
                <p style={{ fontWeight: "bold" }}>
                  {printData.weight / 1000} Kg
                </p>
              </div>
            </Grid>
          </Grid>

          <Box
            justifyContent="center"
            height="26.46mm"
            width="52.92mm"
            pt="7px"
          >
            <BarcodeToPrint barcode={printData.trackingNumber} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default Cn22;
