export const LStorage = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  expDate: "expDate",
};

export enum EUserStatusList {
  ACTIVE = 1,
  DEACTIVE = 2,
  CREATED = 4,
}

export const EBaseUrl = {
  Envelope: "https://envapi.haypost.am/Api",
  CoreLogistics: "https://logistics.devhaypost.com/Api",
};
