import { Paper, Box } from "@material-ui/core";
import Table from "shared/ui/Table/index";
import { translatedColumns } from "./data/constants";
import useTable from "helpers/useTable";
import { useSelector } from "react-redux";
import {
  fetchTransactions,
  selectTransactions,
} from "src/store/slicers/transactions";
import Filters from "./components/filters";
import { useTranslation } from "react-i18next";

const History = () => {
  const historyData = useSelector(selectTransactions);
  const { t } = useTranslation();
  const columns = translatedColumns(t);

  const { loading, pagination, fetchData, updateTable } = useTable(
    fetchTransactions,
    {
      noInitialFetch: true,
      withQueryParams: true,
    }
  );

  return (
    <Box component={Paper} p={4}>
      <Table
        title="balance_history"
        isLoading={loading}
        columns={columns}
        rows={historyData?.data || []}
        paginationConfig={pagination}
        onChangeRowsPerPage={(event: any) =>
          updateTable({ rowsPerPage: event.target.value })
        }
        filters={<Filters fetchData={fetchData} />}
      />
    </Box>
  );
};

export default History;
