import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles, Paper, Box, Button, Grid } from "@material-ui/core";
import RangePicker from "shared/ui/RangePicker";
import InputField from "shared/ui/Input";
import useTableFilters from "helpers/useTableFilters";
import SelectField from "shared/ui/Select";
import { useDispatch, useSelector } from "react-redux";
import { TypeList, PackageType } from "../data/constants";
import useQueryParams from "src/helpers/useQueryParams";
import { StatusList } from "../data/constants";
import { selectUserData } from "src/store/slicers/users";
import { Roles } from "src/router/routes";
import ListAlt from "@material-ui/icons/ListAlt";
import Autocomplete from "src/shared/ui/Autocomplete";
import { debounce, getDefaultDate } from "src/helpers";
import { fetchCoreCustomerIds } from "src/store/slicers/organizations";
import { AppDispatch } from "src/store";
import { defaultQueries } from "src/helpers/useTable";
import { selectOrders } from "src/store/slicers/order";
import countriesList from "src/assets/constants/countries.json";
import { useTranslation } from "react-i18next";
import PrintIcon from "@material-ui/icons/Print";

const styles = makeStyles({
  autocomplete: {
    maxWidth: "235px",
    minWidth: "235px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  root: {
    width: "100%",
    marginBottom: "5px",
    padding: "15px",
  },
  filterIcon: {
    marginTop: "15px",
  },
  clearBtn: {
    marginLeft: "20px",
  },
  buttonStyle: {
    width: "100%",
  },
});

const defaultValues = {
  note1: "",
  note2: "",
  recipient: "",
  trackingNumber: "",
  serviceId: "",
  categoryId: "",
  organization: "",
  statusId: "",
  user: "",
  startDate: getDefaultDate(true),
  endDate: getDefaultDate(false),
};

const Filters = ({
  fetchData,
  handleExportParcels,
  handleExportCustomersParcels,
  handleGetReestrPrintData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [organizations, setOrganizations] = useState([]);
  const [getQueries, setQueries] = useQueryParams();
  const classes = styles();
  const { t } = useTranslation();
  const userData = useSelector(selectUserData);
  const ordersList = useSelector(selectOrders);
  const methods = useForm({
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const { disableClear } = useTableFilters({
    reset,
    getValues,
    fetchData,
    defaultValues,
  });

  const actionsDisabled = useMemo(() => {
    return (
      !(watch("startDate") && watch("endDate")) || !ordersList?.data?.length
    );
  }, [watch()]);

  const onSubmit = (formData) => {
    const pageNumber = 1;
    const pageCount = 10;
    const paramsData = {
      ...(pageCount && { pageCount }),
      ...(pageNumber && { pageNumber }),
      ...(formData?.organization && {
        organizationId: formData.organization?.id,
      }),
      ...(formData?.serviceId && { serviceId: formData.serviceId }),
      ...(formData?.note1 && { note1: formData.note1 }),
      ...(formData?.note2 && { note2: formData.note2 }),
      ...(formData?.recipient && { recipient: formData.recipient }),
      ...(formData?.categoryId && { categoryId: formData.categoryId }),
      ...(formData?.statusId && { statusId: formData.statusId }),
      ...(formData?.user && { user: formData.user }),
      ...(formData?.trackingNumber && {
        trackingNumber: formData.trackingNumber,
      }),
      ...(formData?.countryId?.id && { countryId: formData.countryId?.id }),
      ...(formData?.startDate &&
        formData?.endDate && {
          startDate: formData?.startDate,
          endDate: formData?.endDate,
        }),
    };
    const formDataWithTrackingNumber = {
      ...defaultValues,
      endDate: "",
      startDate: "",
      trackingNumber: formData?.trackingNumber,
    };

    Object.keys(formDataWithTrackingNumber).forEach((key) => {
      if (!formDataWithTrackingNumber[key]) {
        delete formDataWithTrackingNumber[key];
      }
    });
    setQueries(
      !formData?.trackingNumber ? paramsData : formDataWithTrackingNumber
    );
    fetchData(
      !formData?.trackingNumber ? paramsData : formDataWithTrackingNumber
    );
    //reset all filters when grid is filtered with tracking number
    if (formData?.trackingNumber) {
      reset({
        ...defaultValues,
        endDate: "",
        startDate: "",
        trackingNumber: formData?.trackingNumber,
      });
    }
  };

  const handleFetchCoreOrganizations = useCallback(async (value) => {
    const { meta, payload } = await dispatch(
      fetchCoreCustomerIds({ term: value })
    );
    setOrganizations(payload);
  }, []);

  const debouncedFetchCoreOrganizations = useCallback(
    debounce((value: string) => handleFetchCoreOrganizations(value), 400),
    []
  );

  const handleClearFilters = () => {
    const queryParams = { ...defaultValues };

    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    reset({
      ...defaultValues,
      categoryId: defaultValues.categoryId
        ? Number(defaultValues.categoryId)
        : "",
      serviceId: defaultValues.serviceId ? Number(defaultValues.serviceId) : "",
      statusId: defaultValues.statusId ? Number(defaultValues.statusId) : "",
    });
    const deafultURLParamsValues = {
      ...defaultQueries,
      ...queryParams,
    };
    setQueries(deafultURLParamsValues);
    fetchData(queryParams);
  };

  const filtersInitialFetch = useCallback(async () => {
    const queryParams = { ...defaultValues };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    const newQueryParams = {
      ...queryParams,
      ...getQueries(),
    };
    setQueries(newQueryParams);
    let newSelectedOrganization = null;
    if (userData?.role === Roles.SuperAdmin) {
      const { meta, payload } = await dispatch(
        fetchCoreCustomerIds({ term: "" })
      );
      setOrganizations(payload);
      const selectedOrganization = payload.find(
        (item) => item.id == newQueryParams.organizationId
      );
      newSelectedOrganization = selectedOrganization;
    }

    const countryId = countriesList.find(
      (item) => newQueryParams?.countryId == item.id
    );
    reset({
      ...newQueryParams,
      categoryId: newQueryParams.categoryId
        ? Number(newQueryParams.categoryId)
        : "",
      serviceId: newQueryParams.serviceId
        ? Number(newQueryParams.serviceId)
        : "",
      statusId: newQueryParams.statusId ? Number(newQueryParams.statusId) : "",
      countryId,
      organization: newSelectedOrganization,
    });
  }, []);

  useEffect(() => {
    filtersInitialFetch();
  }, [userData]);

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Box>
            <Box display="flex" flexWrap="wrap" pt={2}></Box>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box>
                  <RangePicker label="Select date range" control={control} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="trackingNumber"
                  label={t("parcel_number")}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectField
                  name="serviceId"
                  label={t("parcel_type")}
                  control={control}
                  keyProp="id"
                  size="small"
                  valueProp="id"
                  options={PackageType}
                  isTranslated
                />
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  name="categoryId"
                  label={t("service")}
                  control={control}
                  keyProp="id"
                  size="small"
                  valueProp="id"
                  options={TypeList}
                  isTranslated
                />
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  name="statusId"
                  label={t("status")}
                  control={control}
                  keyProp="id"
                  size="small"
                  valueProp="id"
                  options={StatusList}
                  isTranslated
                />
              </Grid>

              {userData?.role === Roles.SuperAdmin && (
                <Grid item xs={4}>
                  <Autocomplete
                    label={t("organization")}
                    name="organization"
                    options={organizations || []}
                    control={control}
                    isInputChange
                    onInputChange={debouncedFetchCoreOrganizations}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="user"
                  label={t("user")}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={4}>
                <InputField
                  size="small"
                  variant="outlined"
                  name={`${t("note")} 1`}
                  label={`${t("additional_note")} 1`}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  size="small"
                  variant="outlined"
                  name={`${t("note")} 2`}
                  label={`${t("additional_note")} 2`}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="recipient"
                  label={t("recipient")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  size="small"
                  name="countryId"
                  label={t("country")}
                  control={control}
                  options={countriesList || []}
                />
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent={"space-between"}>
                  <Box flex={1}>
                    <Button
                      startIcon={<ListAlt htmlColor="green" />}
                      variant="outlined"
                      color="primary"
                      disabled={actionsDisabled}
                      className={classes.buttonStyle}
                      onClick={() => handleExportParcels(watch())}
                    >
                      {t("export")}
                    </Button>
                  </Box>

                  {userData?.role !== Roles.SuperAdmin && (
                    <Box flex={1}>
                      <Button
                        startIcon={<ListAlt htmlColor="green" />}
                        variant="outlined"
                        color="primary"
                        className={classes.buttonStyle}
                        disabled={actionsDisabled}
                        onClick={() => handleExportCustomersParcels(watch())}
                      >
                        {t("reestr")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                {userData?.role !== Roles.SuperAdmin && (
                  <Box flex={1}>
                    <Button
                      startIcon={<PrintIcon color="primary" />}
                      variant="outlined"
                      onClick={() => handleGetReestrPrintData(watch())}
                      color="primary"
                    >
                      {`${t("print")} ${t("reestr")}`}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Box display={"flex"} justifyContent={"space-between"} pt={2}>
                <Box flex={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.buttonStyle}
                    type="submit"
                  >
                    {t("apply")}
                  </Button>
                </Box>
                <Box flex={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.buttonStyle}
                    onClick={handleClearFilters}
                    disabled={disableClear()}
                  >
                    {t("cancel")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
        </FormProvider>
      </form>
    </Paper>
  );
};

export default Filters;
