import { forwardRef, RefObject, useMemo } from "react";

import HaypostLogo from "src/assets/print-icons/haypost.svg";

import { printStyles } from "./printStyles";
import EMSInstructions from "./components/EMSInstructions";
import EmsEnclosuresArea from "./components/EMSEnclosuresArea";
import { Grid, Box } from "@material-ui/core";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import { format } from "date-fns";
import hsCodeList from "src/assets/constants/hsCodes.json";

const EMS = forwardRef((props: { rightText?: string }, ref) => {
  const classes = printStyles();
  const { printData } = props;

  const {
    street: senderStreet,
    provinceState: senderProvinceState,
    cityVillage: senderCityVillage,
    building: senderBuilding,
    postalCode: senderPostalCode,
    apartment: senderApartment,
    nameSurname: senderNameSurname,
    phone: senderPhone,
    email: senderEmail,
  } = printData.returnSender;

  const {
    country,
    provinceState,
    cityVillage,
    street,
    building,
    postalCode,
    apartment,
    nameSurname,
    phone,
    email,
  } = printData.recipient;

  const enclosuresUpdatedData = useMemo(() => {
    const updatedData = JSON.parse(printData?.enclosures)?.[0]?.Enclosures.map(
      (item) => {
        return {
          ...item,
          description: hsCodeList.find((hsCode) => hsCode.code === item.HsCode)
            ?.description_en,
        };
      }
    );
    return updatedData;
  }, [JSON.parse(printData?.enclosures)?.[0]?.Enclosures]);

  return (
    <Grid ref={ref as RefObject<HTMLDivElement>}>
      <Grid className={classes.emsMainBorders}>
        <Box className={classes.emsContentArea}>
          <Box
            position="absolute"
            right="-24%"
            top="47%"
            width="300px"
            style={{ transform: "rotate(-90deg)" }}
          >
            {props.rightText}
          </Box>
          <Grid container>
            <Grid item xs={6} />
            <Grid container item xs={6}>
              <Grid item xs={6}>
                <Box
                  width="90%"
                  height="18.52mm"
                  style={{ textAlign: "center", paddingTop: "9px" }}
                >
                  <BarcodeToPrint
                    barcode={printData.trackingNumber}
                    height={25}
                    fontSize={12}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.logoArea}>
                <img src={HaypostLogo} alt="logo" />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Box fontWeight="bold" fontSize="12px">
                Custom Declaration
              </Box>
              <Box fontWeight="bold" fontSize="12px">
                Մաքսային հայտարարագիր
              </Box>
              <Box fontSize="10px" paddingTop="8px">
                <b>Important!</b> See instructions on the back /{" "}
                <b>Ուշադրություն․ տես ետադարձի հրահանգները</b>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box border="1px solid" borderBottom="none">
                <Box
                  borderBottom="1px solid"
                  display="flex"
                  justifyContent="space-between"
                  paddingRight="5px"
                >
                  <Box paddingLeft="5px" paddingBottom="9px">
                    <Box fontSize="9px">
                      Importer&apos;s reference(tax code/VAT No./impoter code){" "}
                    </Box>
                    <Box fontSize="9px">
                      Ներմուծողի տվյալները/ԱԱՀ թիվը, դասիչը (պարտադիր չէ)
                    </Box>
                  </Box>
                  <Box />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingRight="5px"
                >
                  <Box paddingLeft="5px" paddingBottom="9px">
                    <Box fontSize="9px">
                      Importer&apos;s telephone/fax/email{" "}
                    </Box>
                    <Box fontSize="9px">Ներմուծողի հեռախոս/ֆաքս/էլ․ փոստ</Box>
                  </Box>
                  <Box />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box border="1px solid" display="flex" position="relative">
            <Box
              position="absolute"
              left="-32px"
              top="-1px"
              borderTop="1px solid"
            >
              <Box>From</Box>
              <Box>Ումից</Box>
            </Box>
            <Box width="94.260458333mm" borderRight="1px solid">
              <Box display="flex">
                <Grid item xs={8}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="3px 5px"
                    borderBottom="1px solid"
                  >
                    <Box>Name/Անուն</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {printData?.returnSender?.nameSurname
                        ? printData?.returnSender?.nameSurname
                        : printData?.returnSender?.legalName}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="3px 5px"
                    borderBottom="1px solid"
                  >
                    <Box>Street/Փողոց</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {senderStreet} {senderBuilding} {senderApartment}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="3px 5px"
                  >
                    <Box>PostCode/Դասիչ</Box>
                    <Box
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {senderPostalCode}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ borderLeft: "1px solid", paddingLeft: "5px" }}
                >
                  <Box fontSize="9px">Sender’s customs reference</Box>
                  <Box fontSize="9px"> Ուղղարկողի մաքսային տվյալները</Box>
                  <Box fontWeight="bold" />
                </Grid>
              </Box>
              <Box display="flex" borderTop="1px solid">
                <Box
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  padding="3px 5px"
                >
                  <Box>Country/Երկիր</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    Armenia
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  padding="3px 5px"
                >
                  <Box>City/Քաղաք</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {senderProvinceState} {senderCityVillage}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" borderTop="1px solid">
                <Box
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  padding="3px 5px"
                >
                  <Box>Tel./Հեռ.</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {senderPhone}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  padding="3px 5px"
                >
                  <Box>Email/Էլ․ հասցե</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {senderEmail}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width="93.260458333mm" display="flex">
              <Box borderRight="1px solid" width="8mm" textAlign="center">
                <Box>To</Box>
                <Box>Ում</Box>
              </Box>
              <Box width="85.260458333mm">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="3px 5px"
                  borderBottom="1px solid"
                >
                  <Box>Name/Անուն</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {printData?.recipient?.nameSurname
                      ? printData?.recipient?.nameSurname
                      : printData?.recipient?.legalName}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="3px 5px"
                  borderBottom="1px solid"
                >
                  <Box>Street/Փողոց</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {street} {building} {apartment}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="3px 5px"
                >
                  <Box>PostCode/Դասիչ</Box>
                  <Box
                    fontWeight="bold"
                    className={classes.lettersSize6}
                    display="flex"
                    alignItems="center"
                  >
                    {postalCode}
                  </Box>
                </Box>
                <Box display="flex" borderTop="1px solid">
                  <Box
                    display="flex"
                    width="50%"
                    justifyContent="space-between"
                    padding="3px 5px"
                  >
                    <Box>Country/Երկիր</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {printData?.country?.name}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width="50%"
                    justifyContent="space-between"
                    padding="3px 5px"
                  >
                    <Box>City/Քաղաք</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {provinceState} {cityVillage}
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" borderTop="1px solid">
                  <Box
                    display="flex"
                    width="50%"
                    justifyContent="space-between"
                    padding="3px 5px"
                  >
                    <Box>Tel./Հեռ.</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {phone}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    width="50%"
                    justifyContent="space-between"
                    padding="3px 5px"
                  >
                    <Box>Email/Էլ․ հասցե</Box>
                    <Box
                      fontWeight="bold"
                      className={classes.lettersSize6}
                      display="flex"
                      alignItems="center"
                    >
                      {email}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <EmsEnclosuresArea />
          <Box>
            {enclosuresUpdatedData.length
              ? enclosuresUpdatedData.slice(0, 2).map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    border="1px solid"
                    borderTop="none"
                    height="5mm"
                    className={classes.emsEnclosuresArea}
                  >
                    <Box
                      width="28%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.description}
                    </Box>
                    <Box
                      width="12%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.Quantity}
                    </Box>
                    <Box
                      width="16%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {parseInt(printData.weight) / 1000} Kg
                    </Box>
                    <Box
                      width="12%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.Amount} AMD
                    </Box>
                    <Box
                      width="17.7%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.HsCode}
                    </Box>
                    <Box
                      width="18%"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.CountryOfOrigin}
                    </Box>
                  </Box>
                ))
              : ""}
          </Box>
          <Box
            borderLeft="1px solid"
            borderBottom="1px solid"
            height="12mm"
            display="flex"
          >
            <Box width="28.1%" height="100%" borderRight="1px solid" />
            <Box width="11%" height="100%" borderRight="1px solid" />
            <Box
              width="11%"
              height="100%"
              borderRight="1px solid"
              fontSize="9px"
            >
              <Box position="relative">
                <Box paddingLeft="5px">
                  <p>Total gross weight</p>
                  <p className={classes.lettersSize8}>Ընդհանուր համաքաշ</p>
                  <p style={{ fontWeight: "bold" }}>
                    {parseInt(printData.weight) / 1000} Kg
                  </p>
                </Box>
                <Box position="absolute" bottom="0" right="0">
                  21
                </Box>
              </Box>
            </Box>
            <Box
              width="15.5%"
              height="100%"
              borderRight="1px solid"
              fontSize="9px"
            >
              <Box position="relative">
                <Box paddingLeft="5px">
                  <p>Total value</p>
                  <p className={classes.lettersSize8}>Ընդհանուր արժեք</p>
                  <p style={{ fontWeight: "bold" }}>{printData.amount} AMD</p>
                </Box>
                <Box position="absolute" top="0" right="0">
                  23
                </Box>
              </Box>
            </Box>
            <Box borderRight="1px solid" width="34.5%">
              <Box height="6.5mm" borderBottom="1px solid" display="flex">
                <Box width="49.5%" borderRight="1px solid" />
                <Box width="50%" height="100%" />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                paddingLeft="5px"
                height="5mm"
                fontWeight="bold"
                fontSize="10px"
              >
                Acceptance information/Ընդունման տվյալներ
              </Box>
            </Box>
          </Box>
          {/* Bottom Area  */}
          <Box display="flex">
            {/* Bottom left Area  */}
            <Box borderLeft="1px solid" width="65.6%" borderRight="1px solid">
              <Box display="flex" borderBottom="1px solid" height="10.6mm">
                <Box textAlign="left">
                  <div className={classes.cp72HeaderArea}>
                    <p className={classes.lettersSize9}>
                      Category of item/Առաքանու բնույթ
                    </p>
                  </div>
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0", height: "3mm" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes3}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px", paddingTop: "3px" }}
                    >
                      <p className={classes.lettersSize9}>Gift/Նվեր</p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0", height: "3mm" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes4}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px", paddingTop: "6px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Documents/Փաստաթղթեր
                        <span
                          className={classes.lettersSize8}
                          style={{ paddingLeft: "2.5mm" }}
                        >
                          26
                        </span>
                      </p>
                    </div>
                  </div>
                </Box>
                <Box textAlign="left" paddingLeft="1mm">
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes5}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Comercial sample/Կոմերցիոն նմուշ
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes6}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px", paddingTop: "3px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Returned goods/Ապրանքների վերադարձ
                      </p>
                    </div>
                  </div>
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes7}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px", paddingTop: "6px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Sale of goods/Ապրանքների վաճառք
                      </p>
                    </div>
                  </div>
                </Box>
                <Box
                  fontSize="8px"
                  borderLeft="1px solid"
                  marginLeft="1mm"
                  paddingLeft="1mm"
                >
                  Explanation/Բացատրություն
                </Box>
              </Box>
              <Box
                height="12mm"
                borderBottom="1px solid"
                fontSize="10px"
                paddingLeft="5px"
                paddingTop="5px"
              >
                <Box justifyContent="space-between" display="flex">
                  <Box>
                    <p>Comments</p>
                    <p>Նշումներ.</p>
                  </Box>
                  <Box>27</Box>
                </Box>
                <Box fontWeight="bold" className={classes.lettersSize6} />
              </Box>
              <Box display="flex" borderBottom="1px solid">
                <Box textAlign="left" borderRight="1px solid" width="33.33%">
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes9}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Licence/Թույլտվություն
                        <span style={{ paddingLeft: "7mm" }}>28</span>
                      </p>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                    <p className={classes.lettersSize9}>
                      No(s). of licence(s)/
                    </p>
                    <p className={classes.lettersSize9}>
                      Թույլտվության(ների) թիվ
                    </p>
                  </div>
                </Box>
                <Box textAlign="left" borderRight="1px solid" width="33.33%">
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes9}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Certificate/Հավաստագիր
                        <span style={{ paddingLeft: "5mm" }}>29</span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <p className={classes.lettersSize9}>
                      No(s). of certificate(s)/
                    </p>
                    <p className={classes.lettersSize9}>
                      Հավաստագրի(-ների) թիվ
                    </p>
                  </div>
                </Box>
                <Box textAlign="left" width="33.33%">
                  <div
                    className={classes.cp72CheckboxArea}
                    style={{ paddingLeft: "0" }}
                  >
                    <div
                      className={`${classes.square} ${classes.squareSizes9}`}
                    />
                    <div
                      className={
                        classes.cp72FourthSectionFirstColumnServiceType
                      }
                      style={{ paddingLeft: "20px" }}
                    >
                      <p className={classes.lettersSize9}>
                        Invoice/Ապրանքագիր
                        <span style={{ paddingLeft: "8.5mm" }}>30</span>
                      </p>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                    <p className={classes.lettersSize9}>
                      No(s). of invoice(s)/
                    </p>
                    <p className={classes.lettersSize9}>Ապրանքագրի թիվը</p>
                  </div>
                </Box>
              </Box>
              <Box
                paddingLeft="5px"
                position="relative"
                borderBottom="1px solid"
              >
                <Box position="absolute" right="0" bottom="0">
                  31
                </Box>
                <Box fontSize="9px">
                  I certify that the particulars given in this customs
                  declaration are correct and that this item does not contain
                  any dangerous article prohibited by legislation or by postal
                  or customs regulations/Հավաստիացնում եմ,որ սույն մաքսային
                  հայտարարագրում ներկայացված տեղեկությունները ճիշտ են, և որ այս
                  առաքանին չի պարունակում փոստային կամ մաքսային կանոնակարգով կամ
                  օրենսդրությամբ արգելված վտանգավոր իր
                </Box>
                <Box padding="5px 0" fontSize="9px">
                  Date and signature/Ամսաթիվ և ուղղարկողի ստորագրություն
                </Box>
              </Box>
            </Box>
            {/* Bottom right Area  */}
            <Box
              width="34.4%"
              fontSize="10px"
              borderRight="1px solid"
              borderBottom="1px solid"
            >
              <Box
                display="flex"
                padding="2px 5px 3px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>Item weight(kg)/Առաքանու քաշը</Box>
                <Box fontWeight="bold" fontSize="11px">
                  {parseInt(printData?.weight) / 1000} Kg
                </Box>
              </Box>
              <Box
                display="flex"
                padding="1px 5px 1px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>
                  <Box>Postal charges/Fees/</Box>
                  <Box>Փոստային վճարներ/գումար</Box>
                </Box>
                <Box fontWeight="bold" fontSize="11px">
                  {printData.amount}
                </Box>
              </Box>
              <Box
                display="flex"
                padding="2px 5px 3px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>Insurance/Ապահովագրություն</Box>
                <Box fontWeight="bold" fontSize="11px" />
              </Box>
              <Box
                display="flex"
                padding="2px 5px 3px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>Total/Ընդամենը</Box>
                <Box fontWeight="bold" fontSize="11px">
                  {printData.amount} AMD
                </Box>
              </Box>
              <Box
                display="flex"
                padding="2px 5px 3px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>Office/Գրասենյակ</Box>
                <Box fontWeight="bold" fontSize="11px" />
              </Box>
              <Box borderBottom="1px solid" display="flex">
                <Box
                  display="flex"
                  padding="2px 0px 3px 1px"
                  borderRight="1px solid"
                  width="50%"
                  justifyContent="space-between"
                >
                  <Box>Date/Ամսաթիվ</Box>
                  <Box fontWeight="bold">
                    {format(new Date(printData?.creationDate), "dd/MM/yyyy")}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  padding="2px 5px 3px 5px"
                  width="50%"
                  justifyContent="space-between"
                >
                  <Box>Time/Թիվ</Box>
                  <Box fontWeight="bold" fontSize="11px" />
                </Box>
              </Box>
              <Box
                fontSize="10px"
                padding="3px 0 3px 5px"
                borderBottom="1px solid"
                fontWeight="bold"
              >
                Delivery information/Առաքման տվյալներ
              </Box>
              <Box borderBottom="1px solid" display="flex">
                <Box
                  display="flex"
                  padding="2px 5px 3px 5px"
                  borderRight="1px solid"
                  width="50%"
                  justifyContent="space-between"
                >
                  <Box>Date/Ամսաթիվ</Box>
                  <Box fontWeight="bold" fontSize="11px" />
                </Box>
                <Box
                  display="flex"
                  padding="2px 5px 3px 5px"
                  width="50%"
                  justifyContent="space-between"
                >
                  <Box>Time/Թիվ</Box>
                  <Box fontWeight="bold" fontSize="11px" />
                </Box>
              </Box>
              <Box
                display="flex"
                padding="2px 5px 3px 5px"
                borderBottom="1px solid"
                justifyContent="space-between"
              >
                <Box>Person name/Անձի անունը</Box>
                <Box fontWeight="bold" fontSize="11px">
                  {senderNameSurname}
                </Box>
              </Box>
              <Box
                display="flex"
                padding="2px 5px 4px 5px"
                justifyContent="space-between"
              >
                <Box>Signature/Ստորագրություն</Box>
                <Box fontWeight="bold" fontSize="11px" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      {enclosuresUpdatedData.length > 2 && (
        <Grid className={classes.emsMainBorders}>
          <Box className={classes.emsContentArea}>
            <EmsEnclosuresArea />
            {enclosuresUpdatedData.length
              ? enclosuresUpdatedData.slice(2).map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    border="1px solid"
                    borderTop="none"
                    height="5mm"
                    className={classes.emsEnclosuresArea}
                  >
                    <Box
                      width="28%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.description}
                    </Box>
                    <Box
                      width="12%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.Quantity}
                    </Box>
                    <Box
                      width="16%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.Weight / 1000} Kg
                    </Box>
                    <Box
                      width="12%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.Amount} AMD
                    </Box>
                    <Box
                      width="17.7%"
                      borderRight="1px solid"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {printData.amount}
                    </Box>
                    <Box
                      width="18%"
                      className={classes.lettersSize6}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.CountryOfOrigin}
                    </Box>
                  </Box>
                ))
              : ""}
          </Box>
        </Grid>
      )}
      <EMSInstructions />
    </Grid>
  );
});

export default EMS;
