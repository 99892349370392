import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Button, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.hover,
    },
    title: {
        marginRight: 'auto',
        fontSize: 18,
        color: theme.palette.secondary.hover,
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.primary.main,
        },
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
}));

const NavItem = ({ className, href, icon: Icon, title, role, ...rest }) => {
    const classes = useStyles();

    if (role) {
        return (
            <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
                <Button activeClassName={classes.active} className={classes.button} component={RouterLink} to={href}>
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                </Button>
            </ListItem>
        );
    } else {
        return null;
    }
};

NavItem.defaultProps = {
    role: true,
};

export default NavItem;
