import { Chip, colors } from "@material-ui/core";
import { Typography } from "antd";
import { format } from "date-fns";

const translatedColumns = (t) => {
  return [
    {
      field: "name",
      title: t("naming"),
    },
    {
      field: "coreCustomerId",
      title: t("phoenix_customer_number"),
    },
    { field: "address", title: t("address") },
    { field: "hvhh", title: t("hvhh") },
    {
      field: "active",
      title: t("status"),
      layout: (row) => (
        <Chip
          style={{
            backgroundColor: row.active ? colors.green[400] : colors.red[400],
            color: colors.common.white,
          }}
          label={row.active ? t("active") : t("inactive")}
        />
      ),
    },
    {
      field: "createdDate",
      title: t("creation_date"),
      layout: (row) => (
        <Typography>
          {format(new Date(row.createdDate), "dd/MM/yyyy HH:mm")}
        </Typography>
      ),
    },
  ];
};

const operatorTypes = [
  {
    id: 1,
    name: "Supervisor",
  },
  { id: 2, name: "Authorized" },
  { id: 3, name: "Standard" },
];

export { translatedColumns, operatorTypes };
