import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import thunkOptions from "store/config/thunkOptions";
import api from "helpers/axios";
import { EBaseUrl } from "store/config/constants";
import { IOrderState } from "../models/interfaces";
import toast from "react-hot-toast";

const name = "ORDER";
const initialState: IOrderState = {
  orders: [],
  excelOrdersData: [],
  enclosuresData: [],
  orderDetailData: null,
  parcelsDataForPrint: null,
  isEnclosuresEdited: false,
};

export const fetchOrders = createAsyncThunk(
  `${name}/fetchOrders`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/Parcel/Grid`, formData)).data,
  thunkOptions
);

export const updateOrder = createAsyncThunk(
  `${name}/updateOrder`,
  async ({ orderId, formData }) =>
    (await api.put(`${EBaseUrl.Envelope}/Parcel/${orderId}`, formData)).data,
  thunkOptions
);

export const createOrder = createAsyncThunk(
  `${name}/createOrder`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/Parcel`, formData)).data,
  thunkOptions
);

export const getOrderPrice = createAsyncThunk(
  `${name}/getOrderPrice`,
  async (params) =>
    (await api.get(`${EBaseUrl.Envelope}/Parcel/GetParcelPrice?${params}`))
      .data,
  thunkOptions
);

export const getOrderDetailById = createAsyncThunk(
  `${name}/getOrderDetailById`,
  async (orderId) =>
    (await api.get(`${EBaseUrl.Envelope}/Parcel/${orderId}`)).data,
  thunkOptions
);

export const exportParcels = createAsyncThunk(
  `${name}/exportParcels`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/ExcelExportParcels`,
        formData,
        {
          responseType: "blob",
        }
      )
    ).data,
  thunkOptions
);

export const exportCustomerParcels = createAsyncThunk(
  `${name}/exportParcels`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/ExcelExportCustomerParcels`,
        formData,
        {
          responseType: "blob",
        }
      )
    ).data,
  thunkOptions
);

export const exportCustomerParcelsByIds = createAsyncThunk(
  `${name}/exportCustomerParcelsByIds`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/ExcelExportCustomerParcelsByIds`,
        formData,
        {
          responseType: "blob",
        }
      )
    ).data,
  thunkOptions
);

export const orderExcelImport = createAsyncThunk(
  `${name}/orderExcelImport`,
  async ({ formData, returnSenderId }) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/ImportExcel/${returnSenderId}`,
        formData,
        {
          hasFileUpload: true,
        }
      )
    ).data,
  thunkOptions
);

export const approveOrders = createAsyncThunk(
  `${name}/approveOrders`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/Parcel/SendParcelToCore`, formData))
      .data,
  thunkOptions
);

export const removeOrder = createAsyncThunk(
  `${name}/removeOrder`,
  async (orderIds) =>
    (await api.delete(`${EBaseUrl.Envelope}/Parcel`, { data: orderIds })).data,
  thunkOptions
);

export const getOrderReceipt = createAsyncThunk(
  `${name}/getOrderReceipt`,
  async (orderIds) =>
    (await api.post(`${EBaseUrl.Envelope}/Parcel/Receipt`, orderIds)).data,
  thunkOptions
);

export const getOrderHistory = createAsyncThunk(
  `${name}/getOrderHistory`,
  async (trackingId: number) =>
    (
      await api.get(
        `${EBaseUrl.Envelope}/Parcel/CoreOrderLocationsByTrackingId?trackingId=${trackingId}`
      )
    ).data,
  thunkOptions
);

export const getCN07PDF = createAsyncThunk(
  `${name}/getCN07PDF`,
  async (formData) =>
    (
      await api.post(`${EBaseUrl.Envelope}/Parcel/ImportExcel/CN07`, formData, {
        hasFileUpload: true,
        responseType: "blob",
      })
    ).data,
  thunkOptions
);

export const getParcelsPDFDataByIDs = createAsyncThunk(
  `${name}/getParcelsPDFDataByIDs`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/PdfExportCustomerParcelsByIds`,
        formData
      )
    ).data,
  thunkOptions
);

export const getParcelsPDFDataByFilters = createAsyncThunk(
  `${name}/getParcelsPDFDataByFilters`,
  async (formData) =>
    (
      await api.post(
        `${EBaseUrl.Envelope}/Parcel/PdfExportCustomerParcels`,
        formData
      )
    ).data,
  thunkOptions
);

export const getOrderDetailByIdV2 = createAsyncThunk(
  `${name}/getOrderDetailByIdV2`,
  async (orderId) =>
    (await api.get(`${EBaseUrl.Envelope}/Parcel/${orderId}/V2`)).data,
  thunkOptions
);

const OrderSlicer = createSlice({
  name,
  initialState,
  reducers: {
    setExcelOrders: (state, { payload: { parcels, problematicCases } }) => {
      if (problematicCases?.length) {
        const errorsList = problematicCases.map(
          (item) => `${item.rowNumber}-րդ տողում առկա է խնդիր `
        );
        toast.error(errorsList.join(","), { duration: 6000 });
      }
      state.excelOrdersData = parcels;
    },
    setEnclosuresData: (state, { payload }) => {
      state.enclosuresData = payload;
    },
    setEnclosuresEdited: (state, { payload }) => {
      state.isEnclosuresEdited = payload;
    },
  },
  extraReducers(build) {
    build.addCase(fetchOrders.fulfilled, (state, { payload }) => {
      state.orders = payload;
    });
    build.addCase(getOrderDetailById.fulfilled, (state, { payload }) => {
      state.orderDetailData = payload;
    });
    build.addCase(getParcelsPDFDataByIDs.fulfilled, (state, { payload }) => {
      state.parcelsDataForPrint = payload;
    });
    build.addCase(
      getParcelsPDFDataByFilters.fulfilled,
      (state, { payload }) => {
        state.parcelsDataForPrint = payload;
      }
    );
  },
});

export const { setExcelOrders, setEnclosuresData, setEnclosuresEdited } =
  OrderSlicer.actions;

export const selectOrders = (state) => state.order.orders;
export const selectOrderDetailData = (state) => state.order.orderDetailData;
export const selectExcelOrders = (state) => state.order.excelOrdersData;
export const selectEnclosuresData = (state) => state.order.enclosuresData;
export const selectIsEnclosuresEdited = (state) =>
  state.order.isEnclosuresEdited;
export const selectParcelsDataForPDF = (state) =>
  state.order.parcelsDataForPrint;

export default OrderSlicer.reducer;
