import { useMemo, useState } from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";
import Table from "shared/ui/Table/index";
import { translatedColumns } from "./data/constants";
import useTable from "helpers/useTable";
import { useSelector, useDispatch } from "react-redux";
import CreateUpdateDialog from "shared/ui/Table/components/createUpdateDialog";
import useTableCrud from "helpers/useTableCrud";
import CreateAddressesForm from "./components/CreateAddressForm";
import {
  fetchAddresses,
  removeAddress,
  selectAddressesList,
} from "store/slicers/address";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "shared/ui/DialogConfirm";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Addresses = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = translatedColumns(t);
  const addressesList = useSelector(selectAddressesList);

  const [confirmRemoveDialogData, setConfirmRemoveDialogData] = useState({
    open: false,
    data: null,
  });

  const handleConfirmRemoveAction = async () => {
    const { meta } = await dispatch(
      removeAddress(confirmRemoveDialogData.data.id)
    );

    if (meta.requestStatus !== "fulfilled") {
      return;
    }

    toast.success(t("address_is_removed"));
    setConfirmRemoveDialogData({
      open: false,
      data: null,
    });
    fetchData();
  };

  const { loading, fetchData, setLoading } = useTable(fetchAddresses);

  const { title, saveAction, closeAction, dialogOpen, updateData } =
    useTableCrud(setLoading, fetchData, "add_address");

  const dropdownActions = useMemo(() => {
    return [
      {
        isDisabled: (row) => row.userDefaultReturnSender === 2,
        icon: <DeleteIcon color="error" />,
        text: t("remove"),
        action: (data) => {
          setConfirmRemoveDialogData({ open: true, data });
        },
      },
    ];
  }, [dialogOpen, dispatch, updateData, t]);

  return (
    <Box component={Paper} p={4}>
      <Box mb={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dialogOpen.set(true)}
        >
          {t("add_address")}
        </Button>
      </Box>
      <Table
        title="office_address"
        isLoading={loading}
        columns={columns}
        dropdownActions={dropdownActions}
        rows={addressesList || []}
      />
      <CreateUpdateDialog
        title={title}
        open={dialogOpen.get}
        onClose={closeAction}
        width={"sm"}
      >
        <CreateAddressesForm
          isLoading={loading}
          onSave={saveAction}
          onClose={closeAction}
          updateData={updateData}
          fetchData={fetchData}
        />
      </CreateUpdateDialog>
      <ConfirmDialog
        isOpen={confirmRemoveDialogData.open}
        onAgree={handleConfirmRemoveAction}
        onClose={() =>
          setConfirmRemoveDialogData({
            open: false,
            data: null,
          })
        }
      >
        <Box mb={4}>
          <Typography variant="h3">{t("attention")}</Typography>
        </Box>
        <Typography variant="h5">
          {" "}
          {`${t("are_you_sure")} ${t("remove_address")}`}
        </Typography>
      </ConfirmDialog>
    </Box>
  );
};

export default Addresses;
