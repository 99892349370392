import { Box, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { forwardRef } from "react";
import BarcodeContainer from "src/components/BarcodeContainer";
import { amountFormatter } from "src/helpers";
import {
  EAdditionalServices,
  ECountriesList,
} from "src/views/Orders/data/constants";
import useStyles from "./styles.ts";

const PrintCN07Format = forwardRef((props, ref) => {
  const classes = useStyles();

  const { activeOrder, showAdditionalNotes, showAdditionalNotes2 } = props;

  const { provinceState, cityVillage, street, building, apartment } =
    activeOrder.returnSender;
  const {
    provinceState: recipientProvinceState,
    cityVillage: recipientCityVillage,
    street: recipientStreet,
    building: recipientBuilding,
    apartment: recipientApartment,
  } = activeOrder.recipient;

  const additionalTrackingNumber = `A${activeOrder?.trackingNumber}`;

  return (
    <div
      className={`${classes.wrap} ${classes.cn07Container}`}
      style={{ marginTop: 10, marginBottom: 5, position: "relative" }}
      ref={ref}
    >
      <div className={`${classes.contain} ${classes.clear}`}>
        <div className={classes.head}>
          <div style={{ margin: "5px 10px" }}>
            Տրման նշանակման օպերատոր /
            <br /> Designated operator of origin
          </div>
          <div style={{ margin: "5px 0 0 -170px" }}>
            ԾԱՆՈՒՑԱԳԻՐ ստացման/հանձնման/վճարման/գրանցման <br /> ADVICE of
            receipt/of delivery/of payment/of entry
          </div>
          <div
            className={classes.textRight}
            style={{ margin: "5px 10px", fontSize: "8px" }}
          >
            <b>CN 07</b>
          </div>
        </div>
        <div className={classes.infoContent}>
          <div style={{ width: "55%" }}>
            <div
              className={`${classes.left} ${classes.floatLeft} ${classes.clear}`}
              style={{
                marginLeft: "3px",
                border: " 1px solid black",
                width: "100%",
              }}
            >
              <div
                className={classes.clear}
                style={{
                  height: "50px",
                  display: "flex",
                  marginLeft: "5px",
                  borderTop: "1px solid black",
                }}
              >
                <span style={{ marginTop: "5px" }}>
                  Առաքանու հասցեատեր/ Addressee of the item
                </span>
                <span style={{ width: "250px", margin: "5px 10px 0" }}>
                  {activeOrder?.recipient?.nameSurname
                    ? activeOrder?.recipient?.nameSurname
                    : activeOrder?.recipient?.legalName}{" "}
                  {recipientProvinceState +
                    " " +
                    recipientCityVillage +
                    " " +
                    recipientStreet +
                    " " +
                    recipientBuilding +
                    " " +
                    (recipientApartment ? recipientApartment : "")}
                </span>
              </div>
            </div>
            <div
              className={classes.clear}
              style={{
                marginLeft: "10px",
                fontWeight: "bold",
                paddingTop: "5px",
                marginBottom: "5px",
              }}
            >
              Առաքանու բնույթը/Nature of the item
            </div>
            <div
              className={`${classes.left} ${classes.border} ${classes.clear} ${classes.bnuyt}`}
              style={{
                marginLeft: "3px",
                paddingTop: "1px",
                paddingBottom: "1px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="printy"
                  name="printy"
                  value=""
                  style={{ margin: 4 }}
                />
                <label htmlFor="printy" style={{ paddingTop: "1px" }}>
                  {" "}
                  Առաջնային նամակ/
                  <br />
                  Priority letter
                </label>
                <br />
              </div>
              <div
                style={{
                  margin: "0 30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input type="checkbox" id="printed" name="printed" value="" />
                <label
                  htmlFor="printed"
                  style={{ paddingTop: "1px", margin: 4 }}
                >
                  {" "}
                  Հրատարակչություն/
                  <br />
                  Printed paper
                </label>
                <br />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="parcel"
                  name="parcel"
                  value=""
                  style={{ margin: 4 }}
                />
                <label htmlFor="parcel" style={{ paddingTop: "1px" }}>
                  {" "}
                  Ծանրոց/
                  <br />
                  Parcel
                </label>
                <br />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="non_prioritet"
                  name="non_prioritet"
                  value=""
                  style={{ margin: 4 }}
                />
                <label htmlFor="non_prioritet">
                  {" "}
                  Ոչ առաջնային նամակ/
                  <br />
                  Non-Priority letter
                </label>
                <br />
              </div>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{ paddingTop: "1px", paddingBottom: "5px" }}
            >
              <div className={classes.bnuyt} style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="registered"
                    name="registered"
                    value=""
                    style={{ margin: 4 }}
                  />
                  <label htmlFor="registered" style={{ paddingTop: "3px" }}>
                    {" "}
                    Պատվիրված/Registered
                  </label>
                  <br />
                </div>
                <div
                  style={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="recorded"
                    name="recorded"
                    style={{ margin: 4 }}
                    value=""
                  />
                  <label htmlFor="recorded" style={{ paddingTop: "3px" }}>
                    Վկայագրված հանձնում/
                    <br />
                    Recorded delivery
                  </label>
                  <br />
                </div>
                <div
                  style={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="insured"
                    name="insured"
                    value=""
                    style={{ margin: 4 }}
                  />
                  <label htmlFor="insured" style={{ paddingTop: "3px" }}>
                    {" "}
                    Գնահատված/
                    <br />
                    Insured
                  </label>
                  <br />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    paddingLeft: "20px",
                  }}
                >
                  Առաքանու թիվը/
                  <br />
                  No of item {activeOrder.trackingNumber}
                </div>

                {!activeOrder?.additionalServiceIds?.includes(
                  EAdditionalServices.SIMPLE_NOTIFICATION
                ) &&
                  !activeOrder?.additionalServiceIds?.includes(
                    EAdditionalServices.ORDERED_NOTIFICATION
                  ) && (
                    <div
                      className={classes.amount}
                      style={{ borderLeft: "1px solid black" }}
                    >
                      Գումար/Amount
                      <span style={{ marginLeft: 10 }}>
                        {amountFormatter(activeOrder.amount.toString())}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{ height: "30px" }}
            >
              <div style={{ float: "left", display: "inline-block" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="prioritet_letter"
                    name="prioritet_letter"
                    value=""
                    style={{ margin: 4 }}
                  />
                  <label
                    htmlFor="prioritet_letter"
                    style={{ paddingTop: "3px", paddingRight: " 18px" }}
                  >
                    {" "}
                    Հասարակ դրամական փոխադրություն/
                    <br />
                    Ordinary money order
                  </label>
                </div>
              </div>
              {!activeOrder?.additionalServiceIds?.includes(
                EAdditionalServices.SIMPLE_NOTIFICATION
              ) &&
                !activeOrder?.additionalServiceIds?.includes(
                  EAdditionalServices.ORDERED_NOTIFICATION
                ) && (
                  <div className={classes.amount}>
                    Գումար/Amount{" "}
                    <span style={{ marginLeft: 10 }}>
                      {amountFormatter(activeOrder.amount.toString())}
                    </span>
                  </div>
                )}
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{
                paddingTop: "1px",
                paddingBottom: "3px",
                display: "flex",
              }}
            >
              <Box display="flex" justifyContent={"space-between"}>
                <div
                  style={{
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="inpayment"
                    name="inpayment"
                    style={{ margin: 4 }}
                    value=""
                  />
                  <label htmlFor="inpayment" style={{ paddingTop: "3px" }}>
                    {" "}
                    Փոխանցման անդորրագիր/
                    <br />
                    Inpayment money order
                  </label>
                  <br />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="outpayment"
                    name="outpayment"
                    value=""
                    style={{ margin: 4 }}
                  />
                  <label htmlFor="outpayment" style={{ paddingTop: "3px" }}>
                    {" "}
                    Վճարման անդորրագիր/
                    <br />
                    Outpayment cheque
                  </label>
                  <br />
                </div>
              </Box>
            </div>
            <div
              className={classes.clear}
              style={{
                marginLeft: "10px",
                marginTop: "5px",
                marginBottom: "2px",
                fontWeight: "bold",
              }}
            >
              Լրացվում է նշանակման վայրում/To be completed at the point of
              destination
            </div>
            <div className={`${classes.border} ${classes.left}`}>
              <div style={{ padding: "1px 1px 9px 10px" }}>
                Վերոնշված առաքանին պատշաճ կերպով/The item mentioned above has
                been duly
              </div>
              <div
                className={classes.bnuyt}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 10px",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="delivered"
                    name="delivered"
                    style={{ margin: 4 }}
                    value=""
                  />
                  <label htmlFor="delivered">
                    հանձնվել է/
                    <br />
                    delivered
                  </label>
                  <br />
                </div>
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="paid"
                    name="paid"
                    value=""
                    style={{ margin: 4 }}
                  />
                  <label htmlFor="paid">
                    վճարվել է/
                    <br />
                    paid
                  </label>
                  <br />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="giro_account"
                    name="giro_account"
                    style={{ margin: 4 }}
                    value=""
                  />
                  <label htmlFor="giro_account">
                    գրանցվել է ընթացիկ փոստային հաշվին/
                    <br />
                    credited to giro account
                  </label>
                  <br />
                </div>
              </div>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
            >
              <div style={{ height: "30px", paddingLeft: "5px" }}>
                Ամսաթիվ և ստորագրություն/Date and signature
                <span style={{ marginLeft: 10 }}></span>
              </div>
            </div>
            <p style={{ padding: "0px 0 1px 5px", fontSize: "7px" }}>
              Այս ծանուցագիրը կարող է ստորագրվել հասցեատիրոջ, կամ եթե նշանակման
              երկրի կանոննակարգը նախատեսում է այդ, այլ լիազորված անձի կողմից/
              The advice may be signed by the addressee or,if the regulations of
              the country of destination so provide by another authorized
              person.
            </p>
          </div>
          <div
            className={classes.vertical}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            Լրացվում է ուղարկողի կողմից/To be filled in by the receiver
          </div>
          <div style={{ width: "40%", marginLeft: "25px" }}>
            <div className={classes.rightHead} style={{ display: "flex" }}>
              <div
                className={classes.floatLeft}
                style={{ margin: "0 10px 3px 100px" }}
              >
                <b className={classes.ar}>A.R.</b>
              </div>
              <div className="">
                <div>
                  Ծառայողական/
                  <br />
                  On postal service
                  <br />
                  Ծանուցագիրը վերադարձնող
                  <br /> գրասենյակի կնիքը/ <br /> On postal service
                </div>
              </div>
            </div>
            <div className={`${classes.clear} ${classes.rightNoBorder}`}>
              <div>
                Առաջնային/Priority
                <br />
                Օդային/By airmail
              </div>
              <div>
                Վերադարձնել /<br />
                Return to
              </div>
            </div>
            <div
              className={`${classes.border} ${classes.left}`}
              style={{ display: "flex" }}
            >
              <span>
                {" "}
                <div style={{ paddingLeft: "5px" }}>
                  Անուն կամ գրասենյակի անվանում/ Name
                </div>
              </span>
              <span style={{ marginLeft: "10px" }}>
                <div>
                  {activeOrder?.returnSender?.legalName
                    ? activeOrder?.returnSender?.legalName
                    : activeOrder?.returnSender?.nameSurname}
                </div>
              </span>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{ display: "flex" }}
            >
              <div style={{ paddingLeft: "5px", width: "70px" }}>
                Փողոց և թիվ/
                <br />
                Street and No
              </div>
              <span style={{ marginLeft: "10px" }}>
                {" "}
                <div>
                  <div>
                    {street +
                      " " +
                      building +
                      " " +
                      (apartment ? apartment : "")}{" "}
                    {activeOrder?.returnSender?.postalCode}
                  </div>
                </div>
              </span>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{ display: "flex" }}
            >
              <div style={{ paddingLeft: "5px", width: "100px" }}>
                Բնակավայր և երկիր/
                <br />
                Locality and country
              </div>
              <span style={{ marginLeft: "10px", width: "180px" }}>
                {" "}
                <div>
                  {activeOrder?.country?.id === ECountriesList.ARMENIA
                    ? "Հայաստան"
                    : "Armenia"}{" "}
                  {provinceState + " " + cityVillage}{" "}
                </div>
              </span>
            </div>
            <div
              className={`${classes.border} ${classes.left} ${classes.borderTopNon}`}
              style={{ display: "flex" }}
            >
              <div
                style={{ height: "35px", paddingLeft: "5px", width: "100px" }}
              />
            </div>
            {!activeOrder?.additionalServiceIds?.includes(
              EAdditionalServices.SIMPLE_NOTIFICATION
            ) &&
              activeOrder?.country?.id === ECountriesList.ARMENIA && (
                <div
                  style={{
                    fontSize: "18px",
                    marginLeft: "15px",
                  }}
                >
                  <BarcodeContainer
                    code={additionalTrackingNumber}
                    height={60}
                  />
                </div>
              )}
            {activeOrder?.additionalServiceIds?.includes(
              EAdditionalServices.SIMPLE_NOTIFICATION
            ) &&
              activeOrder?.country?.id === ECountriesList.ARMENIA && (
                <Box fontSize={"16px"} textAlign="center" mt={4}>
                  {activeOrder?.trackingNumber}
                </Box>
              )}
          </div>
        </div>
        {activeOrder?.note1 || activeOrder?.note2 ? (
          <Box marginLeft="10px">
            {showAdditionalNotes && (
              <Box>
                <Box style={{ fontSize: 10 }}>
                  {"Հավելյալ Նշում 1՝ "} {activeOrder?.note1}
                </Box>
              </Box>
            )}
            {showAdditionalNotes2 && (
              <Box>
                <Box style={{ fontSize: 10 }}>
                  {"Հավելյալ Նշում 2՝ "} {activeOrder?.note2}
                </Box>
              </Box>
            )}
          </Box>
        ) : null}
      </div>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography style={{ fontSize: 10 }}>
          powered by Envelope, envelope.haypost.am
        </Typography>
      </Box>
    </div>
  );
});

export default PrintCN07Format;
