import {
  Fragment,
  useCallback,
  KeyboardEvent,
  ClipboardEvent,
  Ref,
} from "react";
import { TextField, InputAdornment, InputLabel } from "@material-ui/core";
import {
  Controller,
  useFormContext,
  Control,
  FieldErrors,
} from "react-hook-form";
import CrossIcon from "@material-ui/icons/Clear";
import moment from "moment";

interface IInputProps {
  multiline?: boolean;
  size?: "small" | "medium";
  className?: string;
  name: string;
  label: string;
  control: Control;
  placeholder?: string;
  errors?: FieldErrors;
  type?: string;
  margin?: "none" | "dense" | "normal";
  InputProps?: any;
  clearable?: boolean;
  rules?: any;
  InputLabelProps?: any;
  onClear?: () => void;
  onKeyPress?: (parameter: KeyboardEvent<HTMLDivElement>) => void;
  onPaste?: (param: ClipboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  customErrorMessage?: string;
}

const InputField = ({
  multiline,
  size,
  className,
  name,
  label,
  control,
  placeholder = "",
  errors,
  type,
  margin = "none",
  InputProps,
  clearable = false,
  rules,
  onKeyPress,
  onClear,
  inputRef,
  InputLabelProps,
  disabled,
  onPaste,
  customErrorMessage,
}: IInputProps) => {
  const handleClearInput = (field: any) => {
    field.onChange((e: any) => (field.value = ""));
    onClear?.();
  };
  const formContext = useFormContext();

  const errorInfo = useCallback(() => {
    const lastErrors = formContext?.formState?.errors || errors;
    if (name.includes(".")) {
      const [inputName, nestedName] = name.split(".");
      return lastErrors?.[inputName]?.[nestedName];
    }

    return lastErrors?.[name];
  }, [errors, formContext?.formState?.errors, name]);

  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            size={size}
            multiline={multiline}
            className={className}
            inputRef={inputRef}
            value={field.value ?? ""}
            placeholder={placeholder}
            type={type || "text"}
            error={
              (!customErrorMessage ? !!errorInfo?.() : !!customErrorMessage) &&
              !disabled
            }
            helperText={
              !disabled &&
              (!customErrorMessage
                ? errorInfo?.()?.message
                : customErrorMessage)
            }
            fullWidth
            label={label}
            margin={margin}
            variant="outlined"
            disabled={disabled}
            InputProps={{
              ...InputProps,
              ...(clearable && field.value  ? {
                endAdornment: 
                <InputAdornment
                  position={"end"}
                  onClick={() => handleClearInput(field)}
                >
                  <div style={{ cursor: "pointer" }}>
                    <CrossIcon />
                  </div>
                </InputAdornment>
              } : {})
            }}
            InputLabelProps={InputLabelProps}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
          />
        )}
      />
    </Fragment>
  );
};

export default InputField;
