import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Page from "../../components/Page";
import { Box, Typography } from "@material-ui/core";
import ButtonLoader from "../../components/ButtonLoader";
import toast from "react-hot-toast";

import SetPassword from "./SetPassword";
import { resetPassword } from "store/slicers/auth";
import Login from "./Login";

import useStyles from "./style";
import { EAutPage } from "./constants";

import Logo from "../../assets/images/logo.png";
import useQueryParams from "src/helpers/useQueryParams";
import { emailRegex } from "src/helpers/validators";
import InputField from "src/shared/ui/Input";
import { useForm } from "react-hook-form";
import { LStorage } from "src/store/config/constants";
import { useTranslation } from "react-i18next";

function EmailField({ onForgotHandler }: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const onSubmit = async (formData) => {
    setIsLoading(true);
    setIsLoading(true);
    const { meta } = await dispatch(
      resetPassword({
        email: formData.email,
      })
    );

    if (meta.requestStatus !== "fulfilled") {
      return setIsLoading(false);
    }

    toast.success(t("password_successfully_deleted"));
    setIsLoading(false);
  };

  const goBackHandler = () => {
    onForgotHandler(false);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Box mb={3} mt={3}>
        <Typography className={classes.formTitle}>
          {t("password_restoring")}
        </Typography>
      </Box>

      <InputField
        name="email"
        label={t("email")}
        control={control}
        rules={{
          required: {
            value: true,
            message: t("required_field"),
          },
          pattern: { value: emailRegex, message: t("wrong_email") },
        }}
        errors={errors}
      />

      <Box my={2}>
        <ButtonLoader
          fullWidth
          color="primary"
          variant="contained"
          form="login"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        >
          {t("restore_password")}
        </ButtonLoader>
      </Box>

      <Typography onClick={goBackHandler} className={classes.goBack}>
        {t("login")}
      </Typography>
    </form>
  );
}

const CheckPage = ({ queryParams, onForgotHandler, isReset, reset }) => {
  if (queryParams?.hash) {
    return (
      <SetPassword hash={queryParams.hash} isReset={!!queryParams?.isReset} />
    );
  }

  if (isReset) {
    return <EmailField onForgotHandler={onForgotHandler} />;
  }

  return <Login onForgotHandler={onForgotHandler} />;
};

const AuthDashboard = () => {
  const [hash, setHash] = useState();
  const [page, setPage] = useState(EAutPage.LOGIN);
  const [reset, setReset] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [getQueries] = useQueryParams();

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams.entries());

    setHash(params.hash);
    setReset(params.reset);
  }, [location]);

  const onForgotHandler = (flag) => {
    setIsReset(flag);
  };

  return (
    <Fragment>
      {!localStorage.getItem(LStorage.accessToken) ? (
        <Page className={classes.root}>
          <Grid container className={classes.container}>
            <Grid item md={6} xs={12} className={classes.leftColumn}>
              <Box m={4}>
                <img style={{ height: 40 }} src={Logo} alt={""} />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.rightColumn}>
              <CheckPage
                page={page}
                isReset={isReset}
                queryParams={getQueries()}
                hash={hash}
                onForgotHandler={onForgotHandler}
                reset={reset}
              />
            </Grid>
          </Grid>
        </Page>
      ) : (
        <Navigate to="/admin/main" />
      )}
    </Fragment>
  );
};

export default AuthDashboard;
