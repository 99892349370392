import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../helpers/axios";
import { EBaseUrl } from "../config/constants";
import thunkOptions from "../config/thunkOptions";
import { IOrganizationState } from "../models/interfaces";

const name = "ORGANIZATIONS";

export const fetchOrganizations = createAsyncThunk(
  `${name}/fetchOrganizations`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/Organization/Grid`, formData)).data,
  thunkOptions
);

export const fetchCoreCustomerIds = createAsyncThunk(
  `${name}/fetchCoreCustomerIds`,
  async ({ term, count = 10 }) =>
    (
      await api.get(`${EBaseUrl.Envelope}/Organization/CoreCustomerIds`, {
        params: { term, count },
      })
    ).data,
  thunkOptions
);

export const createOrganization = createAsyncThunk(
  `${name}/createOrganization`,
  async (formData) =>
    (await api.post(`${EBaseUrl.Envelope}/Organization`, formData)).data,
  thunkOptions
);

export const changeOrganizationStatus = createAsyncThunk(
  `${name}/changeOrganizationStatus`,
  async (id) =>
    (await api.patch(`${EBaseUrl.Envelope}/Organization/Status/${id}`)).data,
  thunkOptions
);

export const fetchOrganizationBalances = createAsyncThunk(
  `${name}/fetchOrganizationBalances`,
  async () =>
    (await api.get(`${EBaseUrl.Envelope}/Organization/Balance?type=1`)).data,
  thunkOptions
);

export const fetchOrganizationLogo = createAsyncThunk(
  `${name}/getOrganizationLogo`,
  async (id) => (await api.get(`${EBaseUrl.Envelope}/Organization/Logo`)).data,
  thunkOptions
);

export const fetchOrganizationLogoById = createAsyncThunk(
  `${name}/getOrganizationLogo`,
  async (id) =>
    (await api.get(`${EBaseUrl.Envelope}/Organization/Logo/${id}`)).data,
  thunkOptions
);

export const updateOrganizationLogo = createAsyncThunk(
  `${name}/updateOrganizationLogo`,
  async ({ formData, organizationId }) =>
    (
      await api.patch(
        `${EBaseUrl.Envelope}/Organization/logo/${organizationId}`,
        formData
      )
    ).data,
  thunkOptions
);

export const fetchOrganizationById = createAsyncThunk(
  `${name}/fetchOrganizationById`,
  async (id) => (await api.get(`${EBaseUrl.Envelope}/Organization/${id}`)).data,
  thunkOptions
);

export const removeOrganization = createAsyncThunk(
  `${name}/removeOrganization`,
  async (id) =>
    (await api.delete(`${EBaseUrl.Envelope}/Organization/${id}`)).data,
  thunkOptions
);

export const updateOrganization = createAsyncThunk(
  `${name}/updateOrganization`,
  async (formData) =>
    (
      await api.put(
        `${EBaseUrl.Envelope}/Organization/${formData.id}`,
        formData
      )
    ).data,
  thunkOptions
);

const initialState: IOrganizationState = {
  organizationsList: [],
  balances: [],
};

const OrganizationsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      state.organizationsList = payload;
    });
    builder.addCase(fetchCoreCustomerIds.fulfilled, (state, { payload }) => {
      state.coreCustomerIds = payload;
    });
    builder.addCase(fetchOrganizationLogo.fulfilled, (state, { payload }) => {
      state.organizationLogo = payload?.base64Image;
    });
    builder.addCase(
      fetchOrganizationBalances.fulfilled,
      (state, { payload }) => {
        state.balances = payload;
      }
    );
  },
});

export const selectOrganizations = (state) =>
  state.organizations.organizationsList;

export const selectCoreCustomerIds = (state) =>
  state.organizations.coreCustomerIds;

export const selectOrganizationLogo = (state) =>
  state.organizations.organizationLogo;

export const selectBalances = (state) => state.organizations.balances;

export default OrganizationsSlice.reducer;
