const translatedColumns = (t: any) => [
  {
    field: "phone",
    title: t("phone_number"),
  },
  {
    field: "email",
    title: t("email"),
  },
  {
    field: "nameSurname",
    title: t("naming"),
  },
  {
    field: "cityVillage",
    title: t("cityVillage"),
  },
  {
    field: "street",
    title: t("street"),
  },
  {
    field: "building",
    title: t("building"),
  },
  {
    field: "apartment",
    title: t("apartment"),
  },
  {
    field: "postalCode",
    title: t("postal_code"),
  },
];

const operatorTypes = [
  {
    id: 1,
    name: "Supervisor",
  },
  { id: 2, name: "Authorized" },
  { id: 3, name: "Standard" },
];

export { translatedColumns, operatorTypes };
