import { useForm, FormProvider } from "react-hook-form";
import { makeStyles, Paper, Box, Button, Grid } from "@material-ui/core";
import RangePicker from "shared/ui/RangePicker";
import InputField from "shared/ui/Input";
import useTableFilters from "helpers/useTableFilters";
import SelectField from "shared/ui/Select";
import Autocomplete from "src/shared/ui/Autocomplete";
import useQueryParams from "src/helpers/useQueryParams";
import ListAlt from "@material-ui/icons/ListAlt";
import { debounce, getDefaultDate, params } from "src/helpers";
import { fetchCoreCustomerIds } from "src/store/slicers/organizations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { selectUserData, selectUsers } from "src/store/slicers/users";
import { Roles } from "src/router/routes";
import { useDispatch, useSelector } from "react-redux";
import { defaultQueries } from "src/helpers/useTable";
import { useTranslation } from "react-i18next";

const styles = makeStyles({
  autocomplete: {
    maxWidth: "235px",
    minWidth: "235px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  root: {
    width: "100%",
    marginBottom: "5px",
    padding: "15px",
  },
  filterIcon: {
    marginTop: "15px",
  },
  clearBtn: {
    marginLeft: "20px",
  },
});

const defaultValues = {
  searchText: "",
  name: "",
  surName: "",
  email: "",
  organization: "",
  status: "",
  startDate: getDefaultDate(true),
  endDate: getDefaultDate(false),
};

export const translatedStateOptions = (t: any) => [
  {
    id: "all",
    name: t("all"),
  },
  {
    id: 2,
    name: t("inactive"),
  },
  {
    id: 1,
    name: t("active"),
  },
];

const Filters = ({ fetchData, handleExportUsers }) => {
  const classes = styles();
  const [getQueries, setQueries] = useQueryParams();
  const [organizations, setOrganizations] = useState([]);
  const userData = useSelector(selectUserData);
  const usersList = useSelector(selectUsers);
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const { t } = useTranslation();
  const stateOptions = translatedStateOptions(t);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const { clearFilters, disableClear, areFiltersOpen, setAreFiltersOpen } =
    useTableFilters({
      reset,
      getValues,
      fetchData,
      defaultValues,
    });

  const onSubmit = (formData) => {
    const { status, accountantNumber, ...rest } = formData;
    const pageNumber = 1;
    const pageCount = 10;

    const paramsData = {
      ...(pageNumber && { pageNumber }),
      ...(pageCount && { pageCount }),
      ...(Number.isInteger(status) && { status: status }),
      ...(formData.name ? { name: formData.name } : null),
      ...(formData.surName ? { surName: formData.surName } : null),
      ...(formData.email ? { email: formData.email } : null),
      ...(formData.searchText ? { searchText: formData.searchText } : null),
      ...(formData.organization
        ? { organizationId: formData.organization.id }
        : null),
      ...(formData?.startDate &&
        formData?.endDate && {
          startDate: formData?.startDate,
          endDate: formData?.endDate,
        }),
    };
    setQueries(paramsData);

    fetchData(paramsData);
  };

  const handleFetchCoreOrganizations = useCallback(async (value) => {
    const { meta, payload } = await dispatch(
      fetchCoreCustomerIds({ term: value })
    );
    setOrganizations(payload);
  }, []);

  const debouncedFetchCoreOrganizations = useCallback(
    debounce((value: string) => handleFetchCoreOrganizations(value), 400),
    []
  );

  const handleClearFilters = () => {
    const queryParams = { ...defaultValues };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    reset({
      ...defaultValues,
      status: defaultValues.status ? Number(defaultValues.status) : "",
    });
    const deafultURLParamsValues = {
      ...defaultQueries,
      ...queryParams,
    };

    setQueries(deafultURLParamsValues);
    fetchData(defaultValues);
  };

  const excelActionsDisabled = useMemo(() => {
    return (
      !(watch("startDate") && watch("endDate")) || !usersList?.data?.length
    );
  }, [watch()]);

  const filterInitialFetch = useCallback(async () => {
    const queryParams = { ...defaultValues };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    const newQueryParams = {
      ...queryParams,
      ...getQueries(),
    };
    setQueries(newQueryParams);
    let newSelectedOrganization = null;
    if (userData?.role === Roles.SuperAdmin) {
      const { meta, payload } = await dispatch(
        fetchCoreCustomerIds({ term: "" })
      );
      setOrganizations(payload);
      const selectedOrganization = payload.find(
        (item) => item.id == newQueryParams.organizationId
      );

      newSelectedOrganization = selectedOrganization;
    }
    reset({
      ...newQueryParams,
      status: newQueryParams.status ? Number(newQueryParams.status) : "",
      organization: newSelectedOrganization,
    });
  }, []);

  useEffect(() => {
    filterInitialFetch();
  }, [userData]);

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Box>
            <Box display="flex" flexWrap="wrap" pt={2}></Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="name"
                  label={t("name")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="surName"
                  label={t("surname")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="email"
                  label={t("email")}
                  control={control}
                  errors={errors}
                />
              </Grid>

              {userData?.role === Roles.SuperAdmin && (
                <Grid item xs={3}>
                  <Autocomplete
                    label={t("organization")}
                    name="organization"
                    options={organizations}
                    control={control}
                    isInputChange
                    onInputChange={debouncedFetchCoreOrganizations}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <SelectField
                  name="status"
                  label={t("status")}
                  control={control}
                  keyProp="id"
                  size="small"
                  valueProp="id"
                  options={stateOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <RangePicker
                    label="Select date range"
                    name="range"
                    control={control}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                startIcon={<ListAlt htmlColor="green" />}
                variant="outlined"
                color="primary"
                disabled={excelActionsDisabled}
                onClick={() => handleExportUsers(watch())}
              >
                {t("export")}
              </Button>
              <Box>
                <Button color="primary" type="submit" variant="outlined">
                  {t("apply")}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.clearBtn}
                  onClick={handleClearFilters}
                  disabled={disableClear()}
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </form>
    </Paper>
  );
};

export default Filters;
