import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import thunkOptions from "store/config/thunkOptions";
import api from "helpers/axios";
import { EBaseUrl } from "store/config/constants";
import { IResourceState } from "../models/interfaces";

const name = "RESOURCES";

const initialState: IResourceState = {
  additionalServices: [],
  categories: [],
  parcelStatuses: [],
  services: [],
  serviceCategoryDirectionData: null,
};

export const fetchAdditionalServices = createAsyncThunk(
  `${name}/fetchAdditionalServices`,
  async (formData) =>
    (
      await api.get(
        `${EBaseUrl.Envelope}/Resources/AdditionalServices`,
        formData
      )
    ).data,
  thunkOptions
);

export const fetchServices = createAsyncThunk(
  `${name}/fetchServices`,
  async (formData) =>
    (await api.get(`${EBaseUrl.Envelope}/Resources/Services`, formData)).data,
  thunkOptions
);

export const fetchCategories = createAsyncThunk(
  `${name}/fetchCategories`,
  async (formData) =>
    (await api.get(`${EBaseUrl.Envelope}/Resources/Categories`, formData)).data,
  thunkOptions
);

export const fetchParcelStatuses = createAsyncThunk(
  `${name}/fetchParcelStatuses`,
  async (formData) =>
    (await api.get(`${EBaseUrl.Envelope}/Resources/ParcelStatuses`, formData))
      .data,
  thunkOptions
);

export const fetchServiceTypes = createAsyncThunk(
  `${name}/fetchPackageTypes`,
  async (countryId: number) =>
    (await api.get(`${EBaseUrl.Envelope}/Resources/Services/${countryId}`))
      .data,
  thunkOptions
);

export const fetchCategoryTypes = createAsyncThunk(
  `${name}/fetchServicesTypes`,
  async ({ serviceId, countryId }) =>
    (
      await api.get(
        `${EBaseUrl.Envelope}/Resources/Services/${serviceId}/${countryId}`
      )
    ).data,
  thunkOptions
);

export const fetchServiceCategoryDirections = createAsyncThunk(
  `${name}/fetchServiceCategoryDirections`,
  async (id: number) =>
    (
      await api.get(
        `${EBaseUrl.Envelope}/Resources/ServiceCategoryDirection/${id}`
      )
    ).data,
  thunkOptions
);

const ResourcesSlicer = createSlice({
  name,
  initialState,
  reducers: {
    removeServiceCategoryDirectionData: (state) => {
      state.serviceCategoryDirectionData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdditionalServices.fulfilled, (state, { payload }) => {
      state.additionalServices = payload;
    });
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.services = payload;
    });
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
    });
    builder.addCase(fetchParcelStatuses.fulfilled, (state, { payload }) => {
      state.parcelStatuses = payload;
    });
    builder.addCase(
      fetchServiceCategoryDirections.fulfilled,
      (state, { payload }) => {
        state.serviceCategoryDirectionData = payload;
      }
    );
  },
});

export const selectAdditionalServices = (state) =>
  state.resources.additionalServices;

export const selectParcelStatuses = (state) => state.resources.parcelStatuses;
export const selectCategories = (state) => state.resources.categories;
export const selectServices = (state) => state.resources.services;
export const selectServiceCategoryDirectionData = (state) =>
  state.resources.serviceCategoryDirectionData;

export const { removeServiceCategoryDirectionData } = ResourcesSlicer.actions;

export default ResourcesSlicer.reducer;
