import { useForm, FormProvider } from "react-hook-form";
import { makeStyles, Paper, Box, Button, Grid } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import SearchInput from "shared/ui/Table/components/searchInput";
import InputField from "shared/ui/Input";
import useTableFilters from "helpers/useTableFilters";
import SelectField from "shared/ui/Select";
import { onlyNumericalHandler } from "src/helpers/index";
import useQueryParams from "src/helpers/useQueryParams";
import { useTranslation } from "react-i18next";
import { translatedStateOptions } from "src/views/Users/components/filters";

const styles = makeStyles({
  autocomplete: {
    maxWidth: "235px",
    minWidth: "235px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  root: {
    width: "100%",
    marginBottom: "5px",
    padding: "15px",
  },
  filterIcon: {
    marginTop: "15px",
  },
  clearBtn: {
    marginLeft: "20px",
  },
});

const defaultValues = {
  active: "",
  address: "",
  hvhh: "",
  name: "",
  searchText: "",
};

const Filters = ({ fetchData }) => {
  const [getQueries, setQueries] = useQueryParams();
  const classes = styles();
  const { t } = useTranslation();
  const stateOptions = translatedStateOptions(t);
  const methods = useForm({
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const { areFiltersOpen, clearFilters, setAreFiltersOpen } = useTableFilters({
    getValues,
    fetchData,
    defaultValues,
  });

  const onSubmit = (formData) => {
    const { active, accountantNumber, ...rest } = formData;

    const paramsData = {
      ...(Number.isInteger(active) && { active: active }),
      ...(formData?.address && { address: formData.address }),
      ...(formData?.searchText && { searchText: formData.searchText }),
      ...(formData?.hvhh && { hvhh: formData.hvhh }),
      ...(formData?.name && { name: formData.name }),
      ...(formData?.coreCustomerId && {
        coreCustomerId: formData.coreCustomerId,
      }),
    };

    setQueries(paramsData);

    fetchData(paramsData);
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Box mb={2} display="flex" justifyContent="spaceBetween">
            <SearchInput
              onSubmit={() => handleSubmit(onSubmit)({ isSearch: true })}
            />

            <Box ml="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAreFiltersOpen(!areFiltersOpen)}
              >
                <TuneIcon />
              </Button>
            </Box>
          </Box>
          <Box display={areFiltersOpen ? "block" : "none"}>
            <Box display="flex" flexWrap="wrap" pt={2}></Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="name"
                  label={t("naming")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="address"
                  label={t("address")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="coreCustomerId"
                  label={t("phoenix_customer_number")}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  size="small"
                  variant="outlined"
                  name="hvhh"
                  label={t("hvhh")}
                  control={control}
                  onKeyPress={onlyNumericalHandler}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  name="active"
                  label={t("status")}
                  control={control}
                  keyProp="id"
                  size="small"
                  valueProp="id"
                  options={stateOptions}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button color="primary" variant="outlined" type="submit">
                {t("apply")}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.clearBtn}
                onClick={() => {
                  reset();
                  handleSubmit(onSubmit)();
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </form>
    </Paper>
  );
};

export default Filters;
