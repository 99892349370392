import { makeStyles } from "@material-ui/core";

export const ordersGridStyles = makeStyles((theme) => ({
  copyIcon: {
    cursor: "pointer",
    width: 20,
    height: 20,
    marginLeft: 5,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  codPrintPage: {
    height: "290mm",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "210mm",
    pageBreakBefore: "always",
    padding: 30,
  },
  h3: {
    fontSize: "24px",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontWeight: 500,
    lineHeight: "1.167",
    letterSpacing: "-0.06px",
  },
}));
