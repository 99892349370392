import { useRef, useState, useMemo } from "react";
import {
  Box,
  Dialog,
  Typography,
  DialogContent,
  Button,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import StandartLabelPrint from "../../../shared/prints/standartLabel";
import CN07 from "../../../shared/prints/cn07";
import TaxPercue from "../../../shared/prints/taxPercue";
import {
  translatedConfigOptions,
  EPrintOptions,
  translatedCODSelectOptions,
  EServiceTypes,
} from "../data/constants";
import Cheque from "src/shared/prints/OrderCheque";
import { useForm } from "react-hook-form";
import MUISelect from "src/shared/ui/Select";
import BarcodeToPrint from "src/components/BarcodeToPrint";
import CodLandscape from "src/shared/prints/codLandscape";
import CodPortrait from "src/shared/prints/codPortrait";
import useOrderPrintDialogActions from "../hooks/useOrderPrintDialogActions";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ordersGridStyles } from "../styles";
import { chunk } from "lodash";
import Cn22 from "src/shared/prints/cn22";
import CP72AndCN23 from "src/shared/prints/cp72&cn23";
import EMS from "src/shared/prints/EMS";
import { useTranslation } from "react-i18next";
import { IActiveOrder } from "src/store/models/interfaces/order";

const MultipleOrderOptionsDialog = ({
  open,
  onClose,
  printOrders,
}): JSX.Element => {
  const standartPrintRef = useRef();
  const codPrintRef = useRef();
  const classes = ordersGridStyles();
  const cn07Ref = useRef();
  const enclosuresRef = useRef();
  const EMSRef = useRef();
  const [activeOption, setActiveOption] = useState();
  const [tabValue, setTabValue] = useState("0");
  const { t } = useTranslation();
  const ConfigOptions = translatedConfigOptions(t);
  const codSelectOptions = translatedCODSelectOptions(t);
  const {
    filteredCN07Orders,
    availableOptions,
    handleChangeConfig,
    config,
    coreReceiptData,
    commonOptionsForAll,
  } = useOrderPrintDialogActions({ printOrders, open });

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      cod: "",
      codConfigData: null,
    },
  });

  const watchCodConfigData = watch("codConfigData");

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStandartPrintPreview = useReactToPrint({
    content: () => standartPrintRef.current,
  });

  const handleCODprintPreview = useReactToPrint({
    content: () => codPrintRef.current,
  });

  const handleCN07printPreview = useReactToPrint({
    content: () => cn07Ref.current,
  });

  const handleEnclosuresPrintPreview = useReactToPrint({
    content: () => enclosuresRef.current,
  });

  const handleEMSPrintPreview = useReactToPrint({
    content: () => EMSRef.current,
  });

  const handlePrintStandart = (item) => {
    setActiveOption(item);
    setTimeout(() => {
      if (item?.id && item.id == EPrintOptions.CN07) {
        handleCN07printPreview?.();
      } else if (
        item?.id &&
        (item.id == EPrintOptions.CN22 || item.id == EPrintOptions.CN23_CP72)
      ) {
        handleEnclosuresPrintPreview?.();
      } else if (item.id == EPrintOptions.EMS) {
        handleEMSPrintPreview?.();
      } else {
        handleStandartPrintPreview?.();
      }
    }, 0);
  };

  const handlePrintCod = () => {
    setActiveOption(watchCodConfigData);
    setTimeout(() => {
      handleCODprintPreview?.();
    }, 0);
  };

  const handlePrintCODBarcodes = () => {
    setActiveOption({ id: EPrintOptions.BARCODE });
    setTimeout(() => {
      handleCODprintPreview?.();
    }, 0);
  };

  const handleSelectCod = (value: string) => {
    const selectedValue = codSelectOptions.find((item) => item.id == value);
    setValue("codConfigData", selectedValue);
  };

  const onHandleDialogClose = () => {
    onClose();
    reset({
      cod: "",
      codConfigData: null,
    });
  };

  const filteredCODOrders = useMemo(
    () => !!printOrders.filter((order) => order.cod)?.length,
    [printOrders]
  );

  const filteredServiceTypes = useMemo(
    () =>
      !!printOrders.filter(
        (order) =>
          order?.service?.id >= EServiceTypes.Parcel &&
          order?.service?.id <= EServiceTypes.YES
      )?.length,
    [printOrders]
  );

  const defaultPrintDialogButtons = useMemo(() => {
    return availableOptions?.map((item, index) => {
      return (
        <Box key={index} p={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePrintStandart(item)}
          >
            {item.name}
          </Button>
        </Box>
      );
    });
  }, [availableOptions]);

  const commonPrintDialogButtons = useMemo(() => {
    return commonOptionsForAll?.map((item, index) => {
      return (
        <Box key={index} p={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePrintStandart(item)}
            disabled={item?.disabled}
          >
            {item.name}
          </Button>
        </Box>
      );
    });
  }, [commonOptionsForAll]);

  const codPrintDialogButtons = useMemo(
    () => (
      <Box display="flex" alignItems="center" textAlign="center">
        <Box minWidth={200}>
          <MUISelect
            control={control}
            name="cod"
            label={t("print_label_common")}
            options={codSelectOptions}
            textProp="name"
            onChange={handleSelectCod}
          />
        </Box>
        <Box p={1} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            disabled={!watchCodConfigData}
            onClick={handlePrintCod}
          >
            {watchCodConfigData
              ? watchCodConfigData?.name
              : t("print_label_common")}
          </Button>
        </Box>
        <Box p={1} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrintCODBarcodes}
          >
            {t("print_tracking_number")}
          </Button>
        </Box>
      </Box>
    ),
    [watchCodConfigData?.id, t]
  );

  const standardPrintsPreview = useMemo(
    () => printOrders?.filter((item) => !item.cod),
    [printOrders]
  );

  const codPrintsPreview = useMemo(
    () =>
      printOrders?.filter(
        (item) =>
          item.cod ||
          (item?.service?.id >= EServiceTypes.Parcel &&
            item?.service?.id <= EServiceTypes.YES)
      ),
    [printOrders]
  );

  const codChunksPrintsPreview = useMemo(
    () => chunk(codPrintsPreview, 6),
    [codPrintsPreview]
  );

  const cn07Chunks = useMemo(
    () => chunk(filteredCN07Orders, 2),
    [filteredCN07Orders]
  );

  const enclosuresPrintsPreview = useMemo(
    () =>
      printOrders?.filter(
        (item) =>
          JSON.parse(item.enclosures)?.length &&
          !(
            item.service.id == EServiceTypes.EMS_Letter ||
            item.service.id == EServiceTypes.EMS_Parcel
          )
      ),
    [printOrders]
  );

  const filteredEMSItems = useMemo(() => {
    const filteredItems = printOrders.filter(
      (item) =>
        item.service.id == EServiceTypes.EMS_Letter ||
        item.service.id == EServiceTypes.EMS_Parcel
    );
    return filteredItems;
  }, [printOrders]);

  const chequeList = useMemo(() => {
    return printOrders.filter((item) => item.status.id === 6);
  }, [printOrders]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onHandleDialogClose}>
      <Box m={4} style={{ wordBreak: "break-word" }}>
        <Typography variant="h4">{t("print_parcel_parcels")}</Typography>
      </Box>
      <DialogContent>
        <TabContext value={tabValue}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label={t("envelope")} value={"0"} />
            {(filteredCODOrders || filteredServiceTypes) && (
              <Tab label={t("print_label_common")} value={"1"} />
            )}
          </Tabs>
          <Box height={170}>
            {tabValue === "1" && (filteredCODOrders || filteredServiceTypes) ? (
              <TabPanel value={tabValue} index={"1"}>
                <Box p={2} display="flex" flexWrap={"wrap"}>
                  {codPrintDialogButtons}
                </Box>
              </TabPanel>
            ) : (
              <TabPanel value={tabValue} index={"0"}>
                <Box p={2} display="flex" flexWrap={"wrap"}>
                  {defaultPrintDialogButtons}
                  {commonPrintDialogButtons}
                </Box>
              </TabPanel>
            )}
          </Box>
        </TabContext>
        <Box ml={3}>
          {ConfigOptions.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={config[item.key]}
                    onChange={() => handleChangeConfig(item.key)}
                    color="primary"
                  />
                }
                label={item.name}
              />
            );
          })}
        </Box>
        <Box display="none">
          <Box ref={standartPrintRef}>
            {standardPrintsPreview?.map((activeOrder, index) => {
              if (
                activeOption?.id !== EPrintOptions.TAX_PERCUE &&
                activeOption?.id !== EPrintOptions.CHEQUE &&
                activeOption?.id !== EPrintOptions.OVERHEAD_PAYMENT_LANDSCAPE &&
                activeOption?.id !== EPrintOptions.OVERHEAD_PAYMENT_PORTRAIT &&
                activeOption?.id !== EPrintOptions.BARCODE
              ) {
                return (
                  <div key={index} style={{ pageBreakAfter: "always" }}>
                    <StandartLabelPrint
                      activeOrder={activeOrder}
                      config={config}
                      optionData={activeOption}
                    />
                  </div>
                );
              } else if (activeOption?.id === EPrintOptions.TAX_PERCUE) {
                return (
                  <TaxPercue
                    key={index}
                    activeOrder={activeOrder}
                    optionData={activeOption}
                  />
                );
              } else {
                return;
              }
            })}
            {chequeList?.map((activeOrder: IActiveOrder) => {
              if (activeOption?.id === EPrintOptions.CHEQUE) {
                return (
                  <Cheque
                    key={activeOrder?.id}
                    activeOrder={activeOrder}
                    optionData={activeOption}
                    coreData={coreReceiptData?.find(
                      (i) => i.id === activeOrder.id
                    )}
                  />
                );
              }
            })}
          </Box>
          {cn07Chunks?.length && (
            <Box ref={cn07Ref}>
              {cn07Chunks.map((chunks, chunkIndex) => (
                <Box key={chunkIndex} style={{ pageBreakAfter: "always" }}>
                  {chunks.map((activeOrder, index) => {
                    return (
                      <CN07
                        key={index}
                        activeOrder={activeOrder}
                        optionData={activeOption}
                        showAdditionalNotes={config.showAdditionalNotes}
                        showAdditionalNotes2={config.showAdditionalNotes2}
                      />
                    );
                  })}
                </Box>
              ))}
            </Box>
          )}
          {enclosuresPrintsPreview?.length ? (
            <Box ref={enclosuresRef}>
              {enclosuresPrintsPreview?.map((activeOrder, index) => {
                if (activeOption?.id === EPrintOptions.CN22) {
                  return <Cn22 key={index} printData={activeOrder} />;
                } else {
                  return <CP72AndCN23 key={index} printData={activeOrder} />;
                }
              })}
            </Box>
          ) : null}
          {filteredEMSItems?.length ? (
            <Box ref={EMSRef}>
              {filteredEMSItems?.map((activeOrder, index) => {
                return <EMS key={index} printData={activeOrder} />;
              })}
            </Box>
          ) : null}
        </Box>
        <Box display="none">
          <Box ref={codPrintRef}>
            {codChunksPrintsPreview?.length &&
              codChunksPrintsPreview.map((chunks, index) => (
                <Box key={index} className={classes.codPrintPage} style={{}}>
                  {chunks.map((activeOrder, index) => {
                    if (
                      activeOption?.id ==
                      EPrintOptions.OVERHEAD_PAYMENT_LANDSCAPE
                    ) {
                      return (
                        <CodLandscape activeOrder={activeOrder} key={index} />
                      );
                    } else if (
                      activeOption?.id ==
                      EPrintOptions.OVERHEAD_PAYMENT_PORTRAIT
                    ) {
                      return (
                        <CodPortrait activeOrder={activeOrder} key={index} />
                      );
                    } else {
                      return (
                        <Box key={index} style={{ pageBreakBefore: "always" }}>
                          <BarcodeToPrint
                            barcode={activeOrder.trackingNumber}
                          />
                        </Box>
                      );
                    }
                  })}
                </Box>
              ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MultipleOrderOptionsDialog;
