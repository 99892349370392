import { useCallback, useState } from "react";

const useTableFilters = (props) => {
  const { reset, getValues, defaultValues, filtersOpen = false } = props;

  const [areFiltersOpen, setAreFiltersOpen] = useState(filtersOpen);

  const disableClear = useCallback(() => {
    // const queries = getQueries();
    // return (
    //   !Object.keys(queries).length ||
    //   !!(Object.keys(queries).length === 1 && queries.searchText)
    // );
  }, []);

  const clearFilters = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handlePickerChange = useCallback(() => {
    reset(getValues());
  }, [getValues, reset]);

  return {
    handlePickerChange,
    clearFilters,
    disableClear,
    areFiltersOpen,
    setAreFiltersOpen,
  };
};

export default useTableFilters;
