import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Box,
} from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";
import tableStyles from "./style";
import CircularLoader from "components/CircularLoader";
import ActionsDropdown from "shared/ui/Table/components/actionsDropdown";
import { nanoid } from "@reduxjs/toolkit";
import { EStatus } from "src/views/Orders/data/constants";
import { useTranslation } from "react-i18next";

interface ICustomTable {
  columns: any;
  rows: any;
  rowClasses: any;
  actions: any;
  filters: any;
  onChangeRowsPerPage?: (event: any) => void;
  title: string;
  paginationConfig: any;
  multipleSelect?: boolean;
  isLoading: boolean;
  dropdownActions: any;
  multipleActions: () => JSX.Element;
  onRowCheck?: () => void;
  selected?: any;
  onCheckAll?: () => void;
  print: any;
}

const rowsPerPageOptions = [10, 50, 100, 200];

const CustomTable = ({
  columns,
  rows,
  rowClasses,
  actions,
  filters,
  title,
  paginationConfig,
  onChangeRowsPerPage,
  selected = {},
  multipleSelect = false,
  multipleActions,
  onRowCheck,
  onCheckAll,
  isLoading,
  dropdownActions,
  print,
}: ICustomTable): JSX.Element => {
  const classes = tableStyles();
  const ref = useRef(null);
  const { t } = useTranslation();

  const printTable = useReactToPrint({
    content: () => {
      const tableTitle = document.createElement("p");
      tableTitle.innerText = title;
      if (!ref?.current) {
        return;
      }
      const elementToPrint = ref.current.cloneNode(true);
      elementToPrint.prepend(tableTitle);
      return elementToPrint;
    },
  });

  const generateActions = useCallback(() => {
    return (
      (actions?.length || print?.usage) && (
        <div className={classes.actions}>
          {print?.usage && (
            <Button
              className={classes.actionItem}
              variant="outlined"
              color="primary"
              onClick={printTable}
              disabled={print.disabled}
            >
              <PrintIcon />
            </Button>
          )}
          {actions?.map((action) => {
            return action ? (
              <div key={nanoid(10)}>{action.layout(rows)}</div>
            ) : (
              <p key={nanoid(10)} />
            );
          })}
        </div>
      )
    );
  }, [
    actions,
    classes.actionItem,
    classes.actions,
    print?.disabled,
    print?.usage,
    printTable,
    rows,
  ]);

  const generateColumns = useCallback(() => {
    const res = columns?.map((column) => (
      <TableCell key={nanoid(10)}>
        <span className={classes.headCell}>{column.title}</span>
      </TableCell>
    ));
    return dropdownActions?.length ? (
      <Fragment>
        <TableCell>
          <span className={classes.headCell}>{t("actions")}.</span>
        </TableCell>
        {res}
      </Fragment>
    ) : (
      res
    );
  }, [classes.headCell, columns, dropdownActions?.length]);

  const generateSingleRow = useCallback(
    (row, rowIndex) => {
      const newRow = columns.map((column) => {
        if (column?.layout) {
          return (
            <TableCell key={nanoid(10)} scope="row">
              {column.layout(row)}
            </TableCell>
          );
        }

        return (
          <TableCell className={classes.bodyCell} key={nanoid(10)} scope="row">
            {row[column.field]}
          </TableCell>
        );
      });
      return dropdownActions && dropdownActions.length ? (
        <Fragment>
          {multipleSelect && (
            <TableCell>
              <Checkbox
                color="primary"
                onChange={(e, checked) => onRowCheck?.(checked, row, rowIndex)}
                checked={!!selected?.[row.id]?.checked}
              />
            </TableCell>
          )}
          <TableCell>
            <ActionsDropdown
              list={dropdownActions}
              row={row}
              rowIndex={rowIndex}
            />
          </TableCell>
          {...newRow}
        </Fragment>
      ) : (
        newRow
      );
    },
    [classes.bodyCell, columns, dropdownActions, selected]
  );

  const singleRowStyles = useCallback(
    (row) =>
      `${classes.rowDefault} ${rowClasses?.(row) || ""} ${
        row?.status?.id === EStatus.Successful && classes.successfulItemBg
      } ${row?.status?.id === EStatus.Error && classes.errorItemBg}`,
    [rowClasses]
  );

  const generateRows = useCallback(() => {
    return rows.map((row, index) => (
      <TableRow key={nanoid(10)} className={singleRowStyles(row)}>
        {generateSingleRow(row, index)}
      </TableRow>
    ));
  }, [classes.rowDefault, generateSingleRow, rowClasses, rows]);

  const isAllSelected = useMemo(() => {
    if (!selected) {
      return false;
    }

    return (
      Object.values(selected)?.every((i) => i.checked) &&
      Object.values(selected)?.length === paginationConfig?.rowsPerPage
    );
  }, [Object.values(selected), paginationConfig]);

  return (
    <Fragment>
      <Box marginBottom={2}>
        <Typography variant="h3" className={classes.title}>
          {t(title)}
        </Typography>
      </Box>
      <CircularLoader loading={isLoading} fixed maxContent={false}>
        <div className={classes.container}>
          {filters}
          {Object.values(selected || [])?.some((i) => i.checked) ? (
            multipleActions()
          ) : (
            <Box mt={10.5} />
          )}
          {generateActions() || []}
          <TableContainer innerRef={ref} component={Paper}>
            <Box>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {multipleSelect && (
                      <TableCell>
                        <Checkbox
                          color="primary"
                          onChange={(e, checked) => onCheckAll?.(checked)}
                          checked={isAllSelected}
                        />
                      </TableCell>
                    )}
                    {generateColumns()}
                  </TableRow>
                </TableHead>
                <TableBody>{generateRows()}</TableBody>
              </Table>
            </Box>
          </TableContainer>
          {paginationConfig?.page && (
            <TablePagination
              classes={{
                toolbar: classes.pagination,
              }}
              component="div"
              count={paginationConfig?.totalCount || 0}
              rowsPerPage={paginationConfig.rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              labelRowsPerPage={t("rows_count")}
              onChangeRowsPerPage={(event) => onChangeRowsPerPage?.(event)}
              page={
                paginationConfig?.totalCount
                  ? parseInt(paginationConfig.page) - 1
                  : 0
              }
              onChangePage={(e, v) => {
                paginationConfig?.pathToCheck
                  ? paginationConfig?.onChangePage(
                      e,
                      v,
                      paginationConfig?.pathToCheck
                    )
                  : paginationConfig?.onChangePage(e, v);
              }}
            />
          )}
        </div>
      </CircularLoader>
    </Fragment>
  );
};

export default CustomTable;
