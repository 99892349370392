import { Box, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { forwardRef } from "react";
import Barcode from "react-barcode";

const TaxPercue = forwardRef((props, ref) => {
  const { activeOrder, optionData } = props;
  return (
    <div
      ref={ref}
      style={{
        width: `${optionData?.width || 0}mm`,
        height: `${optionData?.height || 0}mm`,
        flexDirection: "column",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <Box display="flex" flexDirection={"column"} alignItems="center">
        <Barcode
          value={activeOrder?.trackingNumber}
          height={40}
          width={0.9}
          fontSize={14}
        />
      </Box>
      <div
        style={{
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          fontWeight: "bold",
          fontSize: 11,
        }}
      >
        <p style={{ marginBottom: 0 }}>Հասույթը գանձված է:</p>
        <p style={{ marginBottom: 0 }}>Tax Perçue: Haypost-EN</p>
      </div>
    </div>
  );
});

export default TaxPercue;
