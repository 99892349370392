import {
  Box,
  Dialog,
  Typography,
  Button,
  DialogContent,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const OrderOptionsDialog = ({ open, onClose, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirectManual = () => {
    navigate("/admin/orders/manual-create", { replace: true });
  };

  const handleRedirectExcelImport = () => {
    navigate("/admin/orders/excel-import", { replace: true });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box m={4}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <Box m={2}>
            <Button
              onClick={handleRedirectExcelImport}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              {t("import_from_excel")}
            </Button>
          </Box>
          <Box m={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirectManual}
            >
              {t("create_on_your_own")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderOptionsDialog;
