import { useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import LabelValueList from "components/LabelValueList/LabelValueList";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { ordersGridStyles } from "src/views/Orders/styles";

const OrganizationDetails = ({ data, open, onClose }) => {
  const { t } = useTranslation();
  const classes = ordersGridStyles();

  const parcelList = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      { label: t("naming"), value: data.name },
      { label: t("email"), value: data.email },
      { label: t("address"), value: data.address },
      { label: t("hvhh"), value: `${data.hvhh}` },
      { label: t("phoenix_customer_number"), value: data.coreCustomerId },
      { label: t("status"), value: data.active ? "Ակտիվ" : "Ոչ ակտիվ" },
      {
        label: t("is_created"),
        value: format(new Date(data.createdDate), "dd/MM/yyyy"),
      },
    ];
  }, [data, t]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.h3}>{t("organization_info")}</Typography>{" "}
      </DialogTitle>
      <DialogContent>
        <LabelValueList list={parcelList} />
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationDetails;
