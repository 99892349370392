import { Link } from "react-router-dom";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  container: {
    textAlign: "center",
  },
  link: {
    fontSize: "20px",
    fontFamily: "system-ui",
    display: "inline-block",
    textAlign: "center",
    margin: "20px auto 0",
    color: "black",
  },
}));

const NotFoundView = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" className={classes.container}>
          <Typography align="center" color="textPrimary" variant="h1">
            404: {t("page_not_found")}.
          </Typography>
          <Link to="/" className={classes.link}>
            {t("return_to_main_page")}
          </Link>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
